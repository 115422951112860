// extracted by mini-css-extract-plugin
export var section = "GroupBranches-module--section--2dspd";
export var sectionContent = "GroupBranches-module--section-content--3avEx";
export var modalTable = "GroupBranches-module--modal-table--2_2W4";
export var modal = "GroupBranches-module--modal--1eWFw";
export var modalTitle = "GroupBranches-module--modal-title--1lz7K";
export var sectionButtons = "GroupBranches-module--section-buttons--3rleg";
export var tableCellName = "GroupBranches-module--table-cell-name--2ibKo";
export var tableCellLocation = "GroupBranches-module--table-cell-location--3UCtk";
export var tableCellEmployeeCount = "GroupBranches-module--table-cell-employeeCount--1ABTb";
export var tableCellButtons = "GroupBranches-module--table-cell-buttons--2k43H";
export var modalHeader = "GroupBranches-module--modal-header--womP-";
export var modalClose = "GroupBranches-module--modal-close--1svqL";
export var modalBlockSearch = "GroupBranches-module--modal-block-search--3XkEE";
export var modalBlockButtons = "GroupBranches-module--modal-block-buttons--2xOyI";
export var modalButton = "GroupBranches-module--modal-button--1oPrG";
export var modalTableCellCheckbox = "GroupBranches-module--modal-table-cell-checkbox--1En-N";
export var modalTableCellName = "GroupBranches-module--modal-table-cell-name--1pbJI";
export var modalTableCellAddress = "GroupBranches-module--modal-table-cell-address--3ynSp";