import { DateTime } from 'luxon'
import React from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Explanations from 'shared/components/Explanations'
import { explanationsParagraph } from 'shared/components/Explanations/Explanations.module.scss'
import CubeQueryRender, { IChartComponentProps, renderChart } from 'shared/components/QueryRender/CubeQueryRender'
import CustomBarCursor from 'shared/components/Recharts/CustomBarCursor'
import CustomTooltip from 'shared/components/Recharts/CustomTooltip'

import { ChartPivotRow, Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import { blockChart, blockChartGraph, blockChartTitle, chart } from './BlockCharts.module.scss'
import { getChartWidth } from './utils'

const barSize = 80
const barSizeBigData = 10
const countBarStyle = 6

const avgGrade = 'CourseFeedback.averageGrade'
const courseId = 'Course.id'
const courseName = 'Course.name'

interface IChartGridData {
  [avgGrade]: number
  ['name']: string
}
const color = '#FF8F51'
const tooltipColor = '#201F25'
const label = 'Средняя оценка за курс'

const applyLabelToBars = (course: { id: string; name: string }, pivots: ChartPivotRow[]): IChartGridData => {
  const { id, name } = course
  const filteredPivots = pivots.filter((p) => p.xValues[0] === id)

  const preparedData = {
    [avgGrade]: Math.round((filteredPivots.find((v) => v.x === `${id},${name}`)?.[avgGrade] ?? 0) * 100) / 100,
    ['name']: name,
  }
  return preparedData
}

const getData = (set: ResultSet) => {
  const pivots = set.chartPivot({
    x: [courseId, courseName],
    y: [avgGrade],
  })

  const courseIdNamePairs = [...new Set(pivots.map((r) => ({ id: r.xValues[0], name: r.xValues[1] })))]

  return courseIdNamePairs.map((pair) => applyLabelToBars(pair, pivots))
}

const barRender = ({ resultSet }: IBarRenderProps) => {
  const data = getData(resultSet as ResultSet)

  const memoData = React.useMemo(() => data, [data])

  const width = getChartWidth(data)

  return (
    memoData && (
      <ResponsiveContainer width={width} height="100%">
        <BarChart data={memoData} margin={{ top: 20 }}>
          <XAxis hide={true} dataKey="name" />
          <YAxis type="number" axisLine={false} tickLine={false} domain={[0, 5]} ticks={[1, 2, 3, 4, 5]} width={20} />
          <CartesianGrid strokeDasharray="5 5" />
          <Bar
            dataKey={avgGrade}
            stackId="a"
            fill={color}
            name={label}
            minPointSize={3}
            barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
          ></Bar>
          <Tooltip cursor={<CustomBarCursor customWidth={2} color={tooltipColor} />} content={<CustomTooltip />} />
        </BarChart>
      </ResponsiveContainer>
    )
  )
}

type IBarRenderProps = IChartComponentProps

const chartRender = (props: QueryRendererRenderProps) => (
  <div className={chart}>
    {renderChart(barRender)(props)}
  </div>
)

const VisibleExplanationsContent = () => {
  return (
    <p className={explanationsParagraph}>
      График отражает данные по&nbsp;курсам Вашей аптечной сети. Позволяет увидеть среднею оценку курса провизорами.
    </p>
  )
}

const AverageGradeChart = (props: AverageGradeChartProps) => {
  return (
    <div className={blockChart}>
      <h2 className={blockChartTitle}>Оценка провизоров за курс</h2>
      <div className={blockChartGraph}>
        <CubeQueryRender
          query={{
            measures: [avgGrade],
            dimensions: [courseId, courseName],
            filters: props.filters,
          }}
          render={chartRender}
        />
      </div>

      <Explanations visibleContent={<VisibleExplanationsContent />} />
    </div>
  )
}

interface IAverageGradeChartStateProps {
  dispatch: AppDispatch
  startDate: DateTime
  endDate: DateTime
  filters: Filter[]
}

type AverageGradeChartProps = {} & IAverageGradeChartStateProps

const mapStateToProps = (store: IAppState): IAverageGradeChartStateProps => {
  return {
    dispatch: store.dispatch,
    startDate: store.pharmacyGroupStatistics.startDate,
    endDate: store.pharmacyGroupStatistics.endDate,
    filters: store.pharmacyGroupStatistics.averageGradeFilters,
  }
}

export default connect<IAverageGradeChartStateProps, {}, {}, IAppState>(mapStateToProps)(AverageGradeChart)
