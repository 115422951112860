/* eslint-disable react/display-name */
import classNames from 'classnames'
import { DateHelper } from 'external/rp.ui/helpers/DateHelper'
import React, { MutableRefObject, useEffect, useState } from 'react'
import { ACTIONS, CallBackProps, STATUS } from 'react-joyride'
import { connect } from 'react-redux'
import {
  Cell,
  Column,
  ColumnInterface,
  Row,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  TableToggleRowsSelectedProps,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useRowSelect,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
  UseRowSelectState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
} from 'react-table'
import HelpButton from 'shared/components/Buttons/HelpButton'
import TextButton from 'shared/components/Buttons/TextButton'
import CustomCheckbox from 'shared/components/CustomCheckbox'
import HelpModal from 'shared/components/HelpModal'
import AddIcon from 'shared/components/Icons/AddIcon'
import CloseIcon from 'shared/components/Icons/CloseIcon'
import Table from 'shared/components/Table'
import {
  tableCell,
  tableRow,
  tableRowChecked,
  tableText,
  tableTextContainer,
} from 'shared/components/Table/Table.module.scss'
import TablePaging from 'shared/components/TablePaging/TablePaging'
import { getCountApplications } from 'shared/reducers/menuItems/menuItemsReducer'

import { IApplication, IApplicationRejectRequest } from '../../../proto/models'
import {
  addApplicationsRequireConfirmation,
  getApplicationsRequireConfirmation,
  IApplicationRequest,
  rejectApplicationsRequireConfirmation,
  resetStateApplications,
} from '../../../reducers/pharmacyApplicationsReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import {
  section,
  sectionButton,
  sectionButtons,
  sectionContent,
  sectionCounter,
  sectionCounterLabel,
  sectionCounterValue,
  tableCellCheckbox,
  tableCellDate,
  tableCellLocation,
  tableCellName,
  tableCellNamePharm,
} from './ApplicationsRequireConfirmation.module.scss'
import ApplicationsRequireConfirmationEditModal from './ApplicationsRequireConfirmationEditModal'
import { applicationsSteps, applicationsStepSelectors } from './help'

const pageSizeOptions = [25, 50, 75]

interface IApplicationsRequireConfirmationTableOwnProps {
  path: string
}

interface IFetchDataProps {
  pageIndex: number
  pageSize: number
  sortBy: Array<SortingRule<IApplication>>
}

interface IApplicationsRequireConfirmationTableStateProps {
  dispatch: AppDispatch
  requireConfirmation: IApplication[]
  loadingRequireConfirmation: boolean
}

type ApplicationsRequireConfirmationTableProps = IApplicationsRequireConfirmationTableOwnProps &
  IApplicationsRequireConfirmationTableStateProps

const ApplicationsRequireConfirmationTable = (props: ApplicationsRequireConfirmationTableProps) => {
  const [fetchedData, setFetchedData] = useState<IApplication[]>([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [open, setOpen] = useState(false)
  const [run, setRun] = useState(false)

  const helpButtonHandler = () => {
    setRun(true)
  }

  const refreshMenuItems = () => {
    props.dispatch(getCountApplications('pharmacy-office/menu/get-count-applications'))
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status } = data

    if (([ACTIONS.CLOSE] as string[]).includes(action) || ([STATUS.PAUSED] as string[]).includes(status)) {
      setRun(false)
    }
  }

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const getRowClass = (row: Row<IApplication>) => {
    const selectableRow = row as Row<IApplication> & UseRowSelectRowProps<IApplication>
    return classNames(tableRow, { [tableRowChecked]: selectableRow.isSelected })
  }

  const onFetchData = async ({ pageIndex, pageSize, sortBy }: IFetchDataProps) => {
    const options: IApplicationRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: 'createDate',
      sortDirection: sortBy[0]?.desc ?? true ? 'desc' : 'asc',
    }

    const response = await props.dispatch(getApplicationsRequireConfirmation(options))
    setFetchedData(response?.items ?? [])
    setTotalRowCount(response?.rowCount ?? 0)
  }

  const getCellClass = (column: ColumnInterface<IApplication>) => {
    switch (column.id) {
      case 'selection': {
        return classNames(tableCell, tableCellCheckbox)
      }
      case 'createDate': {
        return classNames(tableCell, tableCellDate)
      }
      case 'fullName': {
        return classNames(tableCell, tableCellName)
      }
      case 'pharmacyName': {
        return classNames(tableCell, tableCellNamePharm)
      }
      case 'address': {
        return classNames(tableCell, tableCellLocation)
      }
    }
  }

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, TableToggleRowsSelectedProps & { id: string }>(
    ({ indeterminate, id, ...rest }, ref) => {
      const defaultRef = React.useRef<HTMLInputElement>()
      const resolvedRef = (ref as MutableRefObject<HTMLInputElement>) || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return <CustomCheckbox id={id} ref={resolvedRef} {...rest} />
    }
  )

  const columns: Column<IApplication>[] = React.useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<IApplication>) => {
          return <IndeterminateCheckbox id={`globalSelect`} {...getToggleAllRowsSelectedProps()} />
        },
        Cell: ({ row }: { row: Row<IApplication> & UseRowSelectRowProps<IApplication> }) => (
          <IndeterminateCheckbox id={`item-${row.id}`} {...row.getToggleRowSelectedProps()} />
        ),
      },
      {
        Header: 'Дата',
        id: 'createDate',
        accessor: 'createDate',
        sortType: 'basic',
        Cell: (cell: Cell<IApplication>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{DateHelper.fromNumber(cell.value as number).format('DD.MM.YYYY')}</span>
          </p>
        ),
      },
      {
        Header: 'ФИО',
        id: 'fullName',
        accessor: 'fullName',
        disableSortBy: true,
        Cell: (cell: Cell<IApplication>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{cell.value}</span>
          </p>
        ),
      },
      {
        Header: 'Наименование',
        id: 'pharmacyName',
        accessor: 'pharmacyName',
        disableSortBy: true,
        Cell: (cell: Cell<IApplication>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{cell.value}</span>
          </p>
        ),
      },
      {
        Header: 'Адрес филиала',
        id: 'address',
        accessor: 'address',
        disableSortBy: true,
        Cell: (cell: Cell<IApplication>) => (
          <p className={tableTextContainer}>
            <span className={tableText} title={cell.value}>
              {cell.value}
            </span>
          </p>
        ),
      },
    ],
    []
  )

  const [localPageSize, setLocalPageSize] = React.useState(pageSizeOptions[0])

  const data = React.useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const tablePageCount = React.useMemo(
    () => Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0])),
    [totalRowCount, localPageSize]
  )

  const initialState: Partial<TableState<IApplication>> &
    Partial<UsePaginationState<IApplication>> &
    UseSortByState<IApplication> = {
    pageSize: localPageSize,
    sortBy: [{ id: 'createDate', desc: true }],
  }

  const tableOptions: TableOptions<IApplication> & UseSortByOptions<IApplication> & UsePaginationOptions<IApplication> =
    {
      data: data,
      columns: columns,
      pageCount: tablePageCount,
      initialState: initialState,
      manualSortBy: true,
      manualPagination: true,
      disableSortRemove: true,
    }

  const table = useTable<IApplication>(
    tableOptions,
    useSortBy,
    usePagination,
    useRowSelect
  ) as TableInstance<IApplication> & UsePaginationInstanceProps<IApplication> & UseRowSelectInstanceProps<IApplication>
  const { gotoPage, setPageSize, pageCount, selectedFlatRows } = table
  const { pageIndex, pageSize, sortBy } = table.state as UsePaginationState<IApplication> &
    UseSortByState<IApplication> & { state: UseRowSelectState<IApplication> }

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 500)

  useEffect(() => {
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    })
  }, [pageIndex, pageSize, sortBy])

  useEffect(() => {
    return () => {
      props.dispatch(resetStateApplications())
    }
  }, [])

  const getSelectedIds = (): Uint8Array[] => selectedFlatRows.map((row) => row.original.id)

  const addButtonHandler = async () => {
    const ids = getSelectedIds()

    await props.dispatch(addApplicationsRequireConfirmation(ids))
    refreshMenuItems()
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    })
  }

  const rejectButtonHandler = async (message: string) => {
    const ids = getSelectedIds()
    const options: IApplicationRejectRequest = {
      applicationIds: ids,
      comment: message,
    }

    handleClose()
    await props.dispatch(rejectApplicationsRequireConfirmation(options))
    refreshMenuItems()
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    })
  }

  return (
    <>
      {props.requireConfirmation?.length > 0 && (
        <>
          <HelpButton onClick={helpButtonHandler} />
          <HelpModal
            widthModal={680}
            steps={applicationsSteps}
            run={run}
            handleJoyrideCallback={handleJoyrideCallback}
          />
        </>
      )}
      <div className={section}>
        <div className={sectionContent}>
          <div className={sectionButtons}>
            <div className={sectionButton}>
              <TextButton
                text="Добавить в штат"
                onClick={addButtonHandler}
                disabled={selectedFlatRows.length === 0 || props.loadingRequireConfirmation}
                iconLeft={<AddIcon color="white" />}
              />
            </div>
            <div className={sectionButton}>
              <TextButton
                text="Отклонить"
                onClick={handleOpen}
                disabled={selectedFlatRows.length === 0 || props.loadingRequireConfirmation}
                iconLeft={
                  <CloseIcon
                    color={selectedFlatRows.length === 0 || props.loadingRequireConfirmation ? 'white' : 'blue'}
                  />
                }
                variant="outlined"
              />
            </div>
            <div className={sectionCounter}>
              <label className={sectionCounterLabel}>Выбранно заявок:</label>
              <span className={sectionCounterValue}>{selectedFlatRows.length}</span>
            </div>
          </div>
          <Table<IApplication>
            tableInstance={table}
            getCellClass={getCellClass}
            getRowClass={getRowClass}
            addHover={true}
            rowWrap="nowrap"
            loading={props.loadingRequireConfirmation}
            tableSelectors={applicationsStepSelectors.applications}
          />
          <TablePaging
            gotoPage={gotoPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSizeOptions={pageSizeOptions}
            setLocalPageSize={setLocalPageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>

      <ApplicationsRequireConfirmationEditModal
        open={open}
        handleClose={handleClose}
        onRejectApplications={rejectButtonHandler}
      />
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    loadingRequireConfirmation: store.pharmacyApplications.loadingRequireConfirmation,
    requireConfirmation: store.pharmacyApplications.requireConfirmation,
  }
}

export default connect(mapStateToProps, null)(ApplicationsRequireConfirmationTable)
