import React from 'react'
import { connect } from 'react-redux'

import { Filter, ResultSet } from '@cubejs-client/core'
import { info } from 'shared/components/CourseStatistics/CourseStatisticsInfo/BlockCharts.module.scss'
import Details from 'shared/components/CourseStatistics/Details'
import { IDetailsItemProps } from 'shared/components/CourseStatistics/Details/Details'
import CubeQueryRender, {
  IChartComponentProps,
  ICubePivotConfig,
  renderChart,
} from 'shared/components/QueryRender/CubeQueryRender'
import { EPublicationStatus } from 'shared/proto/models'
import { AppDispatch, CourseStatisticsFilterState } from 'shared/reducers/courseStatistics'
import {
  getDateTimeRange,
  ICourseStatisticFiltersState,
} from 'shared/reducers/courseStatistics/courseStatisticFilterReducer'

import { EmployeeCourseStatisticsState } from '../../../reducers/employeeCourseStatisticsReducer'
import { IAppState } from '../../../reducers/rootReducer'
import { addFilterByPharmacy } from '../CourseStatistics'

const percentage = (dividend: number, divider: number): string => {
  if (!divider || divider === 0) {
    return '0.0%'
  }
  return `${(((dividend ?? 0) * 100) / (divider ?? 1)).toFixed(2)}%`
}

const tableRender = ({ resultSet }: IChartComponentProps) => {
  resultSet = resultSet as { [key: string]: ResultSet }
  const pivotConfig: ICubePivotConfig = {
    x: [],
    y: [],
    fillMissingDates: true,
    joinDateRange: false,
  }
  const pharmacist = resultSet.Pharmacist.tablePivot(pivotConfig)[0]
  const status = resultSet.StudentPublicationStatusPharmacy.tablePivot(pivotConfig)[0]
  const feedbacks = resultSet.Feedbacks.tablePivot(pivotConfig)[0]
  const timedOut = resultSet[timedOutKey].tablePivot(pivotConfig)[0]
  const inProgress = resultSet.InProgress.tablePivot(pivotConfig)[0]

  const dataDetails: IDetailsItemProps[] = [
    {
      title: 'Назначено:',
      value: pharmacist['PharmacistCourse.count'].toString(),
    },
    {
      title: 'Взаимодействовали:',
      value: status['StudentPublicationStatusPharmacy.count'].toString(),
    },
    {
      title: 'Изучили:',
      value: percentage(
        +inProgress['StudentPublicationStatusPharmacy.countInProgress'],
        +pharmacist['PharmacistCourse.count']
      ),
    },
    {
      title: 'Общая оценка:',
      value: (+feedbacks['CourseFeedback.averageGrade'])?.toFixed(2) ?? '0',
    },
    {
      title: 'Сдали курс:',
      value: percentage(+status['StudentPublicationStatusPharmacy.countPassed'], +pharmacist['PharmacistCourse.count']),
    },
    {
      title: 'Не сдали курс:',
      value: percentage(
        +status['StudentPublicationStatusPharmacy.countFailed'] + +timedOut[timedOutCount],
        +pharmacist['PharmacistCourse.count']
      ),
    },
  ]

  return (
    <div className={info}>
      <Details array={dataDetails} />
    </div>
  )
}

const timedOutKey = 'TimedOutCourseAttempt'
const timedOutCount = 'TimedOutCourseAttempt.count'

const pharmacistFilters = (props: BlockCourseReportProps): Filter[] => {
  const {
    courseId,
    filter,
    employeeStatistics: { pharmacies, pharmacists },
  } = props

  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'PharmacistCourse.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'PublicationHistory.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
    {
      dimension: 'PublicationHistory.status',
      operator: 'equals',
      values: [`${EPublicationStatus.Active}`],
    },
  ]

  if (pharmacists && pharmacists.length > 0) {
    filters.push({
      dimension: 'PharmacistCourse.userId',
      operator: 'equals',
      values: pharmacists.map((p) => p.id),
    })
  } else if (pharmacies && pharmacies.length > 0) {
    addFilterByPharmacy(filters, pharmacies)
  }

  return filters as Filter[]
}

const inProgressFilter = (props: BlockCourseReportProps): Filter[] => {
  const {
    courseId,
    filter,
    employeeStatistics: { pharmacies, pharmacists },
  } = props

  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'StudentPublicationStatusPharmacy.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'StudentPublicationStatusHistory.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
    {
      dimension: 'Publication.status',
      operator: 'equals',
      values: [EPublicationStatus.Active.toString()],
    },
  ]

  if (pharmacists && pharmacists.length > 0) {
    filters.push({
      dimension: 'StudentPublicationStatusPharmacy.userId',
      operator: 'equals',
      values: pharmacists.map((p) => p.id),
    })
  } else if (pharmacies && pharmacies.length > 0) {
    addFilterByPharmacy(filters, pharmacies)
  }

  return filters as Filter[]
}

const studentPublicationStatusPharmacyFilter = (props: BlockCourseReportProps) => {
  const {
    courseId,
    filter,
    employeeStatistics: { pharmacies, pharmacists },
  } = props

  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'StudentPublicationStatusPharmacy.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'Course.id',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'StudentPublicationStatusHistory.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
  ]

  if (pharmacists && pharmacists.length > 0) {
    filters.push({
      dimension: 'StudentPublicationStatusPharmacy.userId',
      operator: 'equals',
      values: pharmacists.map((p) => p.id),
    })
  } else if (pharmacies && pharmacies.length > 0) {
    addFilterByPharmacy(filters, pharmacies)
  }

  return filters as Filter[]
}

const timedOutFilter = (props: BlockCourseReportProps) => {
  const {
    courseId,
    filter,
    employeeStatistics: { pharmacies, pharmacists },
  } = props

  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'TimedOutCourseAttempt.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'TimedOutCourseAttempt.courseEndDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
  ]

  if (pharmacists && pharmacists.length > 0) {
    filters.push({
      dimension: 'TimedOutCourseAttempt.userId',
      operator: 'equals',
      values: pharmacists.map((p) => p.id),
    })
  } else if (pharmacies && pharmacies.length > 0) {
    addFilterByPharmacy(filters, pharmacies)
  }

  return filters as Filter[]
}

const BlockCourseReport = (props: BlockCourseReportProps) => {
  const { courseId, filter } = props

  return (
    <CubeQueryRender
      queries={{
        Feedbacks: {
          measures: ['CourseFeedback.averageGrade'],
          filters: [
            {
              dimension: 'Course.id',
              operator: 'equals',
              values: [courseId],
            },
            {
              dimension: 'CourseFeedback.date',
              operator: 'inDateRange',
              values: getDateTimeRange(filter),
            },
          ],
        },
        Pharmacist: {
          measures: ['PharmacistCourse.count'],
          filters: pharmacistFilters(props),
        },
        InProgress: {
          measures: ['StudentPublicationStatusPharmacy.countInProgress'],
          filters: inProgressFilter(props),
        },
        StudentPublicationStatusPharmacy: {
          measures: [
            'StudentPublicationStatusPharmacy.count',
            'StudentPublicationStatusPharmacy.countPassed',
            'StudentPublicationStatusPharmacy.countFailed',
          ],
          filters: studentPublicationStatusPharmacyFilter(props),
        },
        [timedOutKey]: {
          measures: [timedOutCount],
          filters: timedOutFilter(props),
        },
      }}
      render={renderChart(tableRender)}
    />
  )
}

export interface IBlockCourseReportOwnProps {
  courseId: string
}

interface IBlockCourseReportStateProps {
  dispatch: AppDispatch
  filter: ICourseStatisticFiltersState
  employeeStatistics: EmployeeCourseStatisticsState
}

type BlockCourseReportProps = IBlockCourseReportOwnProps & IBlockCourseReportStateProps

type AppStateType = CourseStatisticsFilterState & IAppState
const mapStateToProps = (store: AppStateType): IBlockCourseReportStateProps => {
  return {
    dispatch: store.dispatch,
    filter: store.courseStatisticFilters.filter,
    employeeStatistics: store.employeeCourseStatistics,
  }
}

export default connect<IBlockCourseReportStateProps, unknown, IBlockCourseReportOwnProps, AppStateType>(
  mapStateToProps
)(BlockCourseReport)
