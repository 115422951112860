import React, { ReactElement } from 'react'
import {
  blockChart,
  blockChartTitle,
} from 'shared/components/CourseStatistics/CourseStatisticsInfo/BlockCharts.module.scss'
import DynamicsLearningDatesChart from 'shared/components/CourseStatistics/CourseStatisticsInfo/Dynamics/DynamicsLearningDatesChart'
import { ICourseStatisticFiltersState } from 'shared/reducers/courseStatistics/courseStatisticFilterReducer'

import { Filter } from '@cubejs-client/core'

import DynamicsLearningTable from './DynamicsLearningTable'

interface IDynamicsLearningProps {
  courseId: string
  chartFilters(courseId: string, filter: ICourseStatisticFiltersState): Filter[]
  tableFilters(courseId: string, filter: ICourseStatisticFiltersState): Filter[]
}

const DynamicsLearning = (props: IDynamicsLearningProps): ReactElement<IDynamicsLearningProps> => (
  <div className={blockChart}>
    <h2 className={blockChartTitle}>Динамика обучения, по датам</h2>
    <DynamicsLearningDatesChart courseId={props.courseId} getFilters={props.chartFilters} />
    <DynamicsLearningTable courseId={props.courseId} getFilters={props.tableFilters} />
  </div>
)

export default DynamicsLearning
