import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Explanations from 'shared/components/Explanations'
import CubeQueryRender from 'shared/components/QueryRender'
import { IChartComponentProps, renderChart } from 'shared/components/QueryRender/CubeQueryRender'
import CustomLegend from 'shared/components/Recharts/CustomLegend'
import CustomTooltip from 'shared/components/Recharts/CustomTooltip'
import WarningTextBlock from 'shared/components/WarningTextBlock'

import { ChartPivotRow, Filter, ResultSet, TQueryOrderObject } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'
import { Box } from '@material-ui/core'

import { explanationsParagraph } from '../Explanations/Explanations.module.scss'
import { blockChart, blockChartTitle, chart } from './BlockChart.module.scss'

interface IPublicationByCategoryChartProps {
  anotherOrganizationFilter: Filter[]
  anotherInOwnOrganizationFilter: Filter[]
  categoriesSelected?: boolean
  byAnotherInOwnOrganizationQueryKey: string
  byAnotherOrganizationQueryKey: string
  byAnotherInOwnOrganizationCount: string
  byAnotherOrganizationCount: string
  byAnotherInOwnOrganizationStartDate: string
  byAnotherOrganizationStartDate: string
  anotherOrganizationBranchIdSelector: string
  anotherInOwnOrganizationBranchIdSelector: string
  orderAnotherInOwnOrganization: TQueryOrderObject
  orderAnotherOrganization: TQueryOrderObject
}

const PublicationByCategoryChart = (props: IPublicationByCategoryChartProps) => {
  function formatDate(date: string | number) {
    switch (typeof date) {
      case 'string':
        return DateTime.fromISO(date).setLocale('ru').toFormat('LLL')
      case 'number':
        return DateTime.fromSeconds(date).setLocale('ru').toFormat('LLL')
    }
  }

  const lineColors = (key: number) => {
    if (key >= 0 && key <= 4) {
      return '#32C9D1'
    }
    if (key >= 5 && key <= 9) {
      return '#7955A9'
    }

    throw 'Неверный ключ'
  }

  const lineNames = ['АО 1', 'АО 2', 'АО 3', 'АО 4', 'АО 5', 'ФК 1', 'ФК 2', 'ФК 3', 'ФК 4', 'ФК 5']

  interface IPreparedChartData {
    [i: number]: number
    ['date']: string
  }

  const applyLabelToBars = (
    date: string,
    byAnotherManufacturerPivots: ChartPivotRow[],
    byAnotherPharmacyPivots: ChartPivotRow[],
    manufacturerIds: string[],
    pharmacyIds: string[]
  ) => {
    const byAnotherManufacturer = byAnotherManufacturerPivots.filter((p) => p.xValues[1] === date)
    const byAnotherPharmacy = byAnotherPharmacyPivots.filter((p) => p.xValues[1] === date)

    const preparedData: IPreparedChartData = {
      ['date']: date,
    }

    for (const manPivot of byAnotherManufacturer) {
      const lineNumber = manufacturerIds.indexOf(manPivot.xValues[0])
      preparedData[lineNumber] = manPivot[props.byAnotherInOwnOrganizationCount]
    }

    for (const pharmPivot of byAnotherPharmacy) {
      const lineNumber = pharmacyIds.indexOf(pharmPivot.xValues[0])
      preparedData[lineNumber + 5] = pharmPivot[props.byAnotherOrganizationCount]
    }

    return preparedData
  }

  const getData = (sets: { [key: string]: ResultSet }) => {
    const byAnotherManufacturerPivots = sets[props.byAnotherInOwnOrganizationQueryKey].chartPivot({
      x: [props.anotherInOwnOrganizationBranchIdSelector, props.byAnotherInOwnOrganizationStartDate],
      y: [props.byAnotherInOwnOrganizationCount],
    })

    const byAnotherPharmacyPivots = sets[props.byAnotherOrganizationQueryKey].chartPivot({
      x: [props.anotherOrganizationBranchIdSelector, props.byAnotherOrganizationStartDate],
      y: [props.byAnotherOrganizationCount],
    })

    const manufacturerIds = [...new Set(byAnotherManufacturerPivots.map((p) => p.xValues[0]))]
    const pharmacyIds = [...new Set(byAnotherPharmacyPivots.map((p) => p.xValues[0]))]

    const dates = [
      ...new Set(
        byAnotherManufacturerPivots.map((p) => p.xValues[1]).concat(byAnotherPharmacyPivots.map((p) => p.xValues[1]))
      ),
    ]
      .filter((date) => date !== null)
      .sort(dateComparer)

    const preparedData = dates.map((date) =>
      applyLabelToBars(date, byAnotherManufacturerPivots, byAnotherPharmacyPivots, manufacturerIds, pharmacyIds)
    )

    return preparedData
  }

  const dateComparer = (left: string, right: string) => {
    const leftDate = DateTime.fromISO(left)
    const rightDate = DateTime.fromISO(right)
    if (leftDate < rightDate) {
      return -1
    }
    if (leftDate > rightDate) {
      return 1
    }
    return 0
  }

  const CartesianChart = ({
    data,
    children,
    ChartComponent,
    legendClickHandler,
  }: {
    data: IPreparedChartData[]
    children?: React.ReactNode
    ChartComponent: typeof LineChart
    legendClickHandler: (dataKey: number) => void
  }) => {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ChartComponent data={data}>
          <XAxis dataKey="date" tickFormatter={formatDate} />
          <YAxis width={20} key="YAxis" type="number" axisLine={false} tickLine={false} allowDecimals={false} />
          <CartesianGrid strokeDasharray="5 5" horizontal={true} vertical={false} stroke="#ACB2BD" />
          {children}
          <Legend
            wrapperStyle={{ bottom: '-10px' }}
            height={35}
            content={<CustomLegend onClick={legendClickHandler} />}
          />
          <Tooltip content={<CustomTooltip category={true} />} />
        </ChartComponent>
      </ResponsiveContainer>
    )
  }

  const lineRender = (props: ILineRenderProps) => {
    const { resultSet } = props
    const [show, setShow] = useState<number>(null)
    const data = getData(resultSet as { [key: string]: ResultSet })
    const memoData = React.useMemo(() => data, [data])
    const keys = [...Array(lineNames.length).keys()]

    const legendClickHandler = (dataKey: number) => {
      if (show === dataKey) {
        setShow(null)
      } else {
        setShow(dataKey)
      }
    }

    return (
      <CartesianChart data={memoData} ChartComponent={LineChart} legendClickHandler={legendClickHandler}>
        {keys.map((val: unknown, i: number) => (
          <Line
            hide={show !== null && show !== i}
            activeDot={{ r: 5 }}
            type="monotoneX"
            key={i}
            dataKey={i}
            name={lineNames[i]}
            stroke={lineColors(i)}
            strokeWidth={3}
          />
        ))}
      </CartesianChart>
    )
  }

  type ILineRenderProps = IChartComponentProps

  const chartRender = (props: QueryRendererRenderProps) => (
    <div className={chart} style={{ height: '279px' }}>
      {renderChart(lineRender)(props)}
    </div>
  )

  const VisibleExplanationsContent = () => {
    return (
      <p className={explanationsParagraph}>
        График отражает количество публикаций по выбранной категории или препарату за календарный месяц. Информация
        собрана по ТОП-5 фарм компаний и аптечных сетей
      </p>
    )
  }

  if (!props.categoriesSelected) {
    return (
      <>
        <div className={blockChart}>
          <h2 className={blockChartTitle}>Публикация курсов по категории</h2>
          <Box pt={2}>
            <WarningTextBlock text="Для того, чтобы на&nbsp;графике отобразились данные, укажите в&nbsp;фильтре категорию или препарат" />
          </Box>
          <div className={chart} style={{ height: '279px' }}>
            <CartesianChart data={[]} ChartComponent={LineChart} />
          </div>
          <Explanations visibleContent={<VisibleExplanationsContent />} />
        </div>
      </>
    )
  } else {
    return (
      <div className={blockChart}>
        <h2 className={blockChartTitle}>Публикация курсов по категории</h2>
        <CubeQueryRender
          queries={{
            [props.byAnotherInOwnOrganizationQueryKey]: {
              measures: [props.byAnotherInOwnOrganizationCount],
              dimensions: [props.anotherInOwnOrganizationBranchIdSelector, props.byAnotherInOwnOrganizationStartDate],
              order: props.orderAnotherInOwnOrganization,
              filters: props.anotherInOwnOrganizationFilter,
            },
            [props.byAnotherOrganizationQueryKey]: {
              measures: [props.byAnotherOrganizationCount],
              dimensions: [props.anotherOrganizationBranchIdSelector, props.byAnotherOrganizationStartDate],
              order: props.orderAnotherOrganization,
              filters: props.anotherOrganizationFilter,
            },
          }}
          render={chartRender}
        />
        <Explanations visibleContent={<VisibleExplanationsContent />} />
      </div>
    )
  }
}

export default PublicationByCategoryChart
