import { Action, combineReducers } from 'redux'
import { FormStateMap, reducer as formReducer } from 'redux-form'
import { ThunkDispatch } from 'redux-thunk'
import permissionsReducer, { IPermissionsState } from 'shared/reducers/CourseDesigner/permissionsReducer'

import { createAction } from '@reduxjs/toolkit'

import applicationReducer, { IApplicationState } from '../../shared/reducers/application/applicationReducer'
import { courseDesignerReducers, ICourseDesignerReducersState } from '../../shared/reducers/CourseDesigner'
import courseInfoReducer, { ICourseInfoState } from '../../shared/reducers/courseInfo/courseInfoReducer'
import courseReviewReducer, { ICourseReviewState } from '../../shared/reducers/courseInfo/courseReviewReducer'
import { courseStatisticRootReducer, CourseStatisticsFilterState } from '../../shared/reducers/courseStatistics'
import menuItemsReducer, { IMenuItemsState } from '../../shared/reducers/menuItems/menuItemsReducer'
import organizationCourseReducer, {
  OrganizationCourseState,
} from '../../shared/reducers/organizationCourses/organizationCourseReducer'
import personalProfileReducer, {
  IPersonalProfileState,
} from '../../shared/reducers/personalProfile/personalProfileReducer'
import coursesReducer, { ICoursesState } from './coursesReducer'
import employeeCourseStatisticsReducer, { EmployeeCourseStatisticsState } from './employeeCourseStatisticsReducer'
import employeeStatisticsReducer, { EmployeeStatisticFiltersState } from './employeeStatisticsReducer'
import pharmacyApplicationsReducer, { IPharmacyApplicationsState } from './pharmacyApplicationsReducer'
import pharmacyOrganizationStatisticsReducer, {
  IPharmacyOrganizationStatisticFiltersState,
} from './pharmacyOrganizationStatisticsReducer'
import pharmacyReducer, { IPharmacyState } from './pharmacyReducer'
import pharmacistEmployeesReducer, { IPharmacyEmployeeState } from './pharmacistEmployeesReducer'
import businessTrainersReducer, { IBusinessTrainersState } from './businessTrainersReducer'
import alertReducer, { IAlertInitialState } from 'shared/reducers/alert/alertReducer'
import businessTrainersInfoReducer, { IBusinessTrainerInfoState } from './businessTrainerInfoReducer'
import pharmacistStatisticsReducer, { IPharmacistStatisticsState } from 'shared/reducers/pharmacistStatisticsInfo/pharmacistStatisticsInfoReducer'

export interface IAppState {
  dispatch: AppDispatch
  form: FormStateMap
  businessTrainers: IBusinessTrainersState
  businessTrainerInfo: IBusinessTrainerInfoState
  statisticPharmacist: IPharmacyEmployeeState
  pharmacy: IPharmacyState
  pharmacyGroupStatistics: IPharmacyOrganizationStatisticFiltersState
  courses: ICoursesState
  courseInfo: ICourseInfoState
  employeeStatistics: EmployeeStatisticFiltersState
  employeeCourseStatistics: EmployeeCourseStatisticsState
  courseDesigner: ICourseDesignerReducersState
  application: IApplicationState
  courseReview: ICourseReviewState
  menuItems: IMenuItemsState
  courseStatisticFilters: CourseStatisticsFilterState
  organizationCourses: OrganizationCourseState
  profile: IPersonalProfileState
  pharmacyApplications: IPharmacyApplicationsState
  permissions: IPermissionsState
  alert: IAlertInitialState,
  pharmacistStatistics: IPharmacistStatisticsState
}

export type AppDispatch = ThunkDispatch<IAppState, unknown, Action>

const appReducer = combineReducers({
  form: formReducer,
  businessTrainers: businessTrainersReducer,
  businessTrainerInfo: businessTrainersInfoReducer,
  statisticPharmacist: pharmacistEmployeesReducer,
  pharmacy: pharmacyReducer,
  pharmacyGroupStatistics: pharmacyOrganizationStatisticsReducer,
  courses: coursesReducer,
  courseInfo: courseInfoReducer,
  employeeStatistics: employeeStatisticsReducer,
  courseDesigner: courseDesignerReducers,
  application: applicationReducer,
  courseReview: courseReviewReducer,
  menuItems: menuItemsReducer,
  courseStatisticFilters: courseStatisticRootReducer,
  employeeCourseStatistics: employeeCourseStatisticsReducer,
  organizationCourses: organizationCourseReducer,
  profile: personalProfileReducer,
  pharmacyApplications: pharmacyApplicationsReducer,
  permissions: permissionsReducer,
  alert: alertReducer,
  pharmacistStatistics: pharmacistStatisticsReducer
})

export const resetAppState = createAction('RESET_STATE')

export const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    state = undefined
  }
  return appReducer(state, action)
}
