import React, { ReactElement, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { GuidHelper } from '../../../external/rp.ui/helpers/GuidHelper'
import Card from '../../../shared/components/CourseReviews/Card'
import LoaderSpinner from '../../../shared/components/LoaderSpinner'
import StarRating from '../../../shared/components/StarsRating'
import TablePaging from '../../../shared/components/TablePaging/TablePaging'
import { ICourseReview } from '../../../shared/proto/models'
import { fetchReviews } from '../../../shared/reducers/courseInfo/courseReviewReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'
import {
  cardItem,
  cardList,
  header,
  headerRating,
  headerRatingInfo,
  headerRatingText,
  headerTitle,
  headerWrapper,
  paging,
  sectionContent,
} from './CourseReviews.module.scss'

interface IEmployeeCourseReviewsPageOwnProps {
  path: string
}

interface IEmployeeCourseReviewsPageStateProps {
  dispatch: AppDispatch
  loading: boolean
  totalGrade: number
  reviews?: ICourseReview[]
  reviewsCount?: number
  courseId?: string
}

interface IEmployeeCourseReviewsPageDispatchProps {
  onFetchData: (courseId: string, pageIndex: number, pageSize: number) => void
}

type EmployeeCourseReviewsPageProps = IEmployeeCourseReviewsPageOwnProps &
  IEmployeeCourseReviewsPageStateProps &
  IEmployeeCourseReviewsPageDispatchProps

const formatter = new Intl.NumberFormat('ru', { minimumFractionDigits: 2, maximumFractionDigits: 2 })

const EmployeeCourseReviewsPage = (
  props: EmployeeCourseReviewsPageProps
): ReactElement<EmployeeCourseReviewsPageProps> => {
  const pageSizeOptions = [25, 50, 75]
  const [pageSize, setPageSize] = useState(pageSizeOptions[0])
  const [pageIndex, setPageIndex] = useState(0)

  const gotoPage = (pageNum: number) => {
    setPageIndex(pageNum)
  }

  const changePageSize = (pageSize: number) => {
    setPageSize(pageSize)
    setPageIndex(pageSize > props.reviewsCount ? 0 : pageIndex)
  }

  useEffect(() => {
    props.onFetchData(props.courseId, pageIndex, pageSize)
  }, [pageIndex, pageSize])

  return (
    <section className={sectionContent}>
      {props.loading ? (
        <LoaderSpinner />
      ) : (
        <>
          <header className={header}>
            <div className={headerWrapper}>
              <div className={headerRatingInfo}>
                <h2 className={headerTitle}>Общая оценка курса:</h2>
                <StarRating totalGrade={props.totalGrade} />
              </div>

              <div className={headerRating}>
                <strong className={headerRatingText}>
                  {props.totalGrade ? formatter.format(props.totalGrade) : 0}
                </strong>
              </div>
            </div>
          </header>
          <ul className={cardList}>
            {props.reviews ? (
              props.reviews.map((item) => (
                <li key={`card-${item.id}`} className={cardItem}>
                  <Card
                    fullName={item.fullName}
                    reviewText={item.reviewText}
                    grade={item.grade}
                    date={item.date}
                    profileImg={item.profileImg}
                  />
                </li>
              ))
            ) : (
              <></>
            )}
          </ul>
        </>
      )}
      <div className={paging}>
        <TablePaging
          gotoPage={gotoPage}
          pageCount={Math.floor(props.reviewsCount / pageSize) + 1}
          pageIndex={pageIndex}
          pageSizeOptions={pageSizeOptions}
          setPageSize={changePageSize}
        />
      </div>
    </section>
  )
}

const mapStateToProps = (state: IAppState): IEmployeeCourseReviewsPageStateProps => ({
  dispatch: state.dispatch,
  reviews: state.courseReview.reviews,
  totalGrade: state.courseReview.totalGrade,
  loading: state.courseReview.loading,
  reviewsCount: state.courseReview.reviewsCount,
  courseId: state.courseInfo.course.id ? GuidHelper.toString(state.courseInfo.course.id) : null,
})

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    onFetchData: (publicationId: string, pageIndex: number, pageSize: number) => {
      const url = 'pharmacy-office/employee-course/get-reviews/'
      dispatch(fetchReviews(url, publicationId, pageIndex, pageSize))
    },
  }
}

export default connect<
  IEmployeeCourseReviewsPageStateProps,
  IEmployeeCourseReviewsPageDispatchProps,
  IEmployeeCourseReviewsPageOwnProps,
  IAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeCourseReviewsPage)
