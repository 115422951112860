import classNames from 'classnames'
import { IBusinessTrainersCard } from 'pharmacy-office/proto/models'
import {
  clearBusinessTrainerCard,
  loadBusinessTrainerCardList,
} from 'pharmacy-office/reducers/businessTrainerInfoReducer'
import { IBusinessTrainerCardRequest } from 'pharmacy-office/reducers/businessTrainersReducer'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInterface,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useTable,
  UseTableOptions,
} from 'react-table'
import SearchInput from 'shared/components/SearchInput'
import Table from 'shared/components/Table'
import TablePaging from 'shared/components/TablePaging'

import { tableCell, tableText, tableTextContainer } from '../../../shared/components/Table/Table.module.scss'
import { useAppDispatch, useAppSelector } from '../App/hook'
import {
  section,
  sectionContent,
  sectionHeader,
  sectionSearch,
  tableCellAppointed,
  tableCellCategory,
  tableCellName,
  tableCellPassed,
} from './BusinessTrainerInfo.module.scss'

interface IBusinessTrainerInfoTableOwnProps {
  trainerId: string
}

const BusinessTrainerInfoTable = (props: IBusinessTrainerInfoTableOwnProps): ReactElement => {
  const loading = useAppSelector((state) => state.businessTrainerInfo.businessTrainersCardLoading)
  const data = useAppSelector((state) => state.businessTrainerInfo.businessTrainerCards)
  const rowCount = useAppSelector((state) => state.businessTrainerInfo.businessTrainersCardCount)
  const trainerId = props.trainerId

  const dispatch = useAppDispatch()

  const [search, setSearch] = useState('')
  const pageSizeOptions = [25, 50, 75]
  const [localPageSize, setLocalPageSize] = useState(pageSizeOptions[0])

  interface IFetchDataProps {
    pageIndex: number
    pageSize: number
    search: string
  }

  const onFetchData = async ({ pageIndex, pageSize, search }: IFetchDataProps) => {
    const request: IBusinessTrainerCardRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      trainerId: trainerId,
      searchQuery: search,
    }

    if (search.trim() !== '') {
      gotoPage(0)
    }

    dispatch(loadBusinessTrainerCardList(request))
  }

  const getCellClass = (column: ColumnInterface<IBusinessTrainersCard>) => {
    switch (column.id) {
      case 'courseName': {
        return classNames(tableCell, tableCellName)
      }
      case 'category': {
        return classNames(tableCell, tableCellCategory)
      }
      case 'appointed': {
        return classNames(tableCell, tableCellAppointed)
      }
      case 'passed': {
        return classNames(tableCell, tableCellPassed)
      }
    }
  }

  const columns: Column<IBusinessTrainersCard>[] = useMemo(
    () => [
      {
        Header: 'Наименование',
        id: 'courseName',
        disableSortBy: true,
        Cell: (cell: Cell<IBusinessTrainersCard>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.courseName}</span>
            </p>
          )
        },
      },
      {
        Header: 'Категории',
        id: 'category',
        disableSortBy: true,
        Cell: (cell: Cell<IBusinessTrainersCard>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.category}</span>
            </p>
          )
        },
      },
      {
        Header: 'Назначено',
        id: 'appointed',
        disableSortBy: true,
        Cell: (cell: Cell<IBusinessTrainersCard>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.appointed}</span>
            </p>
          )
        },
      },
      {
        Header: 'Сдано',
        id: 'passed',
        disableSortBy: true,
        Cell: (cell: Cell<IBusinessTrainersCard>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.passed}</span>
            </p>
          )
        },
      },
    ],
    []
  )

  const memoData = useMemo(() => {
    return data
  }, [data])

  const tablePageCount = useMemo(
    () => Math.ceil(rowCount / (localPageSize || pageSizeOptions[0])),
    [rowCount, localPageSize]
  )

  const initialState: Partial<TableState<IBusinessTrainersCard>> & Partial<UsePaginationState<IBusinessTrainersCard>> =
    {
      pageSize: localPageSize,
    }

  const tableOptions: TableOptions<IBusinessTrainersCard> &
    UsePaginationOptions<IBusinessTrainersCard> &
    UseTableOptions<IBusinessTrainersCard> = {
    data: memoData,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    manualPagination: true,
  }

  const table = useTable<IBusinessTrainersCard>(tableOptions, usePagination) as TableInstance<IBusinessTrainersCard> &
    UsePaginationInstanceProps<IBusinessTrainersCard>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize } = table.state as UsePaginationState<IBusinessTrainersCard>

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  useEffect(() => {
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      search,
    })
    return () => {
      dispatch(clearBusinessTrainerCard())
    }
  }, [pageIndex, pageSize, search])

  return (
    <section className={section}>
      <div className={sectionContent}>
        <div className={sectionHeader}>
          <div className={sectionSearch}>
            <SearchInput
              fullWidth={true}
              placeholder="Введите запрос для поиска"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(e.target.value)
              }}
            />
          </div>
        </div>
        <Table<IBusinessTrainersCard>
          tableInstance={table}
          loading={loading}
          getCellClass={getCellClass}
          rowWrap="wrap"
        />
        <TablePaging
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSizeOptions={pageSizeOptions}
          setLocalPageSize={setLocalPageSize}
          setPageSize={setPageSize}
        />
      </div>
    </section>
  )
}

export default BusinessTrainerInfoTable
