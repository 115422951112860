import React, { ReactElement } from 'react'
import { connect } from 'react-redux'

import { IEmployeeCourses } from '../../../../proto/models'
import { getEmployeeCourses, IEmployeeCoursesRequest } from '../../../../reducers/pharmacyReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import EmployeeCourseTable from './EmployeeCourseTable'
import { section } from './EmployeeCourseTable.module.scss'

interface IEmployeeCoursePageOwnProps {
  path: string
  groupId?: string
  pharmacyId?: string
  employeeId?: string
  employees?: boolean
}

interface IEmployeeCoursePageStateProps {
  dispatch: AppDispatch
}

type EmployeeCoursePageProps = IEmployeeCoursePageOwnProps & IEmployeeCoursePageStateProps

const EmployeeCoursePage = (props: EmployeeCoursePageProps): ReactElement<EmployeeCoursePageProps> => {
  const getEmployeeCoursesHandler = (options: IEmployeeCoursesRequest): Promise<IEmployeeCourses> => {
    return props.dispatch(getEmployeeCourses(options))
  }

  return (
    <div className={section}>
      <EmployeeCourseTable
        employees={props.employees}
        employeeId={props.employeeId}
        groupId={props.groupId}
        pharmacyId={props.pharmacyId}
        getEmployeeCourses={getEmployeeCoursesHandler}
      />
    </div>
  )
}

const mapStateToProps = (store: IAppState): IEmployeeCoursePageStateProps => {
  return {
    dispatch: store.dispatch,
  }
}

export default connect<IEmployeeCoursePageStateProps, {}, IEmployeeCoursePageOwnProps, IAppState>(mapStateToProps)(
  EmployeeCoursePage
)
