/* eslint-disable react/display-name */
import classNames from 'classnames'
import { Link, withPrefix } from 'gatsby'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  Cell,
  Column,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
} from 'react-table'

import { GuidHelper } from '../../../../../external/rp.ui/helpers/GuidHelper'
import TextButton from '../../../../../shared/components/Buttons/TextButton'
import HideArchiveIcon from '../../../../../shared/components/Icons/HideArchiveIcon'
import PreviewIcon from '../../../../../shared/components/Icons/PreviewIcon'
import SearchInput from '../../../../../shared/components/SearchInput'
import Table from '../../../../../shared/components/Table'
import {
  tableButtons,
  tableButtonsPreview,
  tableCell,
  tableText,
  tableTextContainer,
  tableTextGreen,
  tableTextRed,
} from '../../../../../shared/components/Table/Table.module.scss'
import TablePaging from '../../../../../shared/components/TablePaging/TablePaging'
import { EmployeeCourses, EStudentCourseStatus, IEmployeeCourse, IEmployeeCourses } from '../../../../proto/models'
import { IEmployeeCoursesRequest } from '../../../../reducers/pharmacyReducer'
import {
  section,
  sectionButtons,
  sectionContent,
  sectionContentHeader,
  sectionSearch,
  tableCellCategory,
  tableCellEdit,
  tableCellManufacturer,
  tableCellName,
  tableCellStatus,
} from './EmployeeCourseTable.module.scss'

const url = 'pharmacy-office'

interface IEmployeeTableState {
  employeeId?: string
  groupId?: string
  pharmacyId?: string
  employees?: boolean
  getEmployeeCourses: (options: IEmployeeCoursesRequest) => Promise<IEmployeeCourses>
}

function statusToLocale(status: EStudentCourseStatus) {
  switch (status) {
    case EStudentCourseStatus.InProgress:
      return 'Изучаемый'
    case EStudentCourseStatus.Blocked:
      return 'Заблокирован'
    case EStudentCourseStatus.Passed:
      return 'Курс пройден'
    case EStudentCourseStatus.Failed:
      return 'Курс не сдан'
    default:
      return 'Новый'
  }
}

const EmployeeCourseTable = (props: IEmployeeTableState): ReactElement<IEmployeeTableState> => {
  const [fetchedData, setFetchedData] = useState<IEmployeeCourse[]>([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [hideArchive, setHideArchive] = useState(false)
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const pageSizeOptions = [25, 50, 75]
  const [localPageSize, setLocalPageSize] = useState(pageSizeOptions[0])

  interface FetchDataProps {
    pageIndex: number
    pageSize: number
    sortBy: Array<SortingRule<EmployeeCourses>>
    hide: boolean
    search: string
  }

  const onFetchData = async ({ pageIndex, pageSize, sortBy, hide, search }: FetchDataProps) => {
    setLoading(true)
    const paging: IEmployeeCoursesRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortBy[0]?.id || 'name',
      sortDirection: sortBy[0]?.desc ?? true ? 'desc' : 'asc',
      employeeId: props.employeeId,
      hideInactive: hide,
      searchQuery: search,
    }

    const response = await props.getEmployeeCourses(paging)

    setFetchedData(response?.items ?? [])
    setTotalRowCount(response?.rowCount ?? 0)
    setLoading(false)
  }

  const getCellClass = (column: Column<IEmployeeCourse>) => {
    switch (column.id) {
      case 'name': {
        return classNames(tableCell, tableCellName)
      }
      case 'manufacturer': {
        return classNames(tableCell, tableCellManufacturer)
      }
      case 'businessCategory': {
        return classNames(tableCell, tableCellCategory)
      }
      case 'status': {
        return classNames(tableCell, tableCellStatus)
      }
      case 'edit': {
        return classNames(tableCell, tableCellEdit)
      }
    }
  }

  const columns: Column<IEmployeeCourse>[] = React.useMemo(
    () => [
      {
        Header: 'Наименование',
        id: 'name',
        accessor: 'name',
        sortType: 'basic',
        Cell: (cell: Cell<IEmployeeCourse>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText} title={cell.value}>
                {cell.value}
              </span>
            </p>
          )
        },
      },
      {
        Header: 'Организация',
        id: 'manufacturer',
        accessor: 'manufacturer',
        sortType: 'basic',
        Cell: (cell: Cell<IEmployeeCourse>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
      },
      {
        Header: 'Категории',
        id: 'businessCategory',
        accessor: 'businessCategory',
        disableSortBy: true,
        Cell: (cell: Cell<IEmployeeCourse>) => {
          return (
            <>
              {cell.row.original.businessCategory && (
                <p className={tableTextContainer}>
                  <span className={tableText}>{cell.row.original.businessCategory}</span>
                </p>
              )}
              {cell.row.original.medicalCategory && (
                <p className={tableTextContainer}>
                  <span className={tableText}>{cell.row.original.medicalCategory}</span>
                </p>
              )}
            </>
          )
        },
      },
      {
        Header: 'Статус',
        id: 'status',
        accessor: 'status',
        disableSortBy: true,
        Cell: (cell: Cell<IEmployeeCourse>) => (
          <p className={tableTextContainer}>
            <span
              className={classNames(tableText, {
                [tableTextGreen]: cell.value === EStudentCourseStatus.Passed,
                [tableTextRed]: cell.value === EStudentCourseStatus.Failed,
              })}
            >
              {statusToLocale(cell.value)}
            </span>
          </p>
        ),
      },
      {
        id: 'edit',
        accessor: 'id',
        disableSortBy: true,
        Cell: (cell: Cell<IEmployeeCourse>) => {
          let link = `${url}/organization/group/${props.groupId}/branches/${props.pharmacyId}/employee/${
            props.employeeId
          }/course/${GuidHelper.toString(cell.row.original.id)}/info`

          if (props.employees) {
            link = `${url}/statistics/pharmacist/${props.employeeId}/course/${GuidHelper.toString(
              cell.row.original.id
            )}/info`
          }
          return (
            <div className={tableButtons}>
              <Link className={tableButtonsPreview} to={withPrefix(link)}>
                <PreviewIcon />
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )

  const data = React.useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const tablePageCount = React.useMemo(
    () => Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0])),
    [totalRowCount, localPageSize]
  )

  const initialState: Partial<TableState<IEmployeeCourse>> &
    Partial<UsePaginationState<IEmployeeCourse>> &
    UseSortByState<IEmployeeCourse> = {
    pageSize: localPageSize,
    sortBy: [{ id: 'name', desc: true }],
  }

  const tableOptions: TableOptions<IEmployeeCourse> &
    UseSortByOptions<IEmployeeCourse> &
    UsePaginationOptions<IEmployeeCourse> = {
    data: data,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    manualSortBy: true,
    manualPagination: true,
    disableSortRemove: true,
  }

  const table = useTable<IEmployeeCourse>(tableOptions, useSortBy, usePagination) as TableInstance<IEmployeeCourse> &
    UsePaginationInstanceProps<IEmployeeCourse>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize, sortBy } = table.state as UsePaginationState<IEmployeeCourse> &
    UseSortByState<IEmployeeCourse>

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  useEffect(() => {
    setLoading(true)
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      hide: hideArchive,
      search,
    })
  }, [pageIndex, pageSize, sortBy, hideArchive, search])

  const hideArchiveHandler = () => {
    setHideArchive(!hideArchive)
    setLoading(true)
  }

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
    setLoading(true)
  }

  return (
    <div className={section}>
      <div className={sectionContent}>
        <div className={sectionContentHeader}>
          <div className={sectionButtons}>
            <TextButton
              text={hideArchive === true ? 'Показать завершенные' : 'Скрыть завершенные'}
              onClick={hideArchiveHandler}
              variant="outlined"
              iconLeft={<HideArchiveIcon checked={hideArchive === true} />}
            />
          </div>
          <div className={sectionSearch}>
            <SearchInput placeholder="Поиск по курсам" onChange={searchHandler} />
          </div>
        </div>
        <Table<IEmployeeCourse> tableInstance={table} loading={loading} getCellClass={getCellClass} rowWrap="wrap" />
        <TablePaging
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSizeOptions={pageSizeOptions}
          setLocalPageSize={setLocalPageSize}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  )
}

export default EmployeeCourseTable
