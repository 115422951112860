/* eslint-disable react/display-name */
import classNames from 'classnames'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { Link, withPrefix } from 'gatsby'
import { useAppDispatch, useAppSelector } from 'pharmacy-office/components/App/hook'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInterface,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
  UseTableOptions,
} from 'react-table'
import DeleteButton from 'shared/components/Buttons/DeleteButton'
import PreviewIcon from 'shared/components/Icons/PreviewIcon'
import AlertObjectRemove from 'shared/components/Modal/AlertObjectRemove'
import SearchInput from 'shared/components/SearchInput'
import Table from 'shared/components/Table'
import TablePaging from 'shared/components/TablePaging'

import {
  tableButtons,
  tableButtonsPreview,
  tableCell,
  tableText,
  tableTextContainer,
} from '../../../../shared/components/Table/Table.module.scss'
import { IBusinessTrainer, IBusinessTrainerList, UserPharmacyOrganization } from '../../../proto/models'
import {
  clearBusinessTrainers,
  deleteBusinessTrainer,
  IBusinessTrainersRequest,
  loadBusinessTrainers,
} from '../../../reducers/businessTrainersReducer'
import {
  section,
  sectionContent,
  sectionHeader,
  sectionSearch,
  tableCellButtons,
  tableCellCreatedAndPublishedCourse,
  tableCellEmail,
  tableCellName,
  tableCellPhoneNumber,
} from './BusinessTrainers.module.scss'

const BusinessTrainersTable = (): ReactElement => {
  const loading = useAppSelector((state) => state.businessTrainers.businessTrainersLoading)
  const data = useAppSelector((state) => state.businessTrainers.businessTrainers)
  const rowCount = useAppSelector((state) => state.businessTrainers.businessTrainersCount)
  const organizationId = useAppSelector((state) => state.pharmacy.organization.id)

  const dispatch = useAppDispatch()

  const [search, setSearch] = useState('')
  const pageSizeOptions = [25, 50, 75]
  const [localPageSize, setLocalPageSize] = useState(pageSizeOptions[0])
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [deleteRecordId, setDeleteRecordId] = useState(null)

  interface IFetchDataProps {
    pageIndex: number
    pageSize: number
    sortBy: Array<SortingRule<IBusinessTrainerList>>
    search: string
  }

  const onFetchData = async ({ pageIndex, pageSize, sortBy, search }: IFetchDataProps) => {
    const request: IBusinessTrainersRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortBy[0]?.id || 'name',
      sortDirection: sortBy[0]?.desc ?? true ? 'desc' : 'asc',
      branchId: GuidHelper.toString(organizationId),
      searchQuery: search,
    }

    dispatch(loadBusinessTrainers(request))
  }

  const getCellClass = (column: ColumnInterface<IBusinessTrainerList>) => {
    switch (column.id) {
      case 'name': {
        return classNames(tableCell, tableCellName)
      }
      case 'phoneNumber': {
        return classNames(tableCell, tableCellPhoneNumber)
      }
      case 'email': {
        return classNames(tableCell, tableCellEmail)
      }
      case 'createdAndPublishedCourse': {
        return classNames(tableCell, tableCellCreatedAndPublishedCourse)
      }
      case 'rowButtons': {
        return classNames(tableCell, tableCellButtons)
      }
    }
  }

  const columns: Column<IBusinessTrainer>[] = useMemo(
    () => [
      {
        Header: 'ФИО',
        id: 'name',
        accessor: 'name',
        Cell: (cell: Cell<IBusinessTrainer>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.name}</span>
            </p>
          )
        },
      },
      {
        Header: 'Телефон',
        id: 'phoneNumber',
        disableSortBy: true,
        Cell: (cell: Cell<IBusinessTrainer>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.phoneNumber}</span>
            </p>
          )
        },
      },
      {
        Header: 'Почта',
        id: 'email',
        disableSortBy: true,
        Cell: (cell: Cell<IBusinessTrainer>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.email}</span>
            </p>
          )
        },
      },
      {
        Header: 'Создано/Опубликовано курсов',
        id: 'createdAndPublishedCourse',
        disableSortBy: true,
        Cell: (cell: Cell<IBusinessTrainer>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.createdAndPublishedCourse}</span>
            </p>
          )
        },
      },
      {
        id: 'rowButtons',
        Cell: (cell: Cell<IBusinessTrainer>) => (
          <div className={tableButtons}>
            <Link
              className={tableButtonsPreview}
              to={withPrefix(
                `pharmacy-office/statistics/business-trainers/${GuidHelper.toString(cell.row.original.id)}`
              )}
            >
              <PreviewIcon />
            </Link>
            <DeleteButton
              iconColor="blue"
              onClick={() => {
                setDeleteRecordId(cell.row.original.id)
                setOpenRemoveModal(true)
              }}
            />
          </div>
        ),
        width: 135,
      },
    ],
    []
  )

  const memoData = useMemo(() => {
    return data
  }, [data])

  const tablePageCount = useMemo(
    () => Math.ceil(rowCount / (localPageSize || pageSizeOptions[0])),
    [rowCount, localPageSize]
  )

  const initialState: Partial<TableState<IBusinessTrainer>> &
    Partial<UsePaginationState<IBusinessTrainer>> &
    UseSortByState<IBusinessTrainer> = {
    pageSize: localPageSize,
    sortBy: [{ id: 'name', desc: true }],
  }

  const tableOptions: TableOptions<IBusinessTrainer> &
    UseSortByOptions<IBusinessTrainer> &
    UsePaginationOptions<IBusinessTrainer> &
    UseTableOptions<IBusinessTrainer> = {
    data: memoData,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    disableSortRemove: true,
    manualSortBy: true,
    manualPagination: true,
  }

  const table = useTable<IBusinessTrainer>(tableOptions, useSortBy, usePagination) as TableInstance<IBusinessTrainer> &
    UsePaginationInstanceProps<IBusinessTrainer>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize, sortBy } = table.state as UsePaginationState<IBusinessTrainer> &
    UseSortByState<IBusinessTrainer>

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  useEffect(() => {
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      search,
    })
    return () => {
      dispatch(clearBusinessTrainers())
    }
  }, [pageIndex, sortBy, pageSize, search])

  const agreeRemoveCallback = () => {
    dispatch(deleteBusinessTrainer(organizationId, deleteRecordId))
    setOpenRemoveModal(false)

    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      search,
    })
  }

  return (
    <section className={section}>
      <div className={sectionContent}>
        <div className={sectionHeader}>
          <div className={sectionSearch}>
            <SearchInput
              fullWidth={true}
              placeholder="Введите запрос для поиска"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(e.target.value)
              }}
            />
          </div>
        </div>
        <Table<IBusinessTrainer> tableInstance={table} loading={loading} getCellClass={getCellClass} rowWrap="nowrap" />
        <TablePaging
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSizeOptions={pageSizeOptions}
          setLocalPageSize={setLocalPageSize}
          setPageSize={setPageSize}
        />
        <AlertObjectRemove
          isOpen={openRemoveModal}
          title={'Вы точно хотите удалить пользователя?'}
          agreeString={'Да'}
          disagreeString={'Нет'}
          agreeCallback={agreeRemoveCallback}
          handleClose={() => setOpenRemoveModal(false)}
        />
      </div>
    </section>
  )
}

export default BusinessTrainersTable
