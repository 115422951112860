import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Dispatch } from 'redux'

import { createAction, createReducer } from '@reduxjs/toolkit'

import {
  ApplicationApproveRequest,
  ApplicationList,
  ApplicationRejectRequest,
  IApplication,
  IApplicationApproveRequest,
  IApplicationList,
  IApplicationRejectRequest,
} from '../proto/models'
import { IAppState } from './rootReducer'

export interface IApplicationRequest {
  pageSize: number
  pageIndex: number
  sortColumn: string
  sortDirection: string
}

export interface IPharmacyApplicationsState {
  requireConfirmation: IApplication[]
  loadingRequireConfirmation: boolean
  reject: IApplication[]
  loadingReject: boolean
}

const initialState: IPharmacyApplicationsState = {
  requireConfirmation: null,
  loadingRequireConfirmation: true,
  reject: null,
  loadingReject: true,
}

const url = 'pharmacy-office/application'

const SET_APPLICATIONS_LOADING = 'SET_APPLICATIONS_LOADING'

const GET_APPLICATIONS_APPROVE = 'GET_APPLICATIONS_APPROVE'
export const getApplicationsRequireConfirmation = (options: IApplicationRequest) => async (
  dispatch: Dispatch,
  getState: () => IAppState
): Promise<IApplicationList> => {
  const loading = getState().pharmacyApplications.loadingRequireConfirmation

  if (!loading) {
    dispatch({ type: SET_APPLICATIONS_LOADING, payload: true })
  }

  const response = await ProtoClient.get<IApplicationList>(url + '/list-approve', ApplicationList, options)

  dispatch({ type: GET_APPLICATIONS_APPROVE, payload: response.items })
  return response
}

const RESET_APPLICATIONS = 'RESET_APPLICATIONS'
export const resetStateApplications = createAction(RESET_APPLICATIONS)

const RESET_APPLICATIONS_REJECT = 'RESET_APPLICATIONS_REJECT'
export const resetStateApplicationsReject = createAction(RESET_APPLICATIONS_REJECT)

const SET_APPLICATIONS_REJECT_LOADING = 'SET_APPLICATIONS_REJECT_LOADING'

const GET_APPLICATIONS_REJECT = 'GET_APPLICATIONS_REJECT'
export const getApplicationsReject = (options: IApplicationRequest) => async (
  dispatch: Dispatch,
  getState: () => IAppState
): Promise<IApplicationList> => {
  const loading = getState().pharmacyApplications.loadingReject

  if (!loading) {
    dispatch({ type: SET_APPLICATIONS_REJECT_LOADING, payload: true })
  }

  const response = await ProtoClient.get<IApplicationList>(url + '/list-rejected', ApplicationList, options)

  dispatch({ type: GET_APPLICATIONS_REJECT, payload: response.items })
  return response
}

export const addApplicationsRequireConfirmation = (ids: Uint8Array[]) => async (dispatch: Dispatch): Promise<void> => {
  const values: IApplicationApproveRequest = {
    applicationIds: ids,
  }
  dispatch({ type: SET_APPLICATIONS_LOADING, payload: true })
  await ProtoClient.post(url + '/approve', values, ApplicationApproveRequest)
}

export const rejectApplicationsRequireConfirmation = (options: IApplicationRejectRequest) => async (
  dispatch: Dispatch
): Promise<void> => {
  dispatch({ type: SET_APPLICATIONS_LOADING, payload: true })
  await ProtoClient.post(url + '/decline', options, ApplicationRejectRequest)
}

const pharmacyApplicationsReducer = createReducer(initialState, {
  [SET_APPLICATIONS_LOADING]: (state, action) => {
    state.loadingRequireConfirmation = action.payload
  },
  [SET_APPLICATIONS_REJECT_LOADING]: (state, action) => {
    state.loadingReject = action.payload
  },
  [GET_APPLICATIONS_APPROVE]: (state, action) => {
    state.requireConfirmation = action.payload
    state.loadingRequireConfirmation = false
  },
  [RESET_APPLICATIONS]: (state) => {
    state.requireConfirmation = initialState.requireConfirmation
    state.loadingRequireConfirmation = initialState.loadingRequireConfirmation
  },
  [GET_APPLICATIONS_REJECT]: (state, action) => {
    state.reject = action.payload
    state.loadingReject = false
  },
  [RESET_APPLICATIONS_REJECT]: (state) => {
    state.reject = initialState.reject
    state.loadingReject = initialState.loadingReject
  },
})

export default pharmacyApplicationsReducer
