/* eslint-disable react/display-name */
import { withPrefix } from 'gatsby'
import React from 'react'
import { connect } from 'react-redux'
import CourseStatisticsTable, {
  CourseStatisticsTableProps,
  ICourseStatisticsTableOwnProps,
  ICourseStatisticsTableStateProps,
} from 'shared/components/CourseStatisticsTable/CourseStatisticsTable'

import { IAppState } from '../../../reducers/rootReducer'

const Table = (props: CourseStatisticsTableProps) => (
  <CourseStatisticsTable
    getCourseLink={(courseId) => withPrefix(`pharmacy-office/statistics/courses/${courseId}/info`)}
    {...props}
  />
)

const mapStateToProps = (store: IAppState): ICourseStatisticsTableStateProps => {
  return {
    dispatch: store.dispatch,
    organization: store.pharmacy.organization,
    courseStatistics: store.organizationCourses.courseStatistics,
    loading: store.organizationCourses.loading,
  }
}

export default connect<ICourseStatisticsTableStateProps, {}, ICourseStatisticsTableOwnProps, IAppState>(
  mapStateToProps
)(Table)
