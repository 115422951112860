/* eslint-disable react/display-name */
import classNames from 'classnames'
import { DateHelper } from 'external/rp.ui/helpers/DateHelper'
import React, { ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'
import {
  Cell,
  Column,
  ColumnInterface,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
} from 'react-table'
import Table from 'shared/components/Table'
import { tableCell, tableText, tableTextContainer } from 'shared/components/Table/Table.module.scss'
import TablePaging from 'shared/components/TablePaging/TablePaging'

import { IApplication } from '../../../proto/models'
import {
  getApplicationsReject,
  IApplicationRequest,
  resetStateApplicationsReject,
} from '../../../reducers/pharmacyApplicationsReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import {
  section,
  sectionContent,
  tableCellComment,
  tableCellDate,
  tableCellLocation,
  tableCellName,
  tableCellNamePharm,
} from './ApplicationsRejectTable.module.scss'

const pageSizeOptions = [25, 50, 75]

interface IApplicationsRejectTableOwnProps {
  path: string
}

interface IApplicationsRejectTableStateProps {
  dispatch: AppDispatch
  loadingReject: boolean
}

type ApplicationsRejectTableProps = IApplicationsRejectTableOwnProps & IApplicationsRejectTableStateProps

interface IFetchDataProps {
  pageIndex: number
  pageSize: number
  sortBy: Array<SortingRule<IApplication>>
}

const ApplicationsRejectTable = (props: ApplicationsRejectTableProps): ReactElement<ApplicationsRejectTableProps> => {
  const [fetchedData, setFetchedData] = React.useState<IApplication[]>([])
  const [totalRowCount, setTotalRowCount] = React.useState(0)

  const onFetchData = async ({ pageIndex, pageSize, sortBy }: IFetchDataProps) => {
    const options: IApplicationRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: 'createDate',
      sortDirection: sortBy[0]?.desc ?? true ? 'desc' : 'asc',
    }

    const response = await props.dispatch(getApplicationsReject(options))
    setFetchedData(response?.items ?? [])
    setTotalRowCount(response?.rowCount ?? 0)
  }

  const getCellClass = (column: ColumnInterface<IApplication>) => {
    switch (column.id) {
      case 'createDate': {
        return classNames(tableCell, tableCellDate)
      }
      case 'fullName': {
        return classNames(tableCell, tableCellName)
      }
      case 'pharmacyName': {
        return classNames(tableCell, tableCellNamePharm)
      }
      case 'address': {
        return classNames(tableCell, tableCellLocation)
      }
      case 'comment': {
        return classNames(tableCell, tableCellComment)
      }
    }
  }

  const columns: Column<IApplication>[] = React.useMemo(
    () => [
      {
        Header: 'Дата',
        id: 'createDate',
        accessor: 'createDate',
        sortType: 'basic',
        Cell: (cell: Cell<IApplication>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{DateHelper.fromNumber(cell.value as number).format('DD.MM.YYYY')}</span>
          </p>
        ),
      },
      {
        Header: 'ФИО',
        id: 'fullName',
        accessor: 'fullName',
        disableSortBy: true,
        Cell: (cell: Cell<IApplication>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{cell.value}</span>
          </p>
        ),
      },
      {
        Header: 'Наименование',
        id: 'pharmacyName',
        accessor: 'pharmacyName',
        disableSortBy: true,
        Cell: (cell: Cell<IApplication>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{cell.value}</span>
          </p>
        ),
      },
      {
        Header: 'Адрес филиала',
        id: 'address',
        accessor: 'address',
        disableSortBy: true,
        Cell: (cell: Cell<IApplication>) => (
          <p className={tableTextContainer}>
            <span className={tableText} title={cell.value}>
              {cell.value}
            </span>
          </p>
        ),
      },
      {
        Header: 'Комментарий',
        id: 'comment',
        disableSortBy: true,
        Cell: (cell: Cell<IApplication>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{cell.row.original.comment}</span>
          </p>
        ),
      },
    ],
    []
  )

  const [localPageSize, setLocalPageSize] = React.useState(pageSizeOptions[0])

  const data = React.useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const tablePageCount = React.useMemo(
    () => Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0])),
    [totalRowCount, localPageSize]
  )

  const initialState: Partial<TableState<IApplication>> &
    Partial<UsePaginationState<IApplication>> &
    UseSortByState<IApplication> = {
    pageSize: localPageSize,
    sortBy: [{ id: 'createDate', desc: true }],
  }

  const tableOptions: TableOptions<IApplication> & UseSortByOptions<IApplication> & UsePaginationOptions<IApplication> =
    {
      data: data,
      columns: columns,
      pageCount: tablePageCount,
      initialState: initialState,
      manualSortBy: true,
      manualPagination: true,
      disableSortRemove: true,
    }

  const table = useTable<IApplication>(tableOptions, useSortBy, usePagination) as TableInstance<IApplication> &
    UsePaginationInstanceProps<IApplication>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize, sortBy } = table.state as UsePaginationState<IApplication> & UseSortByState<IApplication>

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 500)

  React.useEffect(() => {
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    })
  }, [pageIndex, pageSize, sortBy])

  useEffect(() => {
    return () => {
      props.dispatch(resetStateApplicationsReject())
    }
  }, [])

  return (
    <div className={section}>
      <div className={sectionContent}>
        <Table<IApplication>
          tableInstance={table}
          getCellClass={getCellClass}
          addHover={true}
          rowWrap="nowrap"
          loading={props.loadingReject}
        />
        <TablePaging
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSizeOptions={pageSizeOptions}
          setLocalPageSize={setLocalPageSize}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    loadingReject: store.pharmacyApplications.loadingReject,
  }
}

export default connect(mapStateToProps, null)(ApplicationsRejectTable)
