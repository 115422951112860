import { withPrefix } from 'gatsby'
import React from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import { IBreadcrumbs } from '../../../shared/components/Breadcrumbs'
import { IDataNavigationTab } from '../../../shared/components/NavigationTab/NavigationTab'
import StatisticsCourseInfo, {
  CourseInfoPageProps,
  ICourseInfoPageOwnProps,
  ICourseInfoPageStateProps,
} from '../../../shared/components/StatisticsCourseInfo/StatisticsCourseInfo'
import { IAppState } from '../../reducers/rootReducer'

const getDataNavigation = (props: CourseInfoPageProps): IDataNavigationTab[] => {
  const navigationTabs = [
    {
      id: nextId(),
      name: 'Информация',
      link: withPrefix(`pharmacy-office/statistics/courses/${props.courseId}/info`),
    },
    {
      id: nextId(),
      name: 'Отзывы',
      link: withPrefix(`pharmacy-office/statistics/courses/${props.courseId}/reviews`),
    },
  ]

  if (props.course.canViewStatistics) {
    navigationTabs.push({
      id: nextId(),
      name: 'Статистика',
      link: withPrefix(`pharmacy-office/statistics/courses/${props.courseId}/statistics`),
    })
  }

  return navigationTabs
}

const getDataBreadcrumbs = (): IBreadcrumbs[] => {
  return [
    {
      title: 'Статистика',
      link: withPrefix(`pharmacy-office/statistics/courses`),
    },
    {
      title: 'Информация о курсе',
      currSection: true,
    },
  ]
}

const InfoPage = (props: CourseInfoPageProps) => (
  <StatisticsCourseInfo getDataBreadcrumbs={getDataBreadcrumbs} getDataNavigation={getDataNavigation} {...props} />
)

const mapStateToProps = (store: IAppState): ICourseInfoPageStateProps => {
  return {
    dispatch: store.dispatch,
    course: store.courseInfo.course,
    imagePath: store.courseInfo.imagePath,
  }
}

export default connect<ICourseInfoPageStateProps, {}, ICourseInfoPageOwnProps, IAppState>(mapStateToProps)(InfoPage)
