import React from 'react'
import { info } from 'shared/components/CourseStatistics/CourseStatisticsInfo/BlockCharts.module.scss'
import Details, { IDetailsItemProps } from 'shared/components/CourseStatistics/Details'
import CubeQueryRender, {
  IChartComponentProps,
  ICubePivotConfig,
  renderChart,
} from 'shared/components/QueryRender/CubeQueryRender'

import { Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'
import { useAppDispatch, useAppSelector } from 'pharmacy-office/components/App/hook'
import { getPharmacistStatistics, IStatisticsRequest } from 'shared/reducers/pharmacistStatisticsInfo/pharmacistStatisticsInfoReducer'

const requestUrl = 'statistics/stat'

const round = (value: number) => Math.round((value + Number.EPSILON) * 100) / 100

const labelFormatter = (label: React.ReactText) => `${label}%`

const barRender = ({ resultSet }: IChartComponentProps) => {

  const personalStatistics = useAppSelector((state) => state.pharmacistStatistics.statistics)

  resultSet = resultSet as { [key: string]: ResultSet }
  const pivotConfig: ICubePivotConfig = {
    x: [],
    y: [],
    fillMissingDates: true,
    joinDateRange: false,
  }

  const courses = resultSet[coursesCount].tablePivot(pivotConfig)[0][coursesCount]
  const tests = resultSet[testsAverageResult].tablePivot(pivotConfig)[0][testsAverageResult]

  const dataDetails: IDetailsItemProps[] = [
    {
      title: 'Просмотрено курсов:',
      value: courses.toString(),
    },
    {
      title: 'Средний результат:',
      value: labelFormatter(round(Number(tests))),
    },
    {
      title: 'Среднее время на тест:',
      value: personalStatistics?.testTime?.toString(),
    },
    {
      title: 'Сдано с 1:',
      value: personalStatistics?.coursesPassedWithFirstTry?.toString(),
    },
    {
      title: 'Сдано с 2:',
      value: personalStatistics?.coursesPassedWithSecondTry?.toString(),
    },
    {
      title: 'Сдано с 3:',
      value: personalStatistics?.coursesPassedWithThirdTry?.toString(),
    },
    {
      title: 'Не сдано:',
      value: personalStatistics?.notPassedCourses?.toString(),
    },
    {
      title: 'Не завершено:',
      value: personalStatistics?.notCompletedCourses?.toString(),
    }
  ]

  return (
    <div className={info}>
      <Details array={dataDetails} />
    </div>
  )
}

const chartRender = (props: QueryRendererRenderProps) => renderChart(barRender)(props)

const coursesCount = 'CourseWithOutFilters.count'
const testsAverageResult = 'CourseTestSession.averageResult'

const TotalsChart = (props: TotalsChartProps): React.ReactElement<TotalsChartProps> => {

  const dispatch = useAppDispatch();

  const startDate = useAppSelector((state) => state.employeeStatistics.startDate)
  const endDate = useAppSelector((state) => state.employeeStatistics.endDate)
  const userId = useAppSelector((state) => state.statisticPharmacist.employees.employee.id)

  React.useEffect(() => {
    const isoFormat = { includeOffset: false, suppressMilliseconds: true }

    const options: IStatisticsRequest = {
      userId: userId,
      start: startDate.startOf('day').toISO(isoFormat),
      end: endDate.endOf('day').toISO(isoFormat),
    }

    dispatch(getPharmacistStatistics(requestUrl, options))
  }, [startDate, endDate])
  
  return (
    <CubeQueryRender
      queries={{
        [coursesCount]: {
          measures: [coursesCount],
          filters: props.totalFilters,
        },
        [testsAverageResult]: {
          measures: [testsAverageResult],
          filters: props.averageResultFilter,
        },
      }}
      render={chartRender}
    />
  )
}

export type TotalsChartStateProps = {
  totalFilters: Filter[]
  averageResultFilter: Filter[]
}

type TotalsChartProps = TotalsChartStateProps

export default TotalsChart
