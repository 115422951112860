import { Duration } from 'luxon'
import React from 'react'
import { connect } from 'react-redux'

import { Filter, ResultSet } from '@cubejs-client/core'
import { details } from 'shared/components/CourseStatistics/CourseStatisticsInfo/BlockCharts.module.scss'
import Details, { IDetailsItemProps } from 'shared/components/CourseStatistics/Details/Details'
import CubeQueryRender, {
  IChartComponentProps,
  ICubePivotConfig,
  renderChart,
} from 'shared/components/QueryRender/CubeQueryRender'
import { AppDispatch, CourseStatisticsFilterState } from 'shared/reducers/courseStatistics'
import { ICourseStatisticFiltersState } from 'shared/reducers/courseStatistics/courseStatisticFilterReducer'

const percentage = (dividend: number, divider: number): string => {
  if (!divider || divider === 0) {
    return '0%'
  }
  return `${(((dividend ?? 0) * 100) / (divider ?? 1)).toFixed()}%`
}

function formatTimeInterval(date: any) {
  if (!date) {
    return ''
  }
  const duration = Duration.fromObject(date).normalize()
  return `${duration.minutes} мин ${duration.seconds} сек`
}

const tableRender = ({ resultSet }: IChartComponentProps) => {
  resultSet = resultSet as ResultSet
  const pivotConfig: ICubePivotConfig = {
    x: [],
    y: [],
    fillMissingDates: true,
    joinDateRange: false,
  }
  const result = resultSet.tablePivot(pivotConfig)[0]

  const dataDetails: IDetailsItemProps[] = [
    {
      title: 'Сдали с 1 попытки',
      value: percentage(+result['CourseTestSession.countPassedFirstTry'], +result['PharmacistCourse.count']),
    },
    {
      title: 'Сдали со 2 попытки:',
      value: percentage(+result['CourseTestSession.countPassedSecondTry'], +result['PharmacistCourse.count']),
    },
    {
      title: 'Сдали с 3 попытки',
      value: percentage(+result['CourseTestSession.countPassedThirdTry'], +result['PharmacistCourse.count']),
    },
    {
      title: 'Средний балл: ',
      value: `${(+result['CourseTestSession.averageResult']).toFixed()}%`,
    },
    {
      title: 'Среднее время затраченное на тест',
      value: formatTimeInterval(result['CourseTestSession.averageTestTime']),
    },
  ]

  return (
    <div className={details}>
      <Details background={true} array={dataDetails} />
    </div>
  )
}

const DynamicsLearningTable = (props: DynamicsLearningTableProps) => (
  <CubeQueryRender
    query={{
      measures: [
        'PharmacistCourse.count',
        'CourseTestSession.averageTestTime',
        'CourseTestSession.countPassedFirstTry',
        'CourseTestSession.countPassedSecondTry',
        'CourseTestSession.countPassedThirdTry',
        'CourseTestSession.averageResult',
      ],
      filters: props.getFilters(props.courseId, props.filter),
    }}
    render={renderChart(tableRender)}
  />
)

export interface IDynamicsLearningTableOwnProps {
  courseId: string
  getFilters(courseId: string, filter: ICourseStatisticFiltersState): Filter[]
}

interface IDynamicsLearningTableStateProps {
  dispatch: AppDispatch
  filter: ICourseStatisticFiltersState
}

type DynamicsLearningTableProps = IDynamicsLearningTableOwnProps & IDynamicsLearningTableStateProps

const mapStateToProps = (store: CourseStatisticsFilterState): IDynamicsLearningTableStateProps => {
  return {
    dispatch: store.dispatch,
    filter: store.courseStatisticFilters.filter,
  }
}

export default connect<
  IDynamicsLearningTableStateProps,
  unknown,
  IDynamicsLearningTableOwnProps,
  CourseStatisticsFilterState
>(mapStateToProps)(DynamicsLearningTable)
