import { connect } from 'react-redux';
import DetailFilter, {
    DetailFilterDispatchProps, DetailFilterStateProps
} from 'shared/components/EmployeeStatistics/Filter/DetailFilter';

import { changeDetail } from '../../../../../reducers/employeeStatisticsReducer';
import { AppDispatch, IAppState } from '../../../../../reducers/rootReducer';

const mapStateToProps = (store: IAppState): DetailFilterStateProps => {
  return {
    detail: store.employeeStatistics.detail,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch): DetailFilterDispatchProps => {
  return {
    changeDetail: (detail) => {
      dispatch(changeDetail(detail))
    },
  }
}

export default connect<DetailFilterStateProps, DetailFilterDispatchProps, unknown, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(DetailFilter)
