// extracted by mini-css-extract-plugin
export var section = "ApplicationsRequireConfirmation-module--section--wjh7l";
export var sectionContent = "ApplicationsRequireConfirmation-module--section-content--VH2Y1";
export var modalClose = "ApplicationsRequireConfirmation-module--modal-close--3Id-B";
export var modal = "ApplicationsRequireConfirmation-module--modal--3maFC";
export var modalTitle = "ApplicationsRequireConfirmation-module--modal-title--3Vn9G";
export var sectionButtons = "ApplicationsRequireConfirmation-module--section-buttons--1rzMu";
export var sectionButton = "ApplicationsRequireConfirmation-module--section-button--3IpuN";
export var sectionCounter = "ApplicationsRequireConfirmation-module--section-counter--1j44h";
export var sectionCounterLabel = "ApplicationsRequireConfirmation-module--section-counter-label--t3mL4";
export var sectionCounterValue = "ApplicationsRequireConfirmation-module--section-counter-value--1m5Wc";
export var modalHeader = "ApplicationsRequireConfirmation-module--modal-header--5zp3X";
export var modalBlockComment = "ApplicationsRequireConfirmation-module--modal-block-comment--3OxWv";
export var modalBlockButtons = "ApplicationsRequireConfirmation-module--modal-block-buttons--3g0hs";
export var modalButton = "ApplicationsRequireConfirmation-module--modal-button--1z_3D";
export var tableCellCheckbox = "ApplicationsRequireConfirmation-module--table-cell-checkbox--2lGh5";
export var tableCellDate = "ApplicationsRequireConfirmation-module--table-cell-date--1uJJp";
export var tableCellName = "ApplicationsRequireConfirmation-module--table-cell-name--SYiR4";
export var tableCellNamePharm = "ApplicationsRequireConfirmation-module--table-cell-name-pharm--1z-UT";
export var tableCellLocation = "ApplicationsRequireConfirmation-module--table-cell-location--1rWKD";