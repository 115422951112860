import AwesomeDebouncePromise from 'awesome-debounce-promise'
import React from 'react'
import { connect } from 'react-redux'
import CloseButton from 'shared/components/Buttons/CloseButton'
import TextButton from 'shared/components/Buttons/TextButton'
import {
  filter,
  filterButton,
  filterButtonClose,
  filterHeader,
  filterIconFilter,
  filterInner,
  filterTitle,
  filterTitleText,
} from 'shared/components/CourseStatistics/Filter/Filter.module.scss'
import Search from 'shared/components/CourseStatistics/Filter/Search'
import IconFilter from 'shared/components/Icons/FilterIcon/FilterIcon'
import { IFilterSearch } from 'shared/proto/models'
import { CourseStatisticsFilterState } from 'shared/reducers/courseStatistics'
import { setFilterOpen } from 'shared/reducers/courseStatistics/courseStatisticFilterReducer'

import {
  getPharmacies,
  getPharmacists,
  setPharmacies,
  setPharmacists,
} from '../../../reducers/employeeCourseStatisticsReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'

const getPharmacistsDebounced = AwesomeDebouncePromise(getPharmacists, 500)
const getPharmaciesDebounced = AwesomeDebouncePromise(getPharmacies, 500)

const isDisableField = (pharmacy: IFilterSearch): boolean => {
  return !pharmacy || pharmacy?.name.trim() === ''
}

const Filter = (props: FilterProps) => {
  const { open } = props
  const [pharmacy, setPharmacy] = React.useState<IFilterSearch>(null)
  const [selectedPharmacits, setSelectedPharmacists] = React.useState<IFilterSearch[]>([])

  if (!open) {
    return null
  }

  return (
    <div className={filter}>
      <header className={filterHeader}>
        <h3 className={filterTitle}>
          <span className={filterIconFilter}>
            <IconFilter />
          </span>
          <span className={filterTitleText}>Фильтр графика</span>
        </h3>
        <div className={filterButtonClose}>
          <CloseButton onClick={() => props.setFilterOpen()} />
        </div>
      </header>
      <div className={filterInner}>
        <Search
          title="Филиал"
          fetchOptions={getPharmaciesDebounced}
          selected={pharmacy ? [pharmacy] : []}
          addSelected={(value) => {
            setPharmacy(value)
          }}
          removeSelected={() => {
            setPharmacy(null)
          }}
          clearSelected={() => {
            setPharmacy(null)
          }}
        />
        <Search
          title="Провизоры"
          selected={selectedPharmacits}
          fetchOptions={(input) => {
            return getPharmacistsDebounced({ pharmacyId: pharmacy?.id, query: input })
          }}
          addSelected={(value) => {
            if (selectedPharmacits.findIndex((item) => item.id === value.id) === -1) {
              setSelectedPharmacists(selectedPharmacits.concat(value))
            }
          }}
          removeSelected={(value) => {
            setSelectedPharmacists(selectedPharmacits.filter((item) => item.id !== value.id))
          }}
          clearSelected={() => {
            setSelectedPharmacists([])
          }}
          disabled={isDisableField(pharmacy)}
        />
      </div>
      <div className={filterButton}>
        <TextButton
          text="Отфильтровать"
          onClick={() => props.changeFilters(pharmacy ? [pharmacy] : null, selectedPharmacits)}
        />
      </div>
    </div>
  )
}

type FilterStateProps = {
  open: boolean
  pharmacies: IFilterSearch[]
  pharmacists: IFilterSearch[]
}

type FilterDispatchProps = {
  setFilterOpen: () => void
  changeFilters: (pharmacy: IFilterSearch[], pharmacist?: IFilterSearch[]) => void
}

type FilterProps = FilterStateProps & FilterDispatchProps

const mapStateToProps = (store: IAppState & CourseStatisticsFilterState): FilterStateProps => {
  return {
    open: store.courseStatisticFilters.filter.filterOpen,
    pharmacies: store.employeeCourseStatistics.pharmacies ?? [],
    pharmacists: store.employeeCourseStatistics.pharmacists ?? [],
  }
}

const mapDispatchToProps = (dispatch: AppDispatch): FilterDispatchProps => {
  return {
    setFilterOpen: () => {
      dispatch(setFilterOpen())
    },
    changeFilters: (pharmacies, pharmacist) => {
      dispatch(setPharmacies(pharmacies))
      dispatch(setPharmacists(pharmacist))
    },
  }
}

export default connect<FilterStateProps, FilterDispatchProps, unknown, IAppState & CourseStatisticsFilterState>(
  mapStateToProps,
  mapDispatchToProps
)(Filter)
