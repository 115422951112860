/* eslint-disable react/display-name */
import classNames from 'classnames'
import { Link, withPrefix } from 'gatsby'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInterface,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
} from 'react-table'
import AlertObjectRemove from 'shared/components/Modal/AlertObjectRemove'

import { GuidHelper } from '../../../../../external/rp.ui/helpers/GuidHelper'
import DeleteButton from '../../../../../shared/components/Buttons/DeleteButton'
import TextButton from '../../../../../shared/components/Buttons/TextButton'
import AddIcon from '../../../../../shared/components/Icons/AddIcon'
import PreviewIcon from '../../../../../shared/components/Icons/PreviewIcon'
import Table from '../../../../../shared/components/Table'
import {
  tableButtons,
  tableButtonsPreview,
  tableCell,
  tableText,
  tableTextContainer,
} from '../../../../../shared/components/Table/Table.module.scss'
import TablePaging from '../../../../../shared/components/TablePaging/TablePaging'
import { IBranchAddRequest, IPharmacyGroupBranch, IPharmacyGroupBranches } from '../../../../proto/models'
import { IAddGroupBranchesRequest, IGroupBranchesRequest } from '../../../../reducers/pharmacyReducer'
import AddBranchModal from './AddBranchModal'
import {
  section,
  sectionButtons,
  sectionContent,
  tableCellButtons,
  tableCellEmployeeCount,
  tableCellLocation,
  tableCellName,
} from './GroupBranches.module.scss'

interface IGroupBranchesTableProps {
  groupId: string
  addBranchPending: boolean
  getGroupBranches: (options: IGroupBranchesRequest) => Promise<IPharmacyGroupBranches>
  deleteGroupBranches: (id: string) => void
  getAddGroupBranches: (options: IAddGroupBranchesRequest) => Promise<IPharmacyGroupBranches>
  addGroupBranch: (options: IBranchAddRequest) => void
}

const GroupBranchesTable = (props: IGroupBranchesTableProps): ReactElement<IGroupBranchesTableProps> => {
  const [fetchedData, setFetchedData] = useState<IPharmacyGroupBranch[]>([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const pageSizeOptions = [25, 50, 75]
  const [localPageSize, setLocalPageSize] = useState(pageSizeOptions[0])
  const [currId, setCurrId] = useState(null)

  interface FetchDataProps {
    pageIndex: number
    pageSize: number
    sortBy: Array<SortingRule<IPharmacyGroupBranches>>
  }

  const onFetchData = async ({ pageIndex, pageSize, sortBy }: FetchDataProps) => {
    setLoading(true)
    const paging: IGroupBranchesRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortBy[0]?.id || 'name',
      sortDirection: sortBy[0]?.desc ?? true ? 'desc' : 'asc',
      groupId: props.groupId,
    }

    const response = await props.getGroupBranches(paging)
    setFetchedData(response?.items ?? [])
    setTotalRowCount(response?.rowCount ?? 0)
    setLoading(false)
  }

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  const getCellClass = (column: ColumnInterface<IPharmacyGroupBranches>) => {
    switch (column.id) {
      case 'name': {
        return classNames(tableCell, tableCellName)
      }
      case 'address': {
        return classNames(tableCell, tableCellLocation)
      }
      case 'employeeCount': {
        return classNames(tableCell, tableCellEmployeeCount)
      }
      case 'rowButtons': {
        return classNames(tableCell, tableCellButtons)
      }
    }
  }

  const removeModalHandler = (id?: string) => {
    setOpenRemoveModal(true)
    setCurrId(id)
  }

  const deleteButtonHandler = async () => {
    setLoading(true)
    await props.deleteGroupBranches(currId)
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    })
  }

  const closeModalHandler = () => {
    setOpen(false)
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    })
  }

  const columns: Column<IPharmacyGroupBranch>[] = React.useMemo(
    () => [
      {
        Header: 'Наименование',
        id: 'name',
        accessor: 'name',
        sortType: 'basic',
        Cell: (cell: Cell<IPharmacyGroupBranch>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
      },
      {
        Header: 'Адрес филиала',
        id: 'address',
        accessor: 'address',
        disableSortBy: true,
        Cell: (cell: Cell<IPharmacyGroupBranch>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText} title={cell.value}>
                {cell.value}
              </span>
            </p>
          )
        },
      },
      {
        Header: 'Провизоры',
        id: 'employeeCount',
        accessor: 'employeeCount',
        disableSortBy: true,
        Cell: (cell: Cell<IPharmacyGroupBranch>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
      },
      {
        id: 'rowButtons',
        Cell: (cell: Cell<IPharmacyGroupBranch>) => (
          <div className={tableButtons}>
            <Link
              className={tableButtonsPreview}
              to={withPrefix(
                `pharmacy-office/organization/group/${props.groupId}/branches/${GuidHelper.toString(
                  cell.row.original.id
                )}`
              )}
            >
              <PreviewIcon />
            </Link>
            <DeleteButton
              iconColor="blue"
              onClick={() => removeModalHandler(GuidHelper.toString(cell.row.original.id))}
            />
          </div>
        ),
      },
    ],
    []
  )

  const data = React.useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const tablePageCount = React.useMemo(
    () => Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0])),
    [totalRowCount, localPageSize]
  )

  const initialState: Partial<TableState<IPharmacyGroupBranch>> &
    Partial<UsePaginationState<IPharmacyGroupBranch>> &
    UseSortByState<IPharmacyGroupBranch> = {
    pageSize: localPageSize,
    sortBy: [{ id: 'name', desc: true }],
  }

  const tableOptions: TableOptions<IPharmacyGroupBranch> &
    UseSortByOptions<IPharmacyGroupBranch> &
    UsePaginationOptions<IPharmacyGroupBranch> = {
    data: data,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    manualSortBy: true,
    manualPagination: true,
    disableSortRemove: true,
  }

  const table = useTable<IPharmacyGroupBranch>(
    tableOptions,
    useSortBy,
    usePagination
  ) as TableInstance<IPharmacyGroupBranch> & UsePaginationInstanceProps<IPharmacyGroupBranch>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize, sortBy } = table.state as UsePaginationState<IPharmacyGroupBranch> &
    UseSortByState<IPharmacyGroupBranch>

  useEffect(() => {
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    })
  }, [pageIndex, pageSize, sortBy])

  useEffect(() => {
    return () => {
      setFetchedData([])
      setTotalRowCount(0)
      setOpen(false)
      setLoading(true)
      setOpenRemoveModal(false)
      setLocalPageSize(pageSizeOptions[0])
      setCurrId(null)
    }
  }, [])

  return (
    <>
      <AddBranchModal
        open={open}
        groupId={props.groupId}
        handleClose={closeModalHandler}
        addBranchPending={props.addBranchPending}
        getAddGroupBranches={props.getAddGroupBranches}
        addGroupBranch={(options: IBranchAddRequest) => {
          setLoading(true)
          props.addGroupBranch(options)
        }}
      />
      <AlertObjectRemove
        isOpen={openRemoveModal}
        title="Вы уверены, что хотите удалить аптеку из группы?"
        agreeString="Удалить"
        disagreeString="Отменить"
        agreeCallback={deleteButtonHandler}
        handleClose={() => setOpenRemoveModal(false)}
      />
      <div className={section}>
        <div className={sectionContent}>
          <div className={sectionButtons}>
            <TextButton
              iconLeft={<AddIcon color="white" />}
              text="Добавить филиал"
              onClick={() => {
                setOpen(true)
              }}
            />
          </div>
          <Table<IPharmacyGroupBranch> tableInstance={table} loading={loading} getCellClass={getCellClass} />
          <TablePaging
            gotoPage={gotoPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSizeOptions={pageSizeOptions}
            setLocalPageSize={setLocalPageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </>
  )
}

export default GroupBranchesTable
