import DifficultyFilter, {
  DifficultyFilterDispatchProps,
  DifficultyFilterStateProps,
} from 'shared/components/EmployeeStatistics/Filter/DifficultyFilter'
import { connect } from 'react-redux'

import { changeDifficulty } from '../../../../../reducers/employeeStatisticsReducer'
import { AppDispatch, IAppState } from '../../../../../reducers/rootReducer'

const mapStateToProps = (store: IAppState): DifficultyFilterStateProps => {
  return {
    difficulty: store.employeeStatistics.difficulty,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch): DifficultyFilterDispatchProps => {
  return {
    changeDifficulty: (difficulty) => {
      dispatch(changeDifficulty(difficulty))
    },
  }
}

export default connect<DifficultyFilterStateProps, DifficultyFilterDispatchProps, unknown, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(DifficultyFilter)
