/* eslint-disable react/display-name */
/* eslint-disable react/jsx-key */
import classNames from 'classnames'
import React, { MutableRefObject, ReactElement, useEffect, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInterface,
  Row,
  TableInstance,
  TableOptions,
  TableToggleRowsSelectedProps,
  useAsyncDebounce,
  useRowSelect,
  UseRowSelectInstanceProps,
  UseRowSelectRowProps,
  UseRowSelectState,
  useTable,
} from 'react-table'
import AddIcon from 'shared/components/Icons/AddIcon'
import Table from 'shared/components/Table'
import {
  tableCell,
  tableRow,
  tableRowChecked,
  tableText,
  tableTextContainer,
} from 'shared/components/Table/Table.module.scss'

import { GuidHelper } from '../../../../../external/rp.ui/helpers/GuidHelper'
import throttleFetch from '../../../../../external/rp.ui/utils/throttleFetch'
import TextButton from '../../../../../shared/components/Buttons/TextButton'
import CustomCheckbox from '../../../../../shared/components/CustomCheckbox'
import SearchInput from '../../../../../shared/components/SearchInput'
import { IBranchAddRequest, IPharmacyGroupBranch, IPharmacyGroupBranches } from '../../../../proto/models'
import { IAddGroupBranchesRequest } from '../../../../reducers/pharmacyReducer'
import {
  modalBlockButtons,
  modalBlockSearch,
  modalButton,
  modalTable,
  modalTableCellAddress,
  modalTableCellCheckbox,
  modalTableCellName,
} from './GroupBranches.module.scss'

interface IAddBranchTableProps {
  groupId: string
  addBranchPending: boolean
  actionCallback: (options: IBranchAddRequest) => void
  closeModalHandler: () => void
  getAddGroupBranches: (options: IAddGroupBranchesRequest) => Promise<IPharmacyGroupBranches>
}

const onUserAdd = throttleFetch((props: IAddBranchTableProps, selectedFlatRows: Row<IPharmacyGroupBranch>[]) => {
  const options: IBranchAddRequest = {
    pharmacyIds: selectedFlatRows.map((d) => d.original.id),
    groupId: GuidHelper.parse(props.groupId),
  }
  props.actionCallback(options)
})

const AddBranchTable = (props: IAddBranchTableProps): ReactElement<IAddBranchTableProps> => {
  const [fetchedData, setFetchedData] = useState<IPharmacyGroupBranch[]>([])
  const [search, setSearch] = useState('')
  const [loading, setLoading] = useState(true)

  const onFetchData = async ({ search }: { search: string }) => {
    const parameters: IAddGroupBranchesRequest = {
      searchQuery: search,
      groupId: props.groupId,
    }
    const list = await props.getAddGroupBranches(parameters)
    setFetchedData(list?.items ?? [])
    setLoading(false)
  }

  const searchHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const getRowClass = (row: Row<IPharmacyGroupBranch>) => {
    const selectableRow = row as Row<IPharmacyGroupBranch> & UseRowSelectRowProps<IPharmacyGroupBranch>
    return classNames(tableRow, { [tableRowChecked]: selectableRow.isSelected })
  }

  const getCellClass = (column: ColumnInterface<IPharmacyGroupBranch>) => {
    switch (column.id) {
      case 'selection': {
        return classNames(tableCell, modalTableCellCheckbox)
      }
      case 'Name': {
        return classNames(tableCell, modalTableCellName)
      }
      case 'address': {
        return classNames(tableCell, modalTableCellAddress)
      }
    }
  }

  const data = React.useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const IndeterminateCheckbox = React.forwardRef<HTMLInputElement, TableToggleRowsSelectedProps & { id: string }>(
    ({ indeterminate, id, ...rest }, ref) => {
      const defaultRef = React.useRef<HTMLInputElement>()
      const resolvedRef = (ref as MutableRefObject<HTMLInputElement>) || defaultRef

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return <CustomCheckbox id={id} ref={resolvedRef} {...rest} />
    }
  )

  const columns: Column<IPharmacyGroupBranch>[] = React.useMemo(
    () => [
      {
        id: 'selection',
        accessor: 'address',
        Header: ({ getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<IPharmacyGroupBranch>) => (
          <IndeterminateCheckbox id={`globalSelect`} {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }: { row: Row<IPharmacyGroupBranch> & UseRowSelectRowProps<IPharmacyGroupBranch> }) => (
          <IndeterminateCheckbox id={`item-${row.id}`} {...row.getToggleRowSelectedProps()} />
        ),
      },
      {
        id: 'Name',
        accessor: 'name',
        Header: 'Наименование',
        Cell: (cell: Cell<IPharmacyGroupBranch>) => (
          <p className={tableTextContainer}>
            <span className={tableText}>{cell.value}</span>
          </p>
        ),
      },
      {
        id: 'address',
        accessor: 'address',
        Header: 'Адрес филиала',
        Cell: (cell: Cell<IPharmacyGroupBranch>) => (
          <p className={tableTextContainer}>
            <span className={tableText} title={cell.value}>
              {cell.value}
            </span>
          </p>
        ),
      },
    ],
    []
  )

  const tableOptions: TableOptions<IPharmacyGroupBranch> = {
    data: data,
    columns: columns,
  }

  const table = useTable<IPharmacyGroupBranch>(tableOptions, useRowSelect) as TableInstance<IPharmacyGroupBranch> &
    UseRowSelectInstanceProps<IPharmacyGroupBranch> & { state: UseRowSelectState<IPharmacyGroupBranch> }
  const { selectedFlatRows } = table

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  useEffect(() => {
    setLoading(true)
    onFetchDataDebounced({
      search,
    })
  }, [search])

  useEffect(() => {
    return () => {
      setFetchedData([])
      setSearch('')
      setLoading(true)
    }
  }, [])

  return (
    <>
      <div className={modalBlockSearch}>
        <SearchInput placeholder="Введите запрос для поиска" onChange={searchHandler} fullWidth={true} />
      </div>
      <div className={modalTable}>
        <Table<IPharmacyGroupBranch>
          tableInstance={table}
          getCellClass={getCellClass}
          getRowClass={getRowClass}
          addHover={true}
          rowWrap="nowrap"
          loading={loading}
        />
      </div>

      <div className={modalBlockButtons}>
        <div className={modalButton}>
          <TextButton
            text="Добавить"
            iconLeft={<AddIcon color="white" />}
            type="submit"
            onClick={() => {
              onUserAdd(props, selectedFlatRows)
            }}
            disabled={props.addBranchPending}
          />
        </div>
        <div className={modalButton}>
          <TextButton
            variant="outlined"
            text="Отменить"
            onClick={props.closeModalHandler}
            disabled={props.addBranchPending}
          />
        </div>
      </div>
    </>
  )
}

export default AddBranchTable
