import { connect } from 'react-redux'

import { IAppState } from '../../../../../reducers/rootReducer'
import TotalsChart, { TotalsChartStateProps } from 'pharmacy-office/components/EmployeeStatistics/Charts/TotalsChart'

const mapStateToProps = (store: IAppState): TotalsChartStateProps => {
  return {
    assignCoursesFilters: store.employeeStatistics.assignCoursesFilters,
    totalFilters: store.employeeStatistics.totalFilters,
    averageResultFilter: store.employeeStatistics.averageResultFilter,
  }
}

export default connect<TotalsChartStateProps, unknown, unknown, IAppState>(mapStateToProps)(TotalsChart)
