import { createAction, createReducer, Dispatch } from '@reduxjs/toolkit'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Employee, IEmployee } from 'manufacturer-office/proto/models'
import { IPharmacyEmployee, IPharmacyEmployeeList, PharmacyEmployeeList } from 'pharmacy-office/proto/models'

const url = 'statistics'

interface IEmployeeState {
  info: IEmployee
  loadingInfo: boolean
  id: string
}

interface IEmployeesState {
  loadingEmployees: boolean
  listEmployees: IPharmacyEmployee[]
  employee: IEmployeeState
}

export interface IPharmacyEmployeeState {
  employees: IEmployeesState
}

const initialState: IPharmacyEmployeeState = {
  employees: {
    loadingEmployees: true,
    listEmployees: [],
    employee: {
      info: null,
      loadingInfo: true,
      id: null,
    },
  },
}

export interface IProvisrosRequest {
  pageIndex: number
  pageSize: number
  sortColumn: string
  sortDirection: string
  organizationId: string
  searchQuery: string
}

const LOAD_EMPLOYEES = 'LOAD_EMPLOYEES'
export const setLoadEmployees = createAction<boolean>(LOAD_EMPLOYEES)

export interface IEmployeesRequest {
  pageIndex: number
  pageSize: number
  sortColumn: string
  sortDirection: string
  organizationId: string
  searchQuery: string
  year: number
  month: number
  day: number
}

const GET_EMPLOYEES = 'GET_EMPLOYEES'
export const getEmployees = (options: IEmployeesRequest) => async (
  dispatch: Dispatch
): Promise<IPharmacyEmployeeList> => {
  const response = await ProtoClient.get<IPharmacyEmployeeList>(url + '/pharmacist', PharmacyEmployeeList, options)

  dispatch({ type: GET_EMPLOYEES, payload: response.items })
  dispatch({ type: LOAD_EMPLOYEES, payload: false })

  return response
}

const LOAD_EMPLOYEE = 'LOAD_EMPLOYEE'
const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const getEmployee = (employeeId: string) => async (dispatch: Dispatch): Promise<void> => {
  const response = await ProtoClient.get<IEmployee>(url + '/employee/get-employee', Employee, { id: employeeId })

  dispatch({ type: GET_EMPLOYEE, payload: response })
  dispatch({ type: LOAD_EMPLOYEE, payload: false })
}

const RESET_EMPLOYEE = 'RESET_EMPLOYEE'
export const resetEmployee = createAction(RESET_EMPLOYEE)

const pharmacistEmployeesReducer = createReducer(initialState, {
  [GET_EMPLOYEES]: (state, action) => {
    state.employees.listEmployees = action.payload
  },
  [LOAD_EMPLOYEES]: (state, action) => {
    state.employees.loadingEmployees = action.payload
  },
  [LOAD_EMPLOYEE]: (state, action) => {
    state.employees.employee.loadingInfo = action.payload
  },
  [GET_EMPLOYEE]: (state, action) => {
    state.employees.employee.info = action.payload
    state.employees.employee.id = GuidHelper.toString(action.payload.id)
  },
  [RESET_EMPLOYEE]: (state) => {
    state.employees.employee = initialState.employees.employee
  },
})

export default pharmacistEmployeesReducer
