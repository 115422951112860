import { withPrefix } from 'gatsby'
import { IAppState } from 'pharmacy-office/reducers/rootReducer'
import React from 'react'
import { connect } from 'react-redux'
import SafeLink from 'shared/components/SafeLink'
import Header from 'shared/components/Sidebar/Header'
import Navigation, { IDataNavigation } from 'shared/components/Sidebar/Navigation'
import { AppDispatch } from 'shared/reducers/CourseDesigner'
import { fetchPermissions } from 'shared/reducers/CourseDesigner/permissionsReducer'
import { fetchMenuItems, getCountApplications } from 'shared/reducers/menuItems/menuItemsReducer'

import UserInfo from '../UserInfo/UserInfo'
import { sidebar } from './Sidebar.module.scss'

interface ISidebarStateProps {
  loading: boolean
  menuItems: string[]
  countApplications: number
}

interface ISidebarDispatchProps {
  fetchMenuItems: () => void
  getCountApplications: () => void
  fetchPermissions: () => void
}

type SidebarProps = ISidebarDispatchProps & ISidebarStateProps

class Sidebar extends React.Component<SidebarProps> {
  componentDidMount() {
    this.props.fetchMenuItems()
    this.props.getCountApplications()
    this.props.fetchPermissions()
  }

  dataNavigation = (): (IDataNavigation<unknown> & { key: string })[] => [
    {
      id: 1,
      name: 'Аптечные сети',
      link: withPrefix('pharmacy-office/organization'),
      class: 'pharmacy',
      key: 'pharmacy-office',
    },
    {
      id: 2,
      name: 'Конструктор курсов',
      link: withPrefix('pharmacy-office/course-designer'),
      class: 'course-designer',
      key: 'course-designer',
    },
    {
      id: 3,
      name: 'Статистика',
      link: withPrefix('pharmacy-office/statistics'),
      class: 'statistics',
      key: 'statistics',
    },
    {
      id: 4,
      name: 'Заявки',
      link: withPrefix('pharmacy-office/applications'),
      class: 'application',
      key: 'application',
      count: this.props.countApplications !== 0 ? this.props.countApplications : null,
    },
    {
      id: 5,
      name: 'Личный профиль',
      link: withPrefix('pharmacy-office/profile'),
      class: 'profile',
      key: 'profile',
    },
  ]

  private getMenuItems = () => {
    const menuItems = this.props.menuItems

    if (menuItems.length !== 0) {
      return this.dataNavigation().filter((dn) => menuItems.includes(dn.key))
    }

    return []
  }

  render() {
    return (
      <aside className={sidebar}>
        <Header />
        <UserInfo />
        <Navigation dataNavigation={this.getMenuItems()} linkElement={SafeLink} />
      </aside>
    )
  }
}

const mapStateToProps = (store: IAppState): ISidebarStateProps => {
  return {
    loading: store.menuItems.loading,
    menuItems: store.menuItems.menuItems,
    countApplications: store.menuItems.countApplications,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch): ISidebarDispatchProps => {
  return {
    fetchMenuItems: () => {
      dispatch(fetchMenuItems('pharmacy-office/menu/get-menu-items'))
    },
    getCountApplications: () => {
      dispatch(getCountApplications('pharmacy-office/menu/get-count-applications'))
    },
    fetchPermissions: () => {
      dispatch(fetchPermissions('pharmacy-office'))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
