import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import React from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Explanations from 'shared/components/Explanations'
import { explanationsParagraph } from 'shared/components/Explanations/Explanations.module.scss'
import CubeQueryRender, { IChartComponentProps, renderChart } from 'shared/components/QueryRender/CubeQueryRender'
import CustomBarCursor from 'shared/components/Recharts/CustomBarCursor'
import CustomTooltip from 'shared/components/Recharts/CustomTooltip'
import { IUserListResponse } from 'shared/proto/models'

import { ChartPivotRow, Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import { blockChart, blockChartGraph, blockChartTitle, chart } from './BlockCharts.module.scss'
import { getChartWidth } from './utils'

const round = (value: number, total: number) => Math.round(((value * 100) / total + Number.EPSILON) * 100) / 100

const barSize = 80
const barSizeBigData = 10
const countBarStyle = 5

const successfulAttemptsKey = 'successful'
const totalPublicationKey = 'failed'

const successfulCount = 'SuccessfullCourseAttempt.count'
const successfulUserId = 'SuccessfullCourseAttempt.userId'

const totalPublicationCount = 'PharmacistCourse.count'
const totalPublicationUserId = 'PharmacistCourse.userId'

const barColor = '#7955A9'
const tooltipColor = '#201F25'

const labelFormatter = (label: React.ReactText) => {
  return label != 0 ? `${label}%` : ''
}

const applyLabelToBars = (
  user: IUserListResponse,
  successfullPivots: ChartPivotRow[],
  totalPivots: ChartPivotRow[]
) => {
  const userId = GuidHelper.toString(user.userId).toLowerCase()
  const filteredSuccessfullPivots = successfullPivots.filter((p) => p.xValues[0] === userId)
  const filteredTotalPivots = totalPivots.filter((p) => p.xValues[0] === userId)
  const total = filteredTotalPivots.reduce((prev, curr) => prev + curr[totalPublicationCount], 0)

  const preparedData = {
    [successfulAttemptsKey]: round(
      filteredSuccessfullPivots.find((v) => v.x === `${userId}`)?.[successfulCount] ?? 0,
      total
    ),
    ['name']: user.fullName,
  }
  return preparedData
}

const getData = (set: { [key: string]: ResultSet }, users: IUserListResponse[]) => {
  const successfulPivots = set[successfulAttemptsKey].chartPivot({
    x: [successfulUserId],
    y: [successfulCount],
  })
  const totalPivots = set[totalPublicationKey].chartPivot({
    x: [totalPublicationUserId],
    y: [totalPublicationCount],
  })
  const userIds = [...new Set(successfulPivots.map((r) => r.xValues[0]).concat(totalPivots.map((r) => r.xValues[0])))]

  const filteredUsers = users.filter((u) => userIds.includes(GuidHelper.toString(u.userId).toLowerCase()))

  const perparedData = filteredUsers.map((i) => applyLabelToBars(i, successfulPivots, totalPivots))

  return perparedData
}

const barRender = (props: IBarRenderProps) => {
  const { resultSet, users } = props
  const data = getData(resultSet as { [key: string]: ResultSet }, users)
  const memoData = React.useMemo(() => data, [data])
  const barData = [successfulAttemptsKey]

  const width = getChartWidth(data)

  return (
    memoData && (
      <ResponsiveContainer width={width} height="100%">
        <BarChart data={memoData} margin={{ top: 20 }}>
          <XAxis hide={true} dataKey="name" />
          <YAxis
            key="YAxis"
            axisLine={false}
            tickLine={false}
            tickFormatter={labelFormatter}
            domain={[0, 100]}
            ticks={[0, 25, 50, 75, 100]}
            width={40}
          />
          <CartesianGrid strokeDasharray="5 5" horizontal={true} vertical={false} stroke="#ACB2BD" />
          {barData.map((item, index) => {
            return (
              <Bar
                key={`bar-${index}`}
                dataKey={item}
                stackId="a"
                fill={barColor}
                minPointSize={3}
                barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
              ></Bar>
            )
          })}
          <Tooltip cursor={<CustomBarCursor customWidth={2} color={tooltipColor} />} content={<CustomTooltip />} />
        </BarChart>
      </ResponsiveContainer>
    )
  )
}
interface IBarRenderStateProps {
  users: IUserListResponse[]
}

type IBarRenderProps = IBarRenderStateProps & IChartComponentProps

const mapStateToBarChartProps = (store: IAppState): IBarRenderStateProps => {
  return {
    users: store.pharmacyGroupStatistics.users,
  }
}
const connectedBarRender = connect<IBarRenderStateProps, {}, IChartComponentProps, IAppState>(mapStateToBarChartProps)(
  barRender
)

const chartRender = (props: QueryRendererRenderProps) => (
  <div className={chart}>{renderChart(connectedBarRender)(props)}</div>
)

const VisibleExplanationsContent = () => {
  return (
    <p className={explanationsParagraph}>
      На&nbsp;графике отражены данные по&nbsp;всем курсам, которые были изучены вашими провизорами и&nbsp;провизорами,
      у&nbsp;которых есть заявка на&nbsp;добавление в&nbsp;штат вашей аптечной сети.
    </p>
  )
}

const ExplanationsContent = () => {
  return (
    <>
      <VisibleExplanationsContent />
      <p className={explanationsParagraph}>
        График позволяет увидеть какой %&nbsp;курсов конкретный провизор сдал успешно от&nbsp;общего числа назначенных
        на&nbsp;него курсов.
      </p>
    </>
  )
}

const PassedTestByPharmacistRatioChart = (props: PassedTestByPharmacistRatioChartProps) => {
  return (
    <div className={blockChart}>
      <h2 className={blockChartTitle}>% сданных публикаций</h2>
      <div className={blockChartGraph}>
        <CubeQueryRender
          queries={{
            [successfulAttemptsKey]: {
              measures: [successfulCount],
              dimensions: [successfulUserId],
              filters: props.passedPublicationWithEndDateFilter,
            },
            [totalPublicationKey]: {
              measures: [totalPublicationCount],
              dimensions: [totalPublicationUserId],
              filters: props.totalPublicationFilter,
            },
          }}
          render={chartRender}
        />
      </div>

      <Explanations content={<ExplanationsContent />} visibleContent={<VisibleExplanationsContent />} />
    </div>
  )
}

type PassedTestByPharmacistRatioChartStateProps = {
  passedPublicationWithEndDateFilter: Filter[]
  totalPublicationFilter: Filter[]
  dispatch: AppDispatch
}

type PassedTestByPharmacistRatioChartProps = PassedTestByPharmacistRatioChartStateProps

const mapStateToProps = (store: IAppState): PassedTestByPharmacistRatioChartStateProps => {
  return {
    passedPublicationWithEndDateFilter: store.pharmacyGroupStatistics.passedPublicationWithEndDateFilter,
    totalPublicationFilter: store.pharmacyGroupStatistics.totalPublicationFilter,
    dispatch: store.dispatch,
  }
}

export default connect<PassedTestByPharmacistRatioChartStateProps, unknown, unknown, IAppState>(mapStateToProps)(
  PassedTestByPharmacistRatioChart
)
