import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, InjectedFormProps, reduxForm, SubmissionError, Validator } from 'redux-form'

import { Dialog } from '@material-ui/core'

import { GuidHelper } from '../../../../external/rp.ui/helpers/GuidHelper'
import CloseButton from '../../../../shared/components/Buttons/CloseButton'
import TextButton from '../../../../shared/components/Buttons/TextButton'
import CustomInput from '../../../../shared/components/CustomInput'
import AddIcon from '../../../../shared/components/Icons/AddIcon'
import SaveIcon from '../../../../shared/components/Icons/SaveIcon'
import { IPharmaciesGroup, IPharmaciesGroupResponse } from '../../../proto/models'
import {
  modal,
  modalBlockButtons,
  modalBlockGroup,
  modalButton,
  modalClose,
  modalHeader,
  modalTitle,
} from './PharmaciesGroup.module.scss'

interface IAddPharmaciesGroupModalProps {
  organizationId: string
  open: boolean
  edit: boolean
  handleClose: () => void
  editGroup: (values: IPharmaciesGroup) => Promise<IPharmaciesGroupResponse>
  createGroup: (values: IPharmaciesGroup) => Promise<IPharmaciesGroupResponse>
}

const required: Validator = (value) => {
  return !value || value.toString().trim() === '' ? 'Обязательное' : undefined
}

const maxLenght = (value: string) => value.substring(0, 255)

class AddPharmaciesGroupModal extends Component<
  IAddPharmaciesGroupModalProps & InjectedFormProps<IPharmaciesGroup, IAddPharmaciesGroupModalProps>
> {
  render() {
    return (
      <Dialog maxWidth={false} open={this.props.open} onClose={() => this.props.handleClose()}>
        <form onSubmit={this.props.handleSubmit}>
          <div className={modal}>
            <header className={modalHeader}>
              {this.props.edit ? (
                <h2 className={modalTitle}>Редактирование группы</h2>
              ) : (
                <h2 className={modalTitle}>Создание группы</h2>
              )}
              <div className={modalClose}>
                <CloseButton onClick={() => this.props.handleClose()} color="grey" isHover={true} />
              </div>
            </header>
            <div className={modalBlockGroup}>
              <Field
                placeholder={'Введите нименование группы'}
                name={'name'}
                component={CustomInput}
                validate={required}
                normalize={maxLenght}
                isReserveLabelError={true}
                size="large"
              />
            </div>
            <div className={modalBlockButtons}>
              <div className={modalButton}>
                <TextButton
                  iconLeft={this.props.edit ? <SaveIcon /> : <AddIcon color="white" />}
                  text={this.props.edit ? 'Сохранить' : 'Создать'}
                  type="submit"
                  disabled={this.props.submitting}
                />
              </div>
              <div className={modalButton}>
                <TextButton
                  variant="outlined"
                  text="Отменить"
                  onClick={() => {
                    this.props.destroy()
                    this.props.handleClose()
                  }}
                  disabled={this.props.submitting}
                />
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    )
  }
}

const addPharmaciesGroupEditForm = reduxForm<IPharmaciesGroup, IAddPharmaciesGroupModalProps>({
  form: 'addPharmaciesGroupEditForm',
  onSubmit: async (values, dispatch, props) => {
    let response: IPharmaciesGroupResponse = null

    if (values.id) {
      response = await props.editGroup(values)
    } else {
      values.pharmacyOrganizationId = GuidHelper.parse(props.organizationId)
      response = await props.createGroup(values)
    }

    if (!response.success) {
      throw new SubmissionError({
        name: 'Запись с таким наименованием уже существует.',
      })
    }

    props.destroy()
    props.handleClose()
  },
})(AddPharmaciesGroupModal)

export default connect(null, null)(addPharmaciesGroupEditForm)
