import React from 'react'
import { connect } from 'react-redux'

import { GuidHelper } from '../../../../external/rp.ui/helpers/GuidHelper'
import {
  IPharmaciesGroup,
  IPharmaciesGroupList,
  IPharmaciesGroupResponse,
  IUserPharmacyOrganization,
} from '../../../proto/models'
import { createGroup, deleteGroup, editGroup, getGroup, IPharmacyGroupRequest } from '../../../reducers/pharmacyReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import PharmaciesGroupTable from './PharmaciesGroupTable'

interface IPharmaciesGroupOwnProps {
  path: string
}

interface IPharmaciesGroupStateProps {
  dispatch: AppDispatch
  organization: IUserPharmacyOrganization
}

type PharmaciesGroupProps = IPharmaciesGroupOwnProps & IPharmaciesGroupStateProps

const PharmaciesGroup = (props: PharmaciesGroupProps): React.ReactElement<PharmaciesGroupProps> => {
  const getGroupHandler = (options: IPharmacyGroupRequest): Promise<IPharmaciesGroupList> => {
    return props.dispatch(getGroup(options))
  }

  const deleteGroupHandler = (id: string) => {
    props.dispatch(deleteGroup(id))
  }

  const editGroupHandler = (values: IPharmaciesGroup): Promise<IPharmaciesGroupResponse> => {
    return props.dispatch(editGroup(values))
  }

  const createGroupHandler = (values: IPharmaciesGroup): Promise<IPharmaciesGroupResponse> => {
    return props.dispatch(createGroup(values))
  }

  return (
    <PharmaciesGroupTable
      organizationId={GuidHelper.toString(props.organization.id)}
      getGroup={getGroupHandler}
      deleteGroup={deleteGroupHandler}
      dispatch={props.dispatch}
      editGroup={editGroupHandler}
      createGroup={createGroupHandler}
    />
  )
}

const mapStateToProps = (store: IAppState): IPharmaciesGroupStateProps => {
  return {
    dispatch: store.dispatch,
    organization: store.pharmacy.organization,
  }
}

export default connect<IPharmaciesGroupStateProps, never, IPharmaciesGroupOwnProps, IAppState>(mapStateToProps)(
  PharmaciesGroup
)
