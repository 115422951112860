import React, { ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'

import AccountOperationTable from '../../../../shared/components/Offices/Accounts/AccountOperationTable'
import { IAccountBalance } from '../../../../shared/proto/models'
import { getAccountBalance } from '../../../reducers/pharmacyReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'

const controllerUrl = 'pharmacy-office/pharmacy-account'

interface IPharmacyAccountOwnProps {
  path: string
}

interface IPharmacyAccountStateProps {
  dispatch: AppDispatch
  balance: IAccountBalance
  loadBalance: boolean
}

type PharmacyAccountProps = IPharmacyAccountOwnProps & IPharmacyAccountStateProps

const PharmacyAccount = (props: PharmacyAccountProps): ReactElement<PharmacyAccountProps> => {
  useEffect(() => {
    props.dispatch(getAccountBalance())
  }, [])

  return (
    <AccountOperationTable
      dispatch={props.dispatch}
      loadBalance={props.loadBalance}
      balance={props.balance}
      controllerUrl={controllerUrl}
    />
  )
}

const mapStateToProps = (store: IAppState): IPharmacyAccountStateProps => {
  return {
    dispatch: store.dispatch,
    balance: store.pharmacy.account.balance,
    loadBalance: store.pharmacy.account.loadBalance,
  }
}

export default connect<IPharmacyAccountStateProps, unknown, IPharmacyAccountOwnProps, IAppState>(mapStateToProps)(
  PharmacyAccount
)
