// extracted by mini-css-extract-plugin
export var section = "EmployeeCourseTable-module--section--2EYo8";
export var sectionContent = "EmployeeCourseTable-module--section-content--jSivt";
export var sectionContentHeader = "EmployeeCourseTable-module--section-content-header--3h8Et";
export var sectionButtons = "EmployeeCourseTable-module--section-buttons--2JcSl";
export var sectionSearch = "EmployeeCourseTable-module--section-search--1oJZP";
export var tableCellName = "EmployeeCourseTable-module--table-cell-name--1U9dQ";
export var tableCellManufacturer = "EmployeeCourseTable-module--table-cell-manufacturer---US8_";
export var tableCellCategory = "EmployeeCourseTable-module--table-cell-category--1puDq";
export var tableCellStatus = "EmployeeCourseTable-module--table-cell-status--2zqw5";
export var tableCellEdit = "EmployeeCourseTable-module--table-cell-edit--2cZwO";