// extracted by mini-css-extract-plugin
export var section = "PharmacyEmployees-module--section--2zyz4";
export var sectionContent = "PharmacyEmployees-module--section-content--o1znh";
export var informationBlockIcon = "PharmacyEmployees-module--informationBlock-icon--3S3E_";
export var customTable = "PharmacyEmployees-module--customTable--17vbg";
export var sectionHeader = "PharmacyEmployees-module--section-header--1_Tfp";
export var sectionSearch = "PharmacyEmployees-module--section-search--3cI0P";
export var sectionFilter = "PharmacyEmployees-module--section-filter--2f89n";
export var sectionExport = "PharmacyEmployees-module--section-export--mNe8P";
export var informationBlock = "PharmacyEmployees-module--informationBlock--2nawt";
export var informationBlockText = "PharmacyEmployees-module--informationBlock-text--1OB19";
export var tableSection = "PharmacyEmployees-module--tableSection--3dy-U";
export var tableCellName = "PharmacyEmployees-module--table-cell-name--1reWP";
export var tableCellPhoneNumber = "PharmacyEmployees-module--table-cell-phoneNumber--rE8W4";
export var tableCellEmail = "PharmacyEmployees-module--table-cell-email--3cbP9";
export var tableCellGroupName = "PharmacyEmployees-module--table-cell-groupName--21zQw";
export var tableCellAddDate = "PharmacyEmployees-module--table-cell-addDate--1b_kr";
export var tableCellNumber = "PharmacyEmployees-module--table-cell-number--1crwI";
export var tableCellButtons = "PharmacyEmployees-module--table-cell-buttons--OotcN";