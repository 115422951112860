import { DateTime, Duration } from 'luxon'
import {
  AutocompleteSearchResults,
  ETeqServices,
  IAutocompleteSearchResults,
  IUserListResponse,
  IUserListResponses,
  UserListResponses,
} from 'shared/proto/models'

import { Filter } from '@cubejs-client/core'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { EPublicationStatus, EStudentPublicationStatus, IFilterSearch } from 'shared/proto/models'

import { AppDispatch, IAppState } from './rootReducer'
import { IOptions } from 'shared/components/CustomListSelect/CustomListSelect'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'

const startDate = DateTime.local().minus({ days: 30 })
const endDate = DateTime.local()

export interface IPharmacyOrganizationStatisticFiltersState {
  startDate: DateTime
  endDate: DateTime
  filterOpen: boolean
  businessCategories: IFilterSearch[]
  medicalCategories: IFilterSearch[]
  drugNames: IFilterSearch[]
  successfullAttmptsFilters: Filter[]
  failedAttemptsFilters: Filter[]
  timedOutAttemptsFilters: Filter[]
  successfullAttmptsByCourseFilters: Filter[]
  failedAttemptsByCourseFilters: Filter[]
  timedOutAttemptsByCourseFilters: Filter[]
  publicationByOwnPharmacyOrganizationBranch: Filter[]
  publicationByAnotherPharmacyOrganizationBranch: Filter[]
  publicationByManufacturerBranch: Filter[]
  averageGradeFilters: Filter[]
  averageLearingTimeFilters: Filter[]
  failedPublicationsFilter: Filter[]
  timedOutPublicationsFilter: Filter[]
  passedPublicationsFilter: Filter[]
  publicationInProgressFilters: Filter[]
  newPublicationFilter: Filter[]
  totalPublicationFilter: Filter[]
  passedPublicationWithEndDateFilter: Filter[]
  globalFilterOpen: boolean
  users: IUserListResponse[]
  loading: boolean
  categoriesSelected: boolean
  branches: IOptions[]
  branchId?: string
}

export const firstTryKey = '1'
export const secondTryKey = '2'
export const thirdTryKey = '3'
export const failedKey = '0'

export interface IChartGridData {
  [firstTryKey]: number
  [secondTryKey]: number
  [thirdTryKey]: number
  [failedKey]: number
  ['name']: string
}

const initialState: IPharmacyOrganizationStatisticFiltersState = {
  startDate: startDate,
  endDate: endDate,
  filterOpen: false,
  businessCategories: [],
  medicalCategories: [],
  drugNames: [],
  successfullAttmptsFilters: [],
  failedAttemptsFilters: [],
  timedOutAttemptsFilters: [],
  successfullAttmptsByCourseFilters: [],
  failedAttemptsByCourseFilters: [],
  timedOutAttemptsByCourseFilters: [],
  averageGradeFilters: [],
  averageLearingTimeFilters: [],
  failedPublicationsFilter: [],
  timedOutPublicationsFilter: [],
  passedPublicationsFilter: [],
  publicationInProgressFilters: [],
  newPublicationFilter: [],
  totalPublicationFilter: [],
  globalFilterOpen: false,
  users: [],
  loading: true,
  publicationByOwnPharmacyOrganizationBranch: [],
  publicationByAnotherPharmacyOrganizationBranch: [],
  publicationByManufacturerBranch: [],
  categoriesSelected: false,
  passedPublicationWithEndDateFilter: [],
  branches: [],
  branchId: null,
}

export const startDateToString = (startDate: DateTime): string =>
  startDate.startOf('day').toISO({ includeOffset: false, suppressMilliseconds: true })

export const endDateToString = (endDate: DateTime): string =>
  endDate.endOf('day').toISO({ includeOffset: false, suppressMilliseconds: true })

export const startDateWithInclusionToString = (startDate: DateTime): string =>
  startDate.startOf('day').minus({ seconds: 1 }).toISO({ includeOffset: false, suppressMilliseconds: true })

export const endDateWithInclusionToString = (startDate: DateTime): string =>
  startDate.endOf('day').plus({ seconds: 1 }).toISO({ includeOffset: false, suppressMilliseconds: true })

export const getDateTimeRange = (startDate: DateTime, endDate: DateTime): string[] => [
  startDateToString(startDate),
  endDateToString(endDate),
]

const url = 'pharmacy-office/pharmacy-org-statistics'

const SET_BRANCH_ID = 'PHARM_ORG_STATISTICS_SET_BRANCH_ID'
export const setBranchId = createAction<string>(SET_BRANCH_ID)

const SET_BRANCHES_FOR_FILTER = 'PHARM_ORG_STATISTICS_SET_BRANCHES_FOR_FILTER'
const setBranchesForFilter = createAction<IOptions[]>(SET_BRANCHES_FOR_FILTER)

export const getBranches =
  () =>
    async (dispatch: AppDispatch): Promise<void> => {
      const branches = await ProtoClient.get<IAutocompleteSearchResults>(
        url + '/get-branches',
        AutocompleteSearchResults,
        {
          fromService: ETeqServices.PharmacyOffice,
        }
      )

      const options = branches.SearchResults?.map((org) => ({
        text: org.fullName,
        value: GuidHelper.toString(org.id),
      }))

      dispatch(setBranchesForFilter(options))
    }

const SET_GLOBAL_FILTER_OPEN = 'PHARMACY_STATISTICS_SET_GLOBAL_FILTER_OPEN'
export const setGlobalFilterOpen = createAction(SET_GLOBAL_FILTER_OPEN)

const getPassedPublicationFilters = (startDate: DateTime, endDate: DateTime, branchId: string): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'SuccessfullCourseAttempt.successfullTryDate',
      operator: 'inDateRange',
      values: getDateTimeRange(startDate, endDate),
    },
    {
      dimension: 'SuccessfullCourseAttempt.successfullTryNumber',
      operator: 'lte',
      values: ['3'],
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })
  }

  return filters as Filter[]
}

const getFailedPublicationsFilter = (startDate: DateTime, endDate: DateTime, branchId: string): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'FailedCourseAttempt.failDate',
      operator: 'inDateRange',
      values: getDateTimeRange(startDate, endDate),
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })
  }

  return filters as Filter[]
}

const getTimedOutPublicationFilter = (startDate: DateTime, endDate: DateTime, branchId: string): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'TimedOutCourseAttempt.courseEndDate',
      operator: 'inDateRange',
      values: getDateTimeRange(startDate, endDate),
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })
  }

  return filters as Filter[]
}

const getPublicationInProgressFilters = (startDate: DateTime, endDate: DateTime, branchId: string, medicalCategories: IFilterSearch[]): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'StudentPublicationStatusHistory.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(startDate, endDate),
    },
    {
      dimension: 'StudentPublicationStatusPharmacy.status',
      operator: 'equals',
      values: [`${EStudentPublicationStatus.InProgress}`, `${EStudentPublicationStatus.Blocked}`],
    },
    {
      dimension: 'Publication.status',
      operator: 'equals',
      values: [`${EPublicationStatus.Active}`],
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })

    if (medicalCategories?.length > 0) {
      filters.push({
        dimension: 'Course.pharmacyOrganizationBranchId',
        operator: 'equals',
        values: [branchId],
      })
    }
  }

  return filters as Filter[]
}

const getNewPublicationFilter = (branchId: string, medicalCategories: IFilterSearch[]): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'Publication.status',
      operator: 'equals',
      values: [`${EPublicationStatus.Active}`],
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })

    if (medicalCategories?.length > 0) {
      filters.push({
        dimension: 'Course.pharmacyOrganizationBranchId',
        operator: 'equals',
        values: [branchId],
      })
    }
  }

  return filters as Filter[]
}

const getSuccessfullAttemptsFilters = (startDate: DateTime, endDate: DateTime, branchId: string, medicalCategories: IFilterSearch[]): Filter[] => {
  const filters: Filter[] = [
    {
      dimension: 'SuccessfullCourseAttempt.successfullTryDate',
      operator: 'inDateRange',
      values: getDateTimeRange(startDate, endDate),
    },
    {
      dimension: 'SuccessfullCourseAttempt.successfullTryNumber',
      operator: 'lte',
      values: ['3'],
    }
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })

    if (medicalCategories?.length > 0) {
      filters.push({
        dimension: 'Course.pharmacyOrganizationBranchId',
        operator: 'equals',
        values: [branchId],
      })
    }
  }

  return filters
}

const getFailedAttemptsFilter = (startDate: DateTime, endDate: DateTime, branchId: string): Filter[] => {
  const filters: Filter[] = [
    {
      dimension: 'FailedCourseAttempt.failDate',
      operator: 'inDateRange',
      values: getDateTimeRange(startDate, endDate),
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })
  }

  return filters
}

const getTimedOutAttemptsFilter = (startDate: DateTime, endDate: DateTime, branchId: string): Filter[] => {
  const filters: Filter[] = [
    {
      dimension: 'TimedOutCourseAttempt.courseEndDate',
      operator: 'inDateRange',
      values: getDateTimeRange(startDate, endDate),
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })
  }

  return filters
}

const getTotalPublicationFilter = (startDate: DateTime, endDate: DateTime, branchId: string, medicalCategories: IFilterSearch[]): Filter[] => {
  const filters: Filter[] = getDateFilters(startDate, endDate)

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })

    if (medicalCategories?.length > 0) {
      filters.push({
        dimension: 'Course.pharmacyOrganizationBranchId',
        operator: 'equals',
        values: [branchId],
      })
    }
  }

  return filters
}

const getPassedPublicationWithEndDateFilter = (startDate: DateTime, endDate: DateTime, branchId: string): Filter[] => {
  const filters: Filter[] = [
    ...getDateFilters(startDate, endDate),
    {
      dimension: 'SuccessfullCourseAttempt.successfullTryNumber',
      operator: 'lte',
      values: ['3'],
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })
  }

  return filters
}

const getPublicationByManufacturerBranch = (startDate: DateTime, endDate: DateTime): Filter[] => {
  const filters: Filter[] = [
    {
      dimension: 'PublicationByManufacturerBranch.courseStartDate',
      operator: 'afterDate',
      values: [startDateWithInclusionToString(startDate)],
    },
    {
      dimension: 'PublicationByManufacturerBranch.courseStartDate',
      operator: 'beforeDate',
      values: [endDateWithInclusionToString(endDate)],
    },
  ]

  return filters
}

const getPublicationByAnotherPharmacyOrganizationBranch = (startDate: DateTime, endDate: DateTime): Filter[] => {
  const filters: Filter[] = [
    {
      dimension: 'PublicationByAnotherPharmacyOrganizationBranch.courseStartDate',
      operator: 'afterDate',
      values: [startDateWithInclusionToString(startDate)],
    },
    {
      dimension: 'PublicationByAnotherPharmacyOrganizationBranch.courseStartDate',
      operator: 'beforeDate',
      values: [endDateWithInclusionToString(endDate)],
    },
  ]

  return filters
}

const getPublicationByOwnPharmacyOrganizationBranch = (startDate: DateTime, endDate: DateTime): Filter[] => {
  const filters: Filter[] = [
    {
      dimension: 'PublicationByOwnPharmacyOrganizationBranch.courseStartDate',
      operator: 'afterDate',
      values: [startDateWithInclusionToString(startDate)],
    },
    {
      dimension: 'PublicationByOwnPharmacyOrganizationBranch.courseStartDate',
      operator: 'beforeDate',
      values: [endDateWithInclusionToString(endDate)],
    },
  ]

  return filters
}

const CHANGE_FILTERS = 'PHARMACY_STATISTICS_CHANGE_FILTERS'
export const changeFilters =
  () =>
    (dispatch: AppDispatch, getState: () => IAppState): void => {
      const state = getState()
      const { startDate, endDate, businessCategories, medicalCategories, drugNames, branchId } =
        state.pharmacyGroupStatistics
      const filters: Filter[] = []

      if (medicalCategories?.length > 0) {
        filters.push({
          dimension: 'CourseNosologyAttachedMedicalCategory.medicalCategoryId',
          operator: 'equals',
          values: medicalCategories.map((v) => v.id),
        })
      }

      if (businessCategories?.length > 0) {
        filters.push({
          dimension: 'CourseBusinessCategory.businessCategoryId',
          operator: 'equals',
          values: businessCategories.map((v) => v.id),
        })
      }

      if (drugNames?.length > 0) {
        filters.push({
          dimension: 'CourseDrug.drugNameId',
          operator: 'equals',
          values: drugNames.map((v) => v.id),
        })
      }

      const publicationFilters = {
        publicationByManufacturerBranch: filters.concat(getPublicationByManufacturerBranch(startDate, endDate)),
        publicationByAnotherPharmacyOrganizationBranch: filters.concat(
          getPublicationByAnotherPharmacyOrganizationBranch(startDate, endDate)
        ),
        publicationByOwnPharmacyOrganizationBranch: filters.concat(
          getPublicationByOwnPharmacyOrganizationBranch(startDate, endDate)
        ),
      }

      const payload = {
        ...publicationFilters,
        successfullAttmptsfilters: filters.concat(getSuccessfullAttemptsFilters(startDate, endDate, branchId, medicalCategories)),
        failedAttemptsFilters: filters.concat(getFailedAttemptsFilter(startDate, endDate, branchId)),
        timedOutAttemptsFilters: filters.concat(getTimedOutAttemptsFilter(startDate, endDate, branchId)),
        successfullAttmptsByCourseFilters: filters.concat(getSuccessfullAttemptsByCourseFilters(startDate, endDate, branchId)),
        failedAttemptsByCourseFilters: filters.concat(getFailedAttemptsByCourseFilter(startDate, endDate, branchId)),
        timedOutAttemptsByCourseFilters: filters.concat(getTimedOutAttemptsByCourseFilter(startDate, endDate, branchId)),
        averageGradeFilters: filters.concat(getFiltersByPublicationAndOrganization(startDate, endDate, branchId)),
        averageLearingTimeFilters: filters.concat(getFiltersByPublicationAndOrganization(startDate, endDate, branchId)),
        failedPublicationsFilter: filters.concat(getFailedPublicationsFilter(startDate, endDate, branchId)),
        timedOutPublicationsFilter: filters.concat(getTimedOutPublicationFilter(startDate, endDate, branchId)),
        passedPublicationsFilter: filters.concat(getPassedPublicationFilters(startDate, endDate, branchId)),
        publicationInProgressFilters: filters.concat(getPublicationInProgressFilters(startDate, endDate, branchId, medicalCategories)),
        newPublicationFilter: filters.concat(getNewPublicationFilter(branchId, medicalCategories)),
        totalPublicationFilter: filters.concat(getTotalPublicationFilter(startDate, endDate, branchId, medicalCategories)),
        passedPublicationWithEndDateFilter: filters.concat(getPassedPublicationWithEndDateFilter(startDate, endDate, branchId)),
      }

      dispatch({ type: CHANGE_FILTERS, payload: payload })
    }

const getSuccessfullAttemptsByCourseFilters = (startDate: DateTime, endDate: DateTime, branchId: string): Filter[] => {
  const filters: Filter[] = [
    {
      dimension: 'SuccessfullCourseAttemptByPharmacyOrganization.successfullTryDate',
      operator: 'inDateRange',
      values: getDateTimeRange(startDate, endDate),
    },
    {
      dimension: 'SuccessfullCourseAttemptByPharmacyOrganization.successfullTryNumber',
      operator: 'lte',
      values: ['3'],
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })
  }

  return filters as unknown as Filter[]
}

const getFailedAttemptsByCourseFilter = (startDate: DateTime, endDate: DateTime, branchId: string): Filter[] => {
  const filters: Filter[] = [
    {
      dimension: 'FailedCourseAttemptByPharmacyOrganization.failDate',
      operator: 'inDateRange',
      values: getDateTimeRange(startDate, endDate),
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })
  }

  return filters as unknown as Filter[]
}

const getTimedOutAttemptsByCourseFilter = (startDate: DateTime, endDate: DateTime, branchId: string): Filter[] => {
  const filters: Filter[] = [
    {
      dimension: 'TimedOutCourseAttemptByOrganization.courseEndDate',
      operator: 'inDateRange',
      values: getDateTimeRange(startDate, endDate),
    },
    {
      dimension: 'Course.pharmacyOrganizationBranchId',
      operator: 'set',
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'UserPharmacyOrganizationBranch.branchId',
      operator: 'equals',
      values: [branchId],
    })
  }

  return filters as unknown as Filter[]
}

const getDateFilters = (startDate: DateTime, endDate: DateTime): Filter[] => [
  {
    dimension: 'Publication.courseStartDate',
    operator: 'beforeDate',
    values: [endDateWithInclusionToString(endDate)],
  },
  {
    dimension: 'Publication.courseEndDate',
    operator: 'afterDate',
    values: [startDateWithInclusionToString(startDate)],
  },
]

export const getFiltersByPublicationAndOrganization = (startDate: DateTime, endDate: DateTime, branchId: string): Filter[] => {
  const filters: Filter[] = [
    ...getDateFilters(startDate, endDate),
    {
      dimension: 'Course.id',
      operator: 'set',
    },
  ]

  if (branchId) {
    filters.push({
      dimension: 'Course.pharmacyOrganizationBranchId',
      operator: 'equals',
      values: [branchId],
    })
  }

  return filters as unknown as Filter[]
}

const CHANGE_START_DATE = 'PHARMACY_STATISTICS_CHANGE_START_DATE'
const changeStartDateAction = createAction<DateTime>(CHANGE_START_DATE)
export const changeStartDate =
  (date: DateTime) =>
    (dispatch: AppDispatch): void => {
      dispatch(changeStartDateAction(date))
      dispatch(changeFilters())
    }

const CHANGE_END_DATE = 'PHARMACY_STATISTICS_CHANGE_END_DATE'
const changeEndDateAction = createAction<DateTime>(CHANGE_END_DATE)
export const changeEndDate =
  (date: DateTime) =>
    (dispatch: AppDispatch): void => {
      dispatch(changeEndDateAction(date))
      dispatch(changeFilters())
    }

const SET_BUSINESS_CATEGORIES = 'PHARMACY_STATISTICS_SET_BUSINESS_CATEGORIES'
const setBusinessCategoriesAction = createAction<IFilterSearch[]>(SET_BUSINESS_CATEGORIES)
export const setBusinessCategories =
  (filters: IFilterSearch[]) =>
    (dispatch: AppDispatch, getState: () => IAppState): void => {
      const state = getState().pharmacyGroupStatistics
      const categories = filters.concat(state.medicalCategories).concat(state.drugNames)
      dispatch(setBusinessCategoriesAction(filters))
      dispatch(selectCategories(categories.length !== 0))
      dispatch(changeFilters())
    }

const SET_MEDICAL_CATEGORIES = 'PHARMACY_STATISTICS_SET_MEDICAL_CATEGORIES'
export const setMedicalCategoriesAction = createAction<IFilterSearch[]>(SET_MEDICAL_CATEGORIES)
export const setMedicalCategories =
  (filters: IFilterSearch[]) =>
    (dispatch: AppDispatch, getState: () => IAppState): void => {
      const state = getState().pharmacyGroupStatistics
      const categories = filters.concat(state.businessCategories).concat(state.drugNames)
      dispatch(setMedicalCategoriesAction(filters))
      dispatch(selectCategories(categories.length !== 0))
      dispatch(changeFilters())
    }

const SET_DRUGS = 'PHARMACY_STATISTICS_SET_DRUGS'
const setDrugsAction = createAction<IFilterSearch[]>(SET_DRUGS)
export const setDrugs =
  (filters: IFilterSearch[]) =>
    (dispatch: AppDispatch, getState: () => IAppState): void => {
      const state = getState().pharmacyGroupStatistics
      const categories = filters.concat(state.medicalCategories).concat(state.businessCategories)
      dispatch(setDrugsAction(filters))
      dispatch(selectCategories(categories.length !== 0))
      dispatch(changeFilters())
    }

const SET_LOADING = 'SET_LOADING'
const setLoading = createAction<boolean>(SET_LOADING)

const SET_USERS = 'SET_USERS'
const setUsers = createAction<IUserListResponse[]>(SET_USERS)
export const fetchUsers =
  (organizationId: string) =>
    async (dispatch: AppDispatch): Promise<void> => {
      const response = await ProtoClient.get<IUserListResponses>(
        `pharmacy-office/pharmacy-org-statistics/get-users/${organizationId}`,
        UserListResponses
      )
      response.items && dispatch(setUsers(response.items))
      dispatch(setLoading(false))
    }

export const clearUsers =
  () =>
    async (dispatch: AppDispatch): Promise<void> => {
      dispatch(setUsers([]))
    }

const SELECT_CATEGORIES = 'SELECT_CATEGORIES'
const selectCategories = createAction<boolean>(SELECT_CATEGORIES)

const RESET = 'PHARMACY_OFFICE_STATISTICS_RESET'
export const reset = createAction<boolean>(RESET)

const pharmacyOrganizationStatisticsReducer = createReducer(initialState, {
  [RESET]: () => initialState,
  [CHANGE_START_DATE]: (state, action) => {
    state.startDate = action.payload
  },
  [CHANGE_END_DATE]: (state, action) => {
    state.endDate = action.payload
  },
  [SET_BUSINESS_CATEGORIES]: (state, action) => {
    state.businessCategories = action.payload
  },
  [SET_MEDICAL_CATEGORIES]: (state, action) => {
    state.medicalCategories = action.payload
  },
  [SET_DRUGS]: (state, action) => {
    state.drugNames = action.payload
  },
  [CHANGE_FILTERS]: (state, action) => {
    state.successfullAttmptsFilters = action.payload.successfullAttmptsfilters
    state.failedAttemptsFilters = action.payload.failedAttemptsFilters
    state.timedOutAttemptsFilters = action.payload.timedOutAttemptsFilters
    state.successfullAttmptsByCourseFilters = action.payload.successfullAttmptsByCourseFilters
    state.failedAttemptsByCourseFilters = action.payload.failedAttemptsByCourseFilters
    state.timedOutAttemptsByCourseFilters = action.payload.timedOutAttemptsByCourseFilters
    state.averageGradeFilters = action.payload.averageGradeFilters
    state.averageLearingTimeFilters = action.payload.averageLearingTimeFilters
    state.failedPublicationsFilter = action.payload.failedPublicationsFilter
    state.timedOutPublicationsFilter = action.payload.timedOutPublicationsFilter
    state.passedPublicationsFilter = action.payload.passedPublicationsFilter
    state.publicationInProgressFilters = action.payload.publicationInProgressFilters
    state.newPublicationFilter = action.payload.newPublicationFilter
    state.totalPublicationFilter = action.payload.totalPublicationFilter
    state.passedPublicationWithEndDateFilter = action.payload.passedPublicationWithEndDateFilter
    state.publicationByManufacturerBranch = action.payload.publicationByManufacturerBranch
    state.publicationByAnotherPharmacyOrganizationBranch = action.payload.publicationByAnotherPharmacyOrganizationBranch
    state.publicationByOwnPharmacyOrganizationBranch = action.payload.publicationByOwnPharmacyOrganizationBranch
  },
  [SET_GLOBAL_FILTER_OPEN]: (state) => {
    state.globalFilterOpen = !state.globalFilterOpen
  },
  [SET_USERS]: (state, action) => {
    state.users = action.payload
  },
  [SET_LOADING]: (state, action) => {
    state.loading = action.payload
  },
  [SELECT_CATEGORIES]: (state, action) => {
    state.categoriesSelected = action.payload
  },
  [SET_BRANCHES_FOR_FILTER]: (state, action) => {
    state.branches = action.payload
  },
  [SET_BRANCH_ID]: (state, action) => {
    state.branchId = action.payload
  },
})

export default pharmacyOrganizationStatisticsReducer
