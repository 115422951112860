import { Dispatch } from 'redux'

import { createAction, createReducer } from '@reduxjs/toolkit'

import { GuidHelper } from '../../external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from '../../external/rp.ui/utils/protoClient'
import { AccountBalance, IAccountBalance } from '../../shared/proto/models'
import {
  BranchAddRequest,
  BranchEmployees,
  Employee,
  EmployeeCourses,
  IBranchAddRequest,
  IBranchEmployees,
  IEmployee,
  IEmployeeCourses,
  IPharmaciesGroup,
  IPharmaciesGroupList,
  IPharmaciesGroupResponse,
  IPharmacyGroupBranch,
  IPharmacyGroupBranches,
  IPharmacyGroupInformation,
  IPharmacyStatisticAvailableTabsList,
  IUserPharmacyOrganization,
  PharmaciesGroup,
  PharmaciesGroupList,
  PharmaciesGroupResponse,
  PharmacyGroupBranch,
  PharmacyGroupBranches,
  PharmacyGroupInformation,
  PharmacyStatisticAvailableTabsList,
  UserPharmacyOrganization,
} from '../proto/models'
import { IAppState } from './rootReducer'

const url = 'pharmacy-office'

interface IEmployeeState {
  info: IEmployee
  loadingInfo: boolean
  id: string
}

interface IAccountState {
  balance: IAccountBalance
  loadBalance: boolean
}

interface IGroupEmployeesState {
  info: IPharmacyGroupBranch
  loadingInfo: boolean
  employee: IEmployeeState
}

interface IGroupState {
  info: IPharmacyGroupInformation
  loadingInfo: boolean
  addBranchPending: boolean
  employees: IGroupEmployeesState
}

interface IAvailableTab {
  tabName: string
  isAvailable: boolean
}

export interface IPharmacyState {
  organization: IUserPharmacyOrganization
  availableTabs: IAvailableTab[]
  group: IGroupState
  account: IAccountState
}

const initialState: IPharmacyState = {
  organization: null,
  availableTabs: [],
  group: {
    info: null,
    loadingInfo: true,
    addBranchPending: false,
    employees: {
      info: null,
      loadingInfo: true,
      employee: {
        info: null,
        loadingInfo: true,
        id: null,
      },
    },
  },
  account: {
    balance: null,
    loadBalance: true,
  },
}

const GET_PHARMACY_ORGANIZATION = 'GET_PHARMACY_ORGANIZATION'
export const getPharmacyOrganization = () => async (dispatch: Dispatch): Promise<void> => {
  const res = await ProtoClient.get<IUserPharmacyOrganization>(
    url + `/pharmacies-group/organization`,
    UserPharmacyOrganization
  )

  dispatch({ type: GET_PHARMACY_ORGANIZATION, payload: res })
}

const GET_PHARMACY_STATISTIC_AVAILABLE_TABS = 'GET_PHARMACY_STATISTIC_AVAILABLE_TABS'
export const getPharmacyStatisticAvailableTabs = () => async (dispatch: Dispatch): Promise<void> => {
  const res = await ProtoClient.get<IPharmacyStatisticAvailableTabsList>(
    url + `/pharmacies-group/getAvailableTabs`,
    PharmacyStatisticAvailableTabsList
  )

  dispatch({ type: GET_PHARMACY_STATISTIC_AVAILABLE_TABS, payload: res })
}

export interface IPharmacyGroupRequest {
  pageIndex: number
  pageSize: number
  sortColumn: string
  sortDirection: string
  organizationId: string
}

export const getGroup = (options: IPharmacyGroupRequest) => async (): Promise<IPharmaciesGroupList> => {
  return ProtoClient.get<IPharmaciesGroupList>(url + '/pharmacies-group', PharmaciesGroupList, options)
}

export const deleteGroup = (id: string) => async (): Promise<void> => {
  ProtoClient.delete(url + '/pharmacies-group', id)
}

export const editGroup = (values: IPharmaciesGroup) => async (): Promise<IPharmaciesGroupResponse> => {
  return ProtoClient.post<IPharmaciesGroupResponse>(
    url + '/pharmacies-group',
    values,
    PharmaciesGroup,
    PharmaciesGroupResponse
  )
}

export const createGroup = (values: IPharmaciesGroup) => async (): Promise<IPharmaciesGroupResponse> => {
  return ProtoClient.put<IPharmaciesGroupResponse>(
    url + '/pharmacies-group',
    values,
    PharmaciesGroup,
    PharmaciesGroupResponse
  )
}

const LOAD_BALANCE_ACCOUNT = 'LOAD_BALANCE_ACCOUNT'
const GET_ACCOUNT_BALANCE = 'GET_ACCOUNT_BALANCE'
export const getAccountBalance = () => async (dispatch: Dispatch): Promise<void> => {
  const response = await ProtoClient.get<IAccountBalance>(url + '/pharmacy-account/account-balance', AccountBalance)

  dispatch({ type: GET_ACCOUNT_BALANCE, payload: response })
  dispatch({ type: LOAD_BALANCE_ACCOUNT, payload: false })
}

const LOAD_GROUP_INFORMATION = 'LOAD_GROUP_INFORMATION'
const GET_GROUP_INFORMATION = 'GET_GROUP_INFORMATION'
export const getGroupInformation = (groupId: string) => async (dispatch: Dispatch): Promise<void> => {
  const response = await ProtoClient.get<IPharmacyGroupInformation>(
    url + '/pharmacies-group/group-info',
    PharmacyGroupInformation,
    {
      groupId: groupId,
    }
  )

  dispatch({ type: GET_GROUP_INFORMATION, payload: response })
  dispatch({ type: LOAD_GROUP_INFORMATION, payload: false })
}

const RESET_GROUP_INFORMATION = 'RESET_GROUP_INFORMATION'
export const resetGroupInformation = createAction(RESET_GROUP_INFORMATION)

export interface IGroupBranchesRequest {
  pageIndex: number
  pageSize: number
  sortColumn: string
  sortDirection: string
  groupId: string
}

export const getGroupBranches = (options: IGroupBranchesRequest) => async (): Promise<IPharmacyGroupBranches> => {
  return ProtoClient.get<IPharmacyGroupBranches>(url + '/pharmacies-group/branches', PharmacyGroupBranches, options)
}

export const deleteGroupBranches = (id: string) => async (): Promise<void> => {
  ProtoClient.delete(url + '/pharmacies-group/branches', id)
}

export interface IAddGroupBranchesRequest {
  searchQuery: string
  groupId: string
}

export const getAddGroupBranches = (options: IAddGroupBranchesRequest) => async (): Promise<IPharmacyGroupBranches> => {
  return ProtoClient.get<IPharmacyGroupBranches>(
    url + '/pharmacies-group/branches/pharm-to-add',
    PharmacyGroupBranches,
    options
  )
}

const ADD_BRANCH_PENDING = 'ADD_BRANCH_PENDING'
export const addGroupBranch = (options: IBranchAddRequest) => async (
  dispatch: Dispatch,
  getState: () => IAppState
): Promise<void> => {
  const pending = getState().pharmacy.group.addBranchPending
  if (pending) {
    return
  }

  dispatch({ type: ADD_BRANCH_PENDING, payload: true })
  await ProtoClient.post(url + '/pharmacies-group/branches/add', options, BranchAddRequest)
  dispatch({ type: ADD_BRANCH_PENDING, payload: false })
}

const LOAD_GROUP_EMPLOYEES_INFORMATION = 'LOAD_GROUP_EMPLOYEES_INFORMATION'
const GET_GROUP_EMPLOYEES_INFORMATION = 'GET_GROUP_EMPLOYEES_INFORMATION'
export const getGroupEmployeesInformation = (pharmacyId: string) => async (dispatch: Dispatch): Promise<void> => {
  const response = await ProtoClient.get<IPharmacyGroupBranch>(
    url + '/pharmacies-group/branches/get-pharmacy',
    PharmacyGroupBranch,
    {
      pharmacyId: pharmacyId,
    }
  )

  dispatch({ type: GET_GROUP_EMPLOYEES_INFORMATION, payload: response })
  dispatch({ type: LOAD_GROUP_EMPLOYEES_INFORMATION, payload: false })
}

const RESET_GROUP_EMPLOYEES_INFORMATION = 'RESET_GROUP_EMPLOYEES_INFORMATION'
export const resetGroupEmployeesInformation = createAction(RESET_GROUP_EMPLOYEES_INFORMATION)

export interface IGroupEmployeesRequest {
  pageIndex: number
  pageSize: number
  sortColumn: string
  sortDirection: string
  branchId: string
  searchQuery?: string
}

export const getGroupEmployees = (options: IGroupEmployeesRequest) => async (): Promise<IBranchEmployees> => {
  return ProtoClient.get<IBranchEmployees>(url + '/employee', BranchEmployees, options)
}

export const deleteGroupEmployees = (employeeId: string) => async (): Promise<void> => {
  ProtoClient.delete(url + '/employee', employeeId)
}

const LOAD_EMPLOYEE = 'LOAD_EMPLOYEE'
const GET_EMPLOYEE = 'GET_EMPLOYEE'
export const getEmployee = (employeeId: string) => async (dispatch: Dispatch): Promise<void> => {
  const response = await ProtoClient.get<IEmployee>(url + '/employee/get-employee', Employee, { id: employeeId })

  dispatch({ type: GET_EMPLOYEE, payload: response })
  dispatch({ type: LOAD_EMPLOYEE, payload: false })
}

const RESET_EMPLOYEE = 'RESET_EMPLOYEE'
export const resetEmployee = createAction(RESET_EMPLOYEE)

export interface IEmployeeCoursesRequest {
  pageIndex: number
  pageSize: number
  sortColumn: string
  sortDirection: string
  employeeId: string
  hideInactive: boolean
  searchQuery: string
}

export const getEmployeeCourses = (options: IEmployeeCoursesRequest) => async (): Promise<IEmployeeCourses> => {
  return ProtoClient.get<IEmployeeCourses>(url + '/employee/get-courses', EmployeeCourses, options)
}

const pharmacyReducer = createReducer(initialState, {
  [GET_PHARMACY_ORGANIZATION]: (state, action) => {
    state.organization = action.payload
  },
  [GET_PHARMACY_STATISTIC_AVAILABLE_TABS]: (state, action) => {
    state.availableTabs = action.payload.items
  },
  [GET_ACCOUNT_BALANCE]: (state, action) => {
    state.account.balance = action.payload
  },
  [LOAD_BALANCE_ACCOUNT]: (state, action) => {
    state.account.loadBalance = action.payload
  },
  [GET_GROUP_INFORMATION]: (state, action) => {
    state.group.info = action.payload
  },
  [LOAD_GROUP_INFORMATION]: (state, action) => {
    state.group.loadingInfo = action.payload
  },
  [RESET_GROUP_INFORMATION]: (state) => {
    state.group.info = initialState.group.info
    state.group.loadingInfo = initialState.group.loadingInfo
  },
  [ADD_BRANCH_PENDING]: (state, action) => {
    state.group.addBranchPending = action.payload
  },
  [GET_GROUP_EMPLOYEES_INFORMATION]: (state, action) => {
    state.group.employees.info = action.payload
  },
  [RESET_GROUP_EMPLOYEES_INFORMATION]: (state) => {
    state.group.employees = initialState.group.employees
  },
  [LOAD_GROUP_EMPLOYEES_INFORMATION]: (state, action) => {
    state.group.employees.loadingInfo = action.payload
  },
  [LOAD_EMPLOYEE]: (state, action) => {
    state.group.employees.employee.loadingInfo = action.payload
  },
  [GET_EMPLOYEE]: (state, action) => {
    state.group.employees.employee.info = action.payload
    state.group.employees.employee.id = GuidHelper.toString(action.payload.id)
  },
  [RESET_EMPLOYEE]: (state) => {
    state.group.employees.employee = initialState.group.employees.employee
  },
})

export default pharmacyReducer
