import { createAction, createReducer, Dispatch } from '@reduxjs/toolkit'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import {
  BusinessTrainerInfo,
  BusinessTrainersCardList,
  IBusinessTrainerInfo,
  IBusinessTrainersCard,
  IBusinessTrainersCardList,
} from 'pharmacy-office/proto/models'
import { IBusinessTrainerCardRequest } from './businessTrainersReducer'

const url = 'pharmacy-office/business-trainers'

export interface IBusinessTrainerInfoState {
  businessTrainersCardLoading: boolean
  businessTrainerCards: IBusinessTrainersCard[]
  businessTrainersCardCount: number
  businessTrainerInfo: IBusinessTrainerInfo
}

const initialStateNusinessTrainerInfo: IBusinessTrainerInfo = {
  name: null,
  phoneNumber: null,
  email: null,
}

const initialState: IBusinessTrainerInfoState = {
  businessTrainersCardLoading: true,
  businessTrainersCardCount: 0,
  businessTrainerInfo: initialStateNusinessTrainerInfo,
  businessTrainerCards: [],
}

const SET_BUSINESS_TRAINER_CARD_LIST = 'SET_BUSINESS_TRAINER_CARDS'
const setBusinessTrainerCardList = createAction<IBusinessTrainersCardList>(SET_BUSINESS_TRAINER_CARD_LIST)

const BUSINESS_TRAINER_CARD_LOADING = 'BUSINESS_TRAINER_CARD_LOADING'
const setBusinessTrainerCardLoading = createAction<boolean>(BUSINESS_TRAINER_CARD_LOADING)

export const loadBusinessTrainerCardList = (options: IBusinessTrainerCardRequest) => async (
  dispatch: Dispatch
): Promise<void> => {
  dispatch(setBusinessTrainerCardLoading(true))

  const response = await ProtoClient.get<IBusinessTrainersCardList>(
    url + '/business-trainer-card',
    BusinessTrainersCardList,
    options
  )
  dispatch(setBusinessTrainerCardList(response))
  dispatch(setBusinessTrainerCardLoading(false))
}

const GET_BUSINESS_TRAINER_INFO = 'GET_BUSINESS_TRAINER_INFO'
export const getBusinessTrainerInfo = (trainerId: string) => async (dispatch: Dispatch) => {
  const response = await ProtoClient.get<IBusinessTrainerInfo>(url + '/business-trainer-info', BusinessTrainerInfo, {
    trainerId: trainerId,
  })

  dispatch({ type: GET_BUSINESS_TRAINER_INFO, payload: response })
}

export const clearBusinessTrainerCard = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setBusinessTrainerCardList({ items: [], rowCount: 0 }))
  dispatch(setBusinessTrainerCardLoading(true))
}

const businessTrainersInfoReducer = createReducer(initialState, {
  [GET_BUSINESS_TRAINER_INFO]: (state, action: { payload: BusinessTrainerInfo }) => {
    state.businessTrainerInfo = action.payload
  },
  [BUSINESS_TRAINER_CARD_LOADING]: (state, action: { payload: boolean }) => {
    state.businessTrainersCardLoading = action.payload
  },
  [SET_BUSINESS_TRAINER_CARD_LIST]: (state, action: { payload: IBusinessTrainersCardList }) => {
    state.businessTrainerCards = action.payload.items
    state.businessTrainersCardCount = action.payload.rowCount
  },
})

export default businessTrainersInfoReducer
