import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Explanations from 'shared/components/Explanations'
import {
  explanationsItem,
  explanationsList,
  explanationsParagraph,
  explanationsText,
} from 'shared/components/Explanations/Explanations.module.scss'
import CubeQueryRender, { IChartComponentProps, renderChart } from 'shared/components/QueryRender/CubeQueryRender'
import CustomBarCursor from 'shared/components/Recharts/CustomBarCursor'
import CustomLegend from 'shared/components/Recharts/CustomLegend'
import CustomTooltip from 'shared/components/Recharts/CustomTooltip'
import { IUserListResponse } from 'shared/proto/models'

import { ChartPivotRow, Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import { IAppState } from '../../../../reducers/rootReducer'
import { blockChart, blockChartGraph, blockChartTitle, chart } from './BlockCharts.module.scss'
import { getChartWidth, thresholdValue } from './utils'

const inProgressKey = 'inProgress'
const newPublicationKey = 'new'
const passedKey = 'successful'
const failedKey = 'failed'
const timedOutKey = 'timedOut'

const newPublicationCount = 'NotStartedPharmacistCourse.count'
const newPublicationUserId = 'NotStartedPharmacistCourse.userId'

const inProgressCount = 'StudentPublicationStatusPharmacy.countInProgress'
const inProgressUserId = 'StudentPublicationStatusPharmacy.userId'

const passedCount = 'SuccessfullCourseAttempt.count'
const passedUserId = 'SuccessfullCourseAttempt.userId'

const failedCount = 'FailedCourseAttempt.count'
const failedUserId = 'FailedCourseAttempt.userId'

const timedOutCount = 'TimedOutCourseAttempt.count'
const timedOutUserId = 'TimedOutCourseAttempt.userId'

const labelFormatter = (label: React.ReactText) => {
  return label != 0 ? `${label}` : ''
}

const barSize = 80
const barSizeBigData = 10
const countBarStyle = 5
const tooltipColor = '#201F25'

export const barNames = (key: string): string => {
  switch (key) {
    case newPublicationKey:
      return 'Новый'
    case inProgressKey:
      return 'В процессе'
    case passedKey:
      return 'Сдано курсов'
    case failedKey:
      return 'Не сдано курсов'
    default:
      return ''
  }
}

export const barСolor = (key: string): string => {
  switch (key) {
    case inProgressKey:
      return '#FEC213'
    case newPublicationKey:
      return '#ACB2BD'
    case passedKey:
      return '#42CF59'
    case failedKey:
      return '#EE5787'
    default:
      return ''
  }
}

const applyLabelToBars = (
  user: IUserListResponse,
  inProgressPivots: ChartPivotRow[],
  newPublicationPivots: ChartPivotRow[],
  passedPivots: ChartPivotRow[],
  failedPivots: ChartPivotRow[],
  timedOutPivots: ChartPivotRow[]
) => {
  const userId = GuidHelper.toString(user.userId).toLowerCase()
  const filteredInProgressPivots = inProgressPivots.filter((p) => p.xValues[0] === userId)
  const filteredNewPublicationPivots = newPublicationPivots.filter((p) => p.xValues[0] === userId)
  const filteredPassedPivots = passedPivots.filter((p) => p.xValues[0] === userId)
  const filteredFailedPivots = failedPivots.filter((p) => p.xValues[0] === userId)
  const filteredTimedOutPivots = timedOutPivots.filter((p) => p.xValues[0] === userId)
  const preparedData = {
    [newPublicationKey]: filteredNewPublicationPivots.find((v) => v.x === `${userId}`)?.[newPublicationCount] ?? 0,
    [inProgressKey]: filteredInProgressPivots.find((v) => v.x === `${userId}`)?.[inProgressCount] ?? 0,
    [passedKey]: filteredPassedPivots.find((v) => v.x === `${userId}`)?.[passedCount] ?? 0,
    [failedKey]:
      (filteredFailedPivots.find((v) => v.x === `${userId}`)?.[failedCount] ?? 0) +
      (filteredTimedOutPivots.find((v) => v.x === `${userId}`)?.[timedOutCount] ?? 0),
    ['name']: user.fullName,
  }
  return preparedData
}

const getData = (set: { [key: string]: ResultSet }, users: IUserListResponse[]) => {
  const inprogressPivots = set[inProgressKey].chartPivot({
    x: [inProgressUserId],
    y: [inProgressCount],
  })
  const newPublicationPivots = set[newPublicationKey].chartPivot({
    x: [newPublicationUserId],
    y: [newPublicationCount],
  })
  const passedPivots = set[passedKey].chartPivot({
    x: [failedUserId],
    y: [failedCount],
  })
  const failedPivots = set[failedKey].chartPivot({
    x: [failedUserId],
    y: [failedCount],
  })
  const timedOutPivots = set[timedOutKey].chartPivot({
    x: [timedOutUserId],
    y: [timedOutCount],
  })
  const userIds = [
    ...new Set(
      inprogressPivots
        .map((r) => r.xValues[0])
        .concat(newPublicationPivots.map((r) => r.xValues[0]))
        .concat(passedPivots.map((r) => r.xValues[0]))
        .concat(failedPivots.map((r) => r.xValues[0]))
        .concat(timedOutPivots.map((r) => r.xValues[0]))
    ),
  ]

  const filteredUsers = users.filter((u) => userIds.includes(GuidHelper.toString(u.userId).toLowerCase()))

  const perparedData = filteredUsers.map((i) =>
    applyLabelToBars(i, inprogressPivots, newPublicationPivots, passedPivots, failedPivots, timedOutPivots)
  )

  return perparedData
}

const barRender = (props: IBarRenderProps) => {
  const { resultSet, users } = props
  const data = getData(resultSet as { [key: string]: ResultSet }, users)
  const memoData = React.useMemo(() => data, [data])
  const [show, setShow] = useState<string>(null)

  const barData = [newPublicationKey, inProgressKey, passedKey, failedKey]

  const legendClickHandler = (dataKey: string) => {
    if (show === dataKey) {
      setShow(null)
    } else {
      setShow(dataKey)
    }
  }

  const width = getChartWidth(data)

  return (
    <ResponsiveContainer width={width} height="100%">
      <BarChart data={data} margin={{ top: 20 }}>
        <YAxis key="YAxis" axisLine={false} tickLine={false} tickFormatter={labelFormatter} width={40} />
        <XAxis hide={true} dataKey="name" />
        <CartesianGrid strokeDasharray="5 5" horizontal={true} vertical={false} stroke="#ACB2BD" />
        {barData.map((item, index) => {
          return (
            <Bar
              hide={show !== null && show !== item}
              key={`bar-${index}`}
              dataKey={item}
              stackId="a"
              name={barNames(item)}
              fill={barСolor(item)}
              minPointSize={3}
              barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
              background={show === item && { fill: '#eee' }}
            ></Bar>
          )
        })}
        <Tooltip cursor={<CustomBarCursor customWidth={2} color={tooltipColor} />} content={<CustomTooltip />} />
        <Legend
          wrapperStyle={{ bottom: '-3px' }}
          height={35}
          content={<CustomLegend barData={barData} onClick={legendClickHandler} />}
        />
      </BarChart>
    </ResponsiveContainer>
  )
}

interface IBarRenderStateProps {
  users: IUserListResponse[]
}

type IBarRenderProps = IBarRenderStateProps & IChartComponentProps

const mapStateToBarChartProps = (store: IAppState): IBarRenderStateProps => {
  return {
    users: store.pharmacyGroupStatistics.users,
  }
}
const connectedBarRender = connect<IBarRenderStateProps, {}, IChartComponentProps, IAppState>(mapStateToBarChartProps)(
  barRender
)

const chartRender = (props: QueryRendererRenderProps) => (
  <div className={chart}>
    {renderChart(connectedBarRender)(props)}
  </div>
)

const VisibleExplanationsContent = () => {
  return (
    <p className={explanationsParagraph}>
      На&nbsp;графике отражены данные по&nbsp;всем курсам, которые были изучены вашими провизорами и&nbsp;провизорами,
      у&nbsp;которых есть заявка на&nbsp;добавление в&nbsp;штат вашей аптечной сети.
    </p>
  )
}

const ExplanationsContent = () => {
  return (
    <>
      <VisibleExplanationsContent />
      <p className={explanationsParagraph}>
        График показывает прогресс сдачи курсов по&nbsp;конкретному провизору. То&nbsp;есть:
      </p>
      <ul className={explanationsList}>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Новый&nbsp;&#8210; процент курсов, которые назначены на&nbsp;провизора, но&nbsp;он&nbsp;не&nbsp;приступал
            к&nbsp;их&nbsp;изучению
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            В&nbsp;процессе&nbsp;&#8210; курсы, по&nbsp;которым провизор изучает презентацию или проходит тест
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>Сдано курсов&nbsp;&#8210; процент успешно сданных курсов</span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Не&nbsp;сдано&nbsp;&#8210; это курсы, тест которых не&nbsp;смог сдать провизор после трех попыток,
            а&nbsp;также курсы, которые провизор не&nbsp;успел пройти прежде чем они ушли в&nbsp;архив
          </span>
        </li>
      </ul>
    </>
  )
}

const CourseProgressByPharmacistChart = (props: CourseProgressByPharmacistChartProps) => {
  return (
    <div className={blockChart}>
      <h2 className={blockChartTitle}>Прогресс курсов</h2>
      <div className={blockChartGraph}>
        <CubeQueryRender
          queries={{
            [newPublicationKey]: {
              measures: [newPublicationCount],
              dimensions: [newPublicationUserId],
              filters: props.newPublicationFilter,
            },
            [inProgressKey]: {
              measures: [inProgressCount],
              dimensions: [inProgressUserId],
              filters: props.publicationInProgressFilters,
            },
            [passedKey]: {
              measures: [passedCount],
              dimensions: [passedUserId],
              filters: props.passedPublicationsFilter,
            },
            [failedKey]: {
              measures: [failedCount],
              dimensions: [failedUserId],
              filters: props.failedPublicationsFilter,
            },
            [timedOutKey]: {
              measures: [timedOutCount],
              dimensions: [timedOutUserId],
              filters: props.timedOutPublicationsFilter,
            },
          }}
          render={chartRender}
        />
      </div>

      <Explanations content={<ExplanationsContent />} visibleContent={<VisibleExplanationsContent />} />
    </div>
  )
}

interface ICourseProgressByPharmacistChartStateProps {
  failedPublicationsFilter: Filter[]
  timedOutPublicationsFilter: Filter[]
  passedPublicationsFilter: Filter[]
  publicationInProgressFilters: Filter[]
  newPublicationFilter: Filter[]
}

type CourseProgressByPharmacistChartProps = ICourseProgressByPharmacistChartStateProps

const mapStateToProps = (store: IAppState): CourseProgressByPharmacistChartProps => {
  return {
    failedPublicationsFilter: store.pharmacyGroupStatistics.failedPublicationsFilter,
    timedOutPublicationsFilter: store.pharmacyGroupStatistics.timedOutPublicationsFilter,
    passedPublicationsFilter: store.pharmacyGroupStatistics.passedPublicationsFilter,
    publicationInProgressFilters: store.pharmacyGroupStatistics.publicationInProgressFilters,
    newPublicationFilter: store.pharmacyGroupStatistics.newPublicationFilter,
  }
}

export default connect<CourseProgressByPharmacistChartProps, unknown, unknown, IAppState>(mapStateToProps)(
  CourseProgressByPharmacistChart
)
