/* eslint-disable react/display-name */
import classNames from 'classnames'
import { Link, withPrefix } from 'gatsby'
import React, { ReactElement, useEffect, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInterface,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
} from 'react-table'
import { initialize } from 'redux-form'

import { GuidHelper } from '../../../../external/rp.ui/helpers/GuidHelper'
import DeleteButton from '../../../../shared/components/Buttons/DeleteButton'
import EditButton from '../../../../shared/components/Buttons/EditButton'
import TextButton from '../../../../shared/components/Buttons/TextButton'
import AddIcon from '../../../../shared/components/Icons/AddIcon'
import PreviewIcon from '../../../../shared/components/Icons/PreviewIcon'
import AlertObjectRemove from '../../../../shared/components/Modal/AlertObjectRemove'
import Table from '../../../../shared/components/Table'
import {
  tableButtons,
  tableButtonsPreview,
  tableCell,
  tableText,
  tableTextContainer,
} from '../../../../shared/components/Table/Table.module.scss'
import TablePaging from '../../../../shared/components/TablePaging/TablePaging'
import { IPharmaciesGroup, IPharmaciesGroupList, IPharmaciesGroupResponse } from '../../../proto/models'
import { IPharmacyGroupRequest } from '../../../reducers/pharmacyReducer'
import { AppDispatch } from '../../../reducers/rootReducer'
import AddPharmaciesGroupModal from './AddPharmaciesGroupModal'
import {
  section,
  sectionButtons,
  sectionContent,
  tableCellBranchCount,
  tableCellButtons,
  tableCellGroup,
  tableCellNumber,
} from './PharmaciesGroup.module.scss'

interface IPharmaciesGroupTableProps {
  dispatch: AppDispatch
  organizationId: string
  getGroup: (options: IPharmacyGroupRequest) => Promise<IPharmaciesGroupList>
  deleteGroup: (id: string) => void
  editGroup: (values: IPharmaciesGroup) => Promise<IPharmaciesGroupResponse>
  createGroup: (values: IPharmaciesGroup) => Promise<IPharmaciesGroupResponse>
}

const PharmaciesGroupTable = (props: IPharmaciesGroupTableProps): ReactElement<IPharmaciesGroupTableProps> => {
  const [fetchedData, setFetchedData] = useState<IPharmaciesGroup[]>([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [openAddModal, setOpenAddModal] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [currId, setCurrId] = useState(null)
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)
  const pageSizeOptions = [25, 50, 75]
  const [localPageSize, setLocalPageSize] = useState(pageSizeOptions[0])
  const [localPageIndex, setLocalPageIndex] = useState(0)

  const openCreateModalHandler = () => {
    setOpenAddModal(true)
    setEdit(false)
  }

  const openEditModalHandler = (group: IPharmaciesGroup) => {
    props.dispatch(initialize('addPharmaciesGroupEditForm', group))
    setOpenAddModal(true)
    setEdit(true)
  }

  const openRemoveModalHandler = (id: string) => {
    setOpenRemoveModal(true)
    setCurrId(id)
  }

  const closeAddModalHandler = () => {
    setLoading(true)
    setOpenAddModal(false)
    onFetchData({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    })
  }

  const agreeRemoveCallback = async () => {
    setLoading(true)
    await props.deleteGroup(currId)
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    })
  }

  interface FetchDataProps {
    pageIndex: number
    pageSize: number
    sortBy: Array<SortingRule<IPharmaciesGroup>>
  }

  const onFetchData = async ({ pageIndex, pageSize, sortBy }: FetchDataProps) => {
    const paging: IPharmacyGroupRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortBy[0]?.id || 'name',
      sortDirection: sortBy[0]?.desc ?? true ? 'desc' : 'asc',
      organizationId: props.organizationId,
    }

    const response = await props.getGroup(paging)
    setFetchedData(response?.items ?? [])
    setTotalRowCount(response?.rowCount ?? 0)
    setLoading(false)
  }

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  const getCellClass = (column: ColumnInterface<IPharmaciesGroup>) => {
    switch (column.id) {
      case 'row': {
        return classNames(tableCell, tableCellNumber)
      }
      case 'name': {
        return classNames(tableCell, tableCellGroup)
      }
      case 'branchCount': {
        return classNames(tableCell, tableCellBranchCount)
      }
      case 'rowButtons': {
        return classNames(tableCell, tableCellButtons)
      }
    }
  }

  const columns: Column<IPharmaciesGroup>[] = React.useMemo(
    () => [
      {
        Header: '#',
        id: 'row',
        Cell: (cell: Cell<IPharmaciesGroup>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.index + localPageSize * localPageIndex + 1}</span>
            </p>
          )
        },
      },
      {
        Header: 'Наименование группы',
        id: 'name',
        accessor: 'name',
        Cell: (cell: Cell<IPharmaciesGroup>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
        sortType: 'basic',
      },
      {
        Header: 'Количество филиалов',
        id: 'branchCount',
        accessor: 'branchCount',
        disableSortBy: true,
        Cell: (cell: Cell<IPharmaciesGroup>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
      },
      {
        id: 'rowButtons',
        Cell: (cell: Cell<IPharmaciesGroup>) => (
          <div className={tableButtons}>
            <Link
              className={tableButtonsPreview}
              to={withPrefix(
                `pharmacy-office/organization/group/${GuidHelper.toString(cell.row.original.id)}/branches`
              )}
            >
              <PreviewIcon />
            </Link>
            <EditButton iconColor="blue" onClick={() => openEditModalHandler(cell.row.original)} />
            <DeleteButton
              iconColor="blue"
              onClick={() => openRemoveModalHandler(GuidHelper.toString(cell.row.original.id))}
            />
          </div>
        ),
        width: 135,
      },
    ],
    [localPageSize, localPageIndex]
  )

  const data = React.useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const tablePageCount = React.useMemo(
    () => Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0])),
    [totalRowCount, localPageSize]
  )

  const initialState: Partial<TableState<IPharmaciesGroup>> &
    Partial<UsePaginationState<IPharmaciesGroup>> &
    UseSortByState<IPharmaciesGroup> = {
    pageSize: localPageSize,
    sortBy: [{ id: 'name', desc: true }],
  }

  const tableOptions: TableOptions<IPharmaciesGroup> &
    UseSortByOptions<IPharmaciesGroup> &
    UsePaginationOptions<IPharmaciesGroup> = {
    data: data,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    manualSortBy: true,
    manualPagination: true,
    disableSortRemove: true,
  }

  const table = useTable<IPharmaciesGroup>(tableOptions, useSortBy, usePagination) as TableInstance<IPharmaciesGroup> &
    UsePaginationInstanceProps<IPharmaciesGroup>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize, sortBy } = table.state as UsePaginationState<IPharmaciesGroup> &
    UseSortByState<IPharmaciesGroup>

  useEffect(() => {
    setLoading(true)
    setLocalPageIndex(pageIndex)
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
    })
  }, [pageIndex, pageSize, sortBy])

  useEffect(() => {
    return () => {
      setFetchedData([])
      setOpenAddModal(false)
      setOpenRemoveModal(false)
      setTotalRowCount(0)
      setCurrId(null)
      setEdit(false)
      setLoading(false)
    }
  }, [])

  return (
    <div className={section}>
      <div className={sectionContent}>
        <div className={sectionButtons}>
          <TextButton iconLeft={<AddIcon color="white" />} text="Создать группу" onClick={openCreateModalHandler} />
        </div>
        <Table<IPharmaciesGroup> tableInstance={table} loading={loading} getCellClass={getCellClass} />
        <TablePaging
          gotoPage={gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          pageSizeOptions={pageSizeOptions}
          setLocalPageSize={setLocalPageSize}
          setPageSize={setPageSize}
        />
      </div>
      <AddPharmaciesGroupModal
        open={openAddModal}
        edit={edit}
        organizationId={props.organizationId}
        handleClose={closeAddModalHandler}
        createGroup={props.createGroup}
        editGroup={props.editGroup}
      />
      <AlertObjectRemove
        isOpen={openRemoveModal}
        title={'Вы уверены, что хотите удалить группу?'}
        agreeString={'Удалить'}
        disagreeString={'Отменить'}
        agreeCallback={agreeRemoveCallback}
        handleClose={() => setOpenRemoveModal(false)}
      />
    </div>
  )
}

export default PharmaciesGroupTable
