import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import throttleFetch from 'external/rp.ui/utils/throttleFetch'
import { IUserPharmacyOrganization } from 'pharmacy-office/proto/models'
import React, { ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'
import { InjectedFormProps, reduxForm } from 'redux-form'
import AdditionalFilterbar from 'shared/components/CourseDesigner/AdditionalFilterbar'
import { IOptions } from 'shared/components/CustomListSelect/CustomListSelect'
import LoaderSpinner from 'shared/components/LoaderSpinner'
import { IStatisticsPermissions } from 'shared/proto/models'

import {
  changeFilters,
  fetchUsers,
  getBranches,
  reset,
  setBranchId,
} from '../../../reducers/pharmacyOrganizationStatisticsReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import AverageTestGradeChart from './Charts/AverageTestGradeChart'
import AverageTotalLearningTimeChart from './Charts/AverageTotalLearningTimeChart'
import CourseProgressByPharmacistChart from './Charts/CourseProgressByPharmacistChart'
import PassedTestByPharmacistRatioChart from './Charts/PassedTestByPharmacistRatioChart'
import TestComplitionByCourseChart from './Charts/TestComplitionByCourseChart'
import TestComplitionByPharmacistChart from './Charts/TestComplitionByPharmacistChart'
import TestComplitionChart from './Charts/TestComplitionChart'
import BlockFilters from './Filter/BlockFilters'
import GlobalFilter from './Filter/GlobalFilter'
import PublicationByCategoryChart from 'shared/components/GeneralStatistic/PublicationByCategoryChart'
import RegionalManagerPublicationByCategoryChart from 'shared/components/GeneralStatistic/RegionalManagerPublicationByCategoryChart'
import { useAppSelector } from 'pharmacy-office/components/App/hook'
import { sectionContent } from './GeneralStatistics.module.scss'

interface ICourseStatisticsOwnProps {
  path: string
}

interface ICourseStatisticsStateProps {
  dispatch: AppDispatch
  organization: IUserPharmacyOrganization
  loading: boolean
  branches: IOptions[]
  permissions: IStatisticsPermissions
  selectedBranchId?: string
}

interface IAdditionalToolbarFormData {
  branch: string
}

type PharmacyOrganizationStatisticsProps = ICourseStatisticsOwnProps & ICourseStatisticsStateProps

type AdditionalToolbarProps = PharmacyOrganizationStatisticsProps &
  InjectedFormProps<IAdditionalToolbarFormData, PharmacyOrganizationStatisticsProps, string>

const AdditionalToolbar = (props: AdditionalToolbarProps): React.ReactElement => {
  const { handleSubmit, change } = props

  return (
    <form onSubmit={handleSubmit}>
      <AdditionalFilterbar options={props.branches} changeHandler={change} />
    </form>
  )
}

const throttledChangeBranchId = throttleFetch((dispatch: AppDispatch, branchId: string) => {
  dispatch(fetchUsers(branchId))
  dispatch(setBranchId(branchId))
})

const AdditionalFilterbarForm = reduxForm<IAdditionalToolbarFormData, PharmacyOrganizationStatisticsProps>({
  form: 'generalStatisticsAdditionalFilterbar',
  onChange: (values, dispatch, props, previousValues) => {
    if (values.branch !== previousValues.branch && values.branch) {
      props.submit()
    }
  },
  onSubmit: (values, dispatch, _) => {
    throttledChangeBranchId(dispatch, values.branch)

    dispatch(changeFilters())
  },
})(AdditionalToolbar)

const PharmacyOrganizationStatistics = (
  props: PharmacyOrganizationStatisticsProps
): ReactElement<PharmacyOrganizationStatisticsProps> => {
  useEffect(() => {
    props.dispatch(changeFilters())
    if (props.permissions?.haveAdditionalFilters) {
      props.dispatch(getBranches())
    }
    return () => {
      props.dispatch(reset())
    }
  }, [])

  const ownOrganizationFilter = useAppSelector(
    (state) => state.pharmacyGroupStatistics.publicationByOwnPharmacyOrganizationBranch
  )
  const anotherOrganizationFilter = useAppSelector(
    (state) => state.pharmacyGroupStatistics.publicationByManufacturerBranch
  )
  const anotherInOwnOrganizationFilter = useAppSelector(
    (state) => state.pharmacyGroupStatistics.publicationByAnotherPharmacyOrganizationBranch
  )
  const categoriesSelected = useAppSelector((state) => state.pharmacyGroupStatistics.categoriesSelected)

  useEffect(() => {
    if (props.organization?.id) {
      const id = GuidHelper.toString(props.organization.id)
      props.dispatch(fetchUsers(id))
    }
  }, [props.organization])

  return props.loading ? (
    <LoaderSpinner />
  ) : (
    <div className={sectionContent}>
      <BlockFilters />
      <GlobalFilter />
      {props.permissions?.haveAdditionalFilters ? <AdditionalFilterbarForm {...props} /> : null}
      {!props.permissions?.haveAdditionalFilters || props.selectedBranchId ? (
        <>
          <TestComplitionChart />
          <TestComplitionByPharmacistChart />
          <CourseProgressByPharmacistChart />
          <PassedTestByPharmacistRatioChart />
          <TestComplitionByCourseChart />
          <AverageTestGradeChart />
          <AverageTotalLearningTimeChart />
          {props.permissions?.haveAdditionalFilters ? (
            <RegionalManagerPublicationByCategoryChart
              anotherOrganizationFilter={anotherOrganizationFilter}
              anotherInOwnOrganizationFilter={anotherInOwnOrganizationFilter}
              categoriesSelected={categoriesSelected}
              byAnotherInOwnOrganizationQueryKey="PublicationByAnotherPharmacyOrganizationBranch"
              byAnotherOrganizationQueryKey="PublicationByManufacturerBranch"
              byAnotherInOwnOrganizationCount="PublicationByAnotherPharmacyOrganizationBranch.count"
              byAnotherOrganizationCount="PublicationByManufacturerBranch.count"
              byAnotherInOwnOrganizationStartDate="PublicationByAnotherPharmacyOrganizationBranch.CourseStartMonth"
              byAnotherOrganizationStartDate="PublicationByManufacturerBranch.CourseStartMonth"
              orderAnotherInOwnOrganization={{
                'PublicationByAnotherPharmacyOrganizationBranch.courseStartDate': 'asc',
              }}
              orderAnotherOrganization={{ 'PublicationByManufacturerBranch.courseStartDate': 'asc' }}
              anotherInOwnOrganizationBranchIdSelector={'CourseWithOutFilters.pharmacyOrganizationBranchId'}
              anotherOrganizationBranchIdSelector={'CourseWithOutFilters.manufacturerBranchId'}
            />
          ) : (
            <PublicationByCategoryChart
              ownOrganizationFilter={ownOrganizationFilter}
              anotherOrganizationFilter={anotherOrganizationFilter}
              anotherInOwnOrganizationFilter={anotherInOwnOrganizationFilter}
              categoriesSelected={categoriesSelected}
              byOwnOrganizationQueryKey="PublicationByOwnPharmacyOrganizationBranch"
              byAnotherInOwnOrganizationQueryKey="PublicationByAnotherPharmacyOrganizationBranch"
              byAnotherOrganizationQueryKey="PublicationByManufacturerBranch"
              byOwnOrganizationCount="PublicationByOwnPharmacyOrganizationBranch.count"
              byAnotherInOwnOrganizationCount="PublicationByAnotherPharmacyOrganizationBranch.count"
              byAnotherOrganizationCount="PublicationByManufacturerBranch.count"
              byOwnOrganizationCreateDate="PublicationByOwnPharmacyOrganizationBranch.courseStartDate"
              byAnotherInOwnOrganizationStartDate="PublicationByAnotherPharmacyOrganizationBranch.CourseStartMonth"
              byAnotherOrganizationStartDate="PublicationByManufacturerBranch.CourseStartMonth"
              orderOwnOrganization={{ 'PublicationByOwnPharmacyOrganizationBranch.courseStartDate': 'asc' }}
              orderAnotherInOwnOrganization={{
                'PublicationByAnotherPharmacyOrganizationBranch.courseStartDate': 'asc',
              }}
              orderAnotherOrganization={{ 'PublicationByManufacturerBranch.courseStartDate': 'asc' }}
              anotherInOwnOrganizationBranchIdSelector={'CourseWithOutFilters.pharmacyOrganizationBranchId'}
              anotherOrganizationBranchIdSelector={'CourseWithOutFilters.manufacturerBranchId'}
              type={props.permissions?.haveAdditionalFilters ? 'regional-manager' : 'pharmacy'}
            />
          )}
        </>
      ) : null}
    </div>
  )
}

const mapStateToProps = (store: IAppState): ICourseStatisticsStateProps => {
  return {
    dispatch: store.dispatch,
    organization: store.pharmacy.organization,
    loading: store.pharmacyGroupStatistics.loading,
    branches: store.pharmacyGroupStatistics.branches,
    permissions: store.permissions?.permissions?.statistics,
    selectedBranchId: store.pharmacyGroupStatistics.branchId,
  }
}

export default connect<ICourseStatisticsStateProps, {}, ICourseStatisticsOwnProps, IAppState>(mapStateToProps)(
  PharmacyOrganizationStatistics
)
