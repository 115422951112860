// extracted by mini-css-extract-plugin
export var section = "CourseReviews-module--section--WBbQ_";
export var sectionNav = "CourseReviews-module--section-nav--v66En";
export var sectionContent = "CourseReviews-module--section-content--2_PWv";
export var header = "CourseReviews-module--header--3bnvY";
export var headerTitle = "CourseReviews-module--header-title--2iTH-";
export var headerWrapper = "CourseReviews-module--header-wrapper--3XiSY";
export var headerRating = "CourseReviews-module--header-rating--22gGc";
export var headerRatingInfo = "CourseReviews-module--header-rating-info--1C6Oz";
export var headerRatingText = "CourseReviews-module--header-rating-text--2YX2k";
export var paging = "CourseReviews-module--paging--2UIMc";
export var cardList = "CourseReviews-module--card-list--1fbtq";
export var cardItem = "CourseReviews-module--card-item--36-YD";