import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { IEmployee } from 'manufacturer-office/proto/models'
import Employee from 'pharmacy-office/components/PharmacyOffice/Employee/Employee'
import {
  BusinessTrainerList,
  DeleteRequestBisunessTrainer,
  IBusinessTrainer,
  IBusinessTrainerList,
  IDeleteRequestBisunessTrainer,
} from 'pharmacy-office/proto/models'

import { createAction, createReducer, Dispatch } from '@reduxjs/toolkit'
import { IResponseDto, ResponseDto } from 'shared/proto/models'

const url = 'pharmacy-office/business-trainers'

export interface IBusinessTrainersState {
  businessTrainersLoading: boolean
  businessTrainers: IBusinessTrainer[]
  businessTrainersCount: number
}

const initialState: IBusinessTrainersState = {
  businessTrainersLoading: true,
  businessTrainersCount: 0,
  businessTrainers: [],
}

export interface IBusinessTrainersRequest {
  pageIndex: number
  pageSize: number
  sortColumn: string
  sortDirection: string
  branchId: string
  searchQuery: string
}

export interface IBusinessTrainerCardRequest {
  pageIndex: number
  pageSize: number
  trainerId: string
  searchQuery: string
}

const SET_BUSINESS_TRAINERS = 'SET_BUSINESS_TRAINERS'
const setBusinessTrainers = createAction<IBusinessTrainerList>(SET_BUSINESS_TRAINERS)

const BUSINESS_TRAINERS_LOADING = 'BUSINESS_TRAINERS_LOADING'
const setBusinessTrainersLoading = createAction<boolean>(BUSINESS_TRAINERS_LOADING)

export const clearBusinessTrainers = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(setBusinessTrainers({ items: [], rowCount: 0 }))
  dispatch(setBusinessTrainersLoading(true))
}
export const loadBusinessTrainers = (options: IBusinessTrainersRequest) => async (
  dispatch: Dispatch
): Promise<void> => {
  dispatch(setBusinessTrainersLoading(true))

  const response = await ProtoClient.get<IBusinessTrainerList>(url, BusinessTrainerList, options)
  dispatch(setBusinessTrainers(response))
  dispatch(setBusinessTrainersLoading(false))
}

const GET_BUSINESS_TRAINER = 'GET_BUSINESS_TRAINER'
export const getBusinessTrainer = (employeeId: string) => async (dispatch: Dispatch): Promise<void> => {
  const response = await ProtoClient.get<IEmployee>('pharmacy-office/employee/get-employee', Employee, {
    id: employeeId,
  })

  dispatch({ type: GET_BUSINESS_TRAINER, payload: response })
  dispatch({ type: BUSINESS_TRAINERS_LOADING, payload: false })
}

const RESET_PROVISORS = 'RESET_PROVISORS'
export const resetEmployee = createAction(RESET_PROVISORS)

export const deleteBusinessTrainer = (branchId: Uint8Array, businessTrainerId: Uint8Array) => async (
  dispatch: Dispatch
) => {
  dispatch(setBusinessTrainersLoading(false))

  const data: IDeleteRequestBisunessTrainer = {
    branchId: branchId,
    trainerId: businessTrainerId,
  }

  const response = await ProtoClient.post<IResponseDto>(
    url + '/delete',
    data,
    DeleteRequestBisunessTrainer,
    ResponseDto
  )

  if (response.success) {
    dispatch(setBusinessTrainersLoading(false))
  }
}

const businessTrainersReducer = createReducer(initialState, {
  [SET_BUSINESS_TRAINERS]: (state, action: { payload: IBusinessTrainerList }) => {
    state.businessTrainers = action.payload.items
    state.businessTrainersCount = action.payload.rowCount
  },
  [BUSINESS_TRAINERS_LOADING]: (state, action: { payload: boolean }) => {
    state.businessTrainersLoading = action.payload
  },
})

export default businessTrainersReducer
