import { withPrefix } from 'gatsby'
import React, { useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import LoaderSpinner from '../../../shared/components/LoaderSpinner'
import NavigationTab, { IDataNavigationTab } from '../../../shared/components/NavigationTab/NavigationTab'
import PageInfoHeader, { IPageInfoHeader } from '../../../shared/components/PageInfoHeader'
import PageTitle from '../../../shared/components/PageTitle'
import { IUserPharmacyOrganization } from '../../proto/models'
import { getPharmacyOrganization } from '../../reducers/pharmacyReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'
import { section } from './PharmacyOffice.module.scss'

const url = 'pharmacy-office/organization'

interface IPharmacyOfficeOwnProps {
  path?: string
  children: React.ReactNode
}

interface IPharmacyOfficeStateProps {
  dispatch: AppDispatch
  organization: IUserPharmacyOrganization
}

type PharmacyOfficeProps = IPharmacyOfficeOwnProps & IPharmacyOfficeStateProps

const dataNavigation: IDataNavigationTab[] = [
  {
    id: 1,
    name: 'Группы',
    link: withPrefix(url),
  },
  {
    id: 2,
    name: 'Счет',
    link: withPrefix(`${url}/account`),
  },
]

const PharmacyOffice = (props: PharmacyOfficeProps) => {
  useEffect(() => {
    if (!props.organization) {
      props.dispatch(getPharmacyOrganization())
    }
  }, [])

  const dataInfoHeader: IPageInfoHeader[] = [
    {
      id: nextId(),
      title: 'Наименование',
      value: props.organization?.name,
    },
    {
      id: nextId(),
      title: 'Номер договора',
      value: props.organization?.contractCode,
    },
  ]

  return (
    <section className={section}>
      <PageTitle title="Аптечные сети" />
      {!props.organization ? (
        <LoaderSpinner />
      ) : (
        <>
          <PageInfoHeader data={dataInfoHeader} />
          <NavigationTab data={dataNavigation} />
          {props.children}
        </>
      )}
    </section>
  )
}

const mapStateToProps = (store: IAppState): IPharmacyOfficeStateProps => {
  return {
    dispatch: store.dispatch,
    organization: store.pharmacy.organization,
  }
}

export default connect<IPharmacyOfficeStateProps, {}, IPharmacyOfficeOwnProps, IAppState>(mapStateToProps)(
  PharmacyOffice
)
