// extracted by mini-css-extract-plugin
export var section = "PharmaciesGroup-module--section--1V5o2";
export var sectionContent = "PharmaciesGroup-module--section-content--dXdz8";
export var modal = "PharmaciesGroup-module--modal--KUgRq";
export var modalTitle = "PharmaciesGroup-module--modal-title--HJ0mt";
export var sectionButtons = "PharmaciesGroup-module--section-buttons--3P8bM";
export var tableCellNumber = "PharmaciesGroup-module--table-cell-number--1dEjx";
export var tableCellGroup = "PharmaciesGroup-module--table-cell-group--VYUNR";
export var tableCellBranchCount = "PharmaciesGroup-module--table-cell-branchCount--196dP";
export var tableCellButtons = "PharmaciesGroup-module--table-cell-buttons--2KgiO";
export var modalHeader = "PharmaciesGroup-module--modal-header--4NNBg";
export var modalHeaderRemove = "PharmaciesGroup-module--modal-header-remove--2Imne";
export var modalClose = "PharmaciesGroup-module--modal-close--IBW9n";
export var modalBlockGroup = "PharmaciesGroup-module--modal-block-group--11btP";
export var modalBlockButtons = "PharmaciesGroup-module--modal-block-buttons--1fENQ";
export var modalButton = "PharmaciesGroup-module--modal-button--3KZHL";