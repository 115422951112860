import { Step } from 'react-joyride'

export interface IApplicationsSelectors {
  applications: string
}

export const applicationsStepSelectors: IApplicationsSelectors = {
  applications: 'applications',
}

export const applicationsSteps: Array<Step> = [
  {
    target: `.${applicationsStepSelectors.applications}`,
    title: 'Заявки',
    content:
      'Это список провизоров, которые создали заявки на добавление в штат Вашей аптечной сети. Если провизор является вашим сотрудником, добавьте его в штат. Иначе отклоните заявку.',
    disableBeacon: true,
    placement: 'top',
    offset: 5,
    hideFooter: true,
  },
]
