import React from 'react'
import { connect } from 'react-redux'
import CloseButton from 'shared/components/Buttons/CloseButton'
import TextButton from 'shared/components/Buttons/TextButton'
import BizCategoriesFilter from 'shared/components/CourseStatistics/Filter/BizCategoriesFilter'
import DrugNamesFilter from 'shared/components/CourseStatistics/Filter/DrugNamesFilter'
import {
  filter,
  filterButton,
  filterButtonClose,
  filterHeader,
  filterIconFilter,
  filterInner,
  filterTitle,
  filterTitleText,
} from 'shared/components/CourseStatistics/Filter/Filter.module.scss'
import MedicalCategoriesFilter from 'shared/components/CourseStatistics/Filter/MedicalCategoriesFilter'
import IconFilter from 'shared/components/Icons/FilterIcon/FilterIcon'
import { IFilterSearch } from 'shared/proto/models'

import {
  changeFilters,
  setBusinessCategoriesAction,
  setDrugNamesAction,
  setFilterOpen,
  setMedicalCategoriesAction,
} from '../../../../../reducers/employeeStatisticsReducer'
import { AppDispatch, IAppState } from '../../../../../reducers/rootReducer'
import DetailFilter from './DetailFilter'
import DifficultyFilter from './DifficultyFilter'

const Filter = (props: FilterProps) => {
  const {
    open,
    businessCategories,
    setBusinessCategories,
    medicalCategories,
    setMedicalCategories,
    drugNames,
    setDrugNames,
  } = props

  if (!open) {
    return null
  }

  return (
    <div className={filter}>
      <header className={filterHeader}>
        <h3 className={filterTitle}>
          <span className={filterIconFilter}>
            <IconFilter />
          </span>
          <span className={filterTitleText}>Фильтр графиков</span>
        </h3>
        <div className={filterButtonClose}>
          <CloseButton onClick={() => props.setFilterOpen()} />
        </div>
      </header>
      <div className={filterInner}>
        <DetailFilter />
        <DifficultyFilter />
        <BizCategoriesFilter {...{ businessCategories, setBusinessCategories }} />
        <MedicalCategoriesFilter {...{ medicalCategories, setMedicalCategories }} />
        <DrugNamesFilter {...{ drugNames, setDrugNames }} />
      </div>
      <div className={filterButton}>
        <TextButton text="Отфильтровать" onClick={props.changeFilters} />
      </div>
    </div>
  )
}

type FilterStateProps = {
  open: boolean
  businessCategories: IFilterSearch[]
  medicalCategories: IFilterSearch[]
  drugNames: IFilterSearch[]
}

type FilterDispatchProps = {
  setFilterOpen: () => void
  setBusinessCategories: (values: IFilterSearch[]) => void
  setMedicalCategories: (values: IFilterSearch[]) => void
  setDrugNames: (values: IFilterSearch[]) => void
  changeFilters: () => void
}

type FilterProps = FilterStateProps & FilterDispatchProps

const mapStateToProps = (store: IAppState): FilterStateProps => {
  return {
    open: store.employeeStatistics.filterOpen,
    businessCategories: store.employeeStatistics.businessCategories,
    medicalCategories: store.employeeStatistics.medicalCategories,
    drugNames: store.employeeStatistics.drugNames,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch): FilterDispatchProps => {
  return {
    setFilterOpen: () => {
      dispatch(setFilterOpen())
    },
    setBusinessCategories: (values: IFilterSearch[]) => {
      dispatch(setBusinessCategoriesAction(values))
    },
    setMedicalCategories: (values: IFilterSearch[]) => {
      dispatch(setMedicalCategoriesAction(values))
    },
    setDrugNames: (values: IFilterSearch[]) => {
      dispatch(setDrugNamesAction(values))
    },
    changeFilters: () => {
      dispatch(changeFilters())
    },
  }
}

export default connect<FilterStateProps, FilterDispatchProps, unknown, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(Filter)
