import { withPrefix } from 'gatsby'
import React, { ReactElement, useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import { DateHelper } from '../../../external/rp.ui/helpers/DateHelper'
import Breadcrumbs, { IBreadcrumbs } from '../../../shared/components/Breadcrumbs'
import LoaderSpinner from '../../../shared/components/LoaderSpinner'
import NavigationTab, { IDataNavigationTab } from '../../../shared/components/NavigationTab/NavigationTab'
import PageInfoHeader, { IPageInfoHeader } from '../../../shared/components/PageInfoHeader'
import PageTitle from '../../../shared/components/PageTitle'
import { ICourseInfoForm } from '../../../shared/proto/models'
import { loadCourseInfo, resetState } from '../../../shared/reducers/courseInfo/courseInfoReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'
import { section } from './CourseInfoPage.module.scss'

const url = 'pharmacy-office'

interface IEmployeeCourseInfoPageOwnProps {
  path: string
  courseId?: string
  groupId?: string
  pharmacyId?: string
  employeeId?: string
  children: React.ReactNode
  employees?: boolean
}

interface IEmployeeCourseInfoPageStateProps {
  dispatch: AppDispatch
  course: ICourseInfoForm
  imagePath: string
}

type EmployeeCourseInfoPageProps = IEmployeeCourseInfoPageOwnProps & IEmployeeCourseInfoPageStateProps

const getDataNavigation = (props: EmployeeCourseInfoPageProps): IDataNavigationTab[] => {
  let infoLink = `${url}/organization/group/${props.groupId}/branches/${props.pharmacyId}/employee/${props.employeeId}/course/${props.courseId}/info`
  let reviews = `${url}/organization/group/${props.groupId}/branches/${props.pharmacyId}/employee/${props.employeeId}/course/${props.courseId}/reviews`

  if (props.employees) {
    infoLink = `${url}/statistics/pharmacist/${props.employeeId}/course/${props.courseId}/info`
    reviews = `${url}/statistics/pharmacist/${props.employeeId}/course/${props.courseId}/reviews`
  }

  const navigationTabs = [
    {
      id: nextId(),
      name: 'Информация',
      link: withPrefix(infoLink),
    },
    {
      id: nextId(),
      name: 'Отзывы',
      link: withPrefix(reviews),
    },
  ]

  if (props.course.canViewStatistics) {
    let statisticsLink = `${url}/organization/group/${props.groupId}/branches/${props.pharmacyId}/employee/${props.employeeId}/course/${props.courseId}/statistics`

    if (props.employees) {
      statisticsLink = `${url}/statistics/pharmacist/${props.employeeId}/course/${props.courseId}/statistics`
    }

    navigationTabs.push({
      id: nextId(),
      name: 'Статистика',
      link: withPrefix(statisticsLink),
    })
  }

  return navigationTabs
}

const getDataBreadcrumbs = (props: EmployeeCourseInfoPageProps): IBreadcrumbs[] => {
  return props.employees
    ? [
        {
          title: 'Статистика',
          link: withPrefix(`${url}/statistics`),
        },
        {
          title: 'Курсы',
          link: withPrefix(`${url}/statistics/pharmacist/${props.employeeId}/courses`),
        },
        {
          title: 'Информация о курсе',
          currSection: true,
        },
      ]
    : [
        {
          title: 'Аптечные сети',
          link: withPrefix(`${url}/organization`),
        },
        {
          title: 'Группа',
          link: withPrefix(`${url}/organization/group/${props.groupId}/branches`),
        },
        {
          title: 'Филиал',
          link: withPrefix(`${url}/organization/group/${props.groupId}/branches/${props.pharmacyId}`),
        },
        {
          title: 'Курсы',
          link: withPrefix(
            `${url}/organization/group/${props.groupId}/branches/${props.pharmacyId}/employee/${props.employeeId}/courses`
          ),
        },
        {
          title: 'Информация о курсе',
          currSection: true,
        },
      ]
}

const getDataPageInfoHeader = (props: EmployeeCourseInfoPageProps): IPageInfoHeader[] => {
  return [
    {
      id: nextId(),
      title: 'Наименование',
      value: props.course.name,
    },
    {
      id: nextId(),
      title: 'Дата создания курса',
      value: props.course.createDate
        ? DateHelper.fromNumber(props.course.createDate as number).format('DD.MM.YYYY')
        : '',
    },
    {
      id: nextId(),
      title: 'Язык',
      value: props.course.language,
    },
    {
      id: nextId(),
      title: 'Автор',
      value: props.course.author,
    },
    {
      id: nextId(),
      title: 'Количество публикаций',
      value: `${props.course.publicationCount}`,
    },
    {
      id: nextId(),
      title: 'Вопросы',
      value: `${props.course.questionCount}`,
    },
  ]
}

const EmployeeCourseInfoPage = (props: EmployeeCourseInfoPageProps): ReactElement<EmployeeCourseInfoPageProps> => {
  useEffect(() => {
    //TODO: Переименовать courseId в publicationId
    const publicationId = props.courseId

    props.dispatch(loadCourseInfo(publicationId, 'pharmacy-office/employee/get-course/'))

    return () => {
      props.dispatch(resetState())
    }
  }, [])

  return !props.course ? (
    <LoaderSpinner />
  ) : (
    <section className={section}>
      <Breadcrumbs list={getDataBreadcrumbs(props)} />
      <PageTitle title="Информация о курсе" />
      <PageInfoHeader data={getDataPageInfoHeader(props)} image={props.imagePath} />
      <NavigationTab data={getDataNavigation(props)} />
      {props.children}
    </section>
  )
}

const mapStateToProps = (store: IAppState): IEmployeeCourseInfoPageStateProps => {
  return {
    dispatch: store.dispatch,
    course: store.courseInfo.course,
    imagePath: store.courseInfo.imagePath,
  }
}

export default connect<IEmployeeCourseInfoPageStateProps, {}, IEmployeeCourseInfoPageOwnProps, IAppState>(
  mapStateToProps
)(EmployeeCourseInfoPage)
