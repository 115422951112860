import {
  failedKey,
  firstTryKey,
  secondTryKey,
  thirdTryKey,
} from '../../../../reducers/pharmacyOrganizationStatisticsReducer'

export const barNames = (key: string): string => {
  switch (key) {
    case firstTryKey:
      return 'Сданы с 1-й попытки'
    case secondTryKey:
      return 'Сданы со 2-й попытки'
    case thirdTryKey:
      return 'Сданы с 3-й попытки'
    case failedKey:
      return 'Не сданы'
    default:
      return ''
  }
}

export const barСolor = (key: string): string => {
  switch (key) {
    case firstTryKey:
      return '#7955A9'
    case secondTryKey:
      return '#32C9D1'
    case thirdTryKey:
      return '#FEC213'
    case failedKey:
      return '#EE5787'
    default:
      return ''
  }
}

export const thirtyFiveCountDataChartThreshold = 35

const seventyCountDataChartThreshold = 70

export const getChartWidth = (data: any): string => data.length > seventyCountDataChartThreshold ? '200%' : '99%'
