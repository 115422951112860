import { withPrefix } from 'gatsby'
import React, { ReactElement, useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import Breadcrumbs, { IBreadcrumbs } from '../../../../shared/components/Breadcrumbs'
import LoaderSpinner from '../../../../shared/components/LoaderSpinner'
import PageInfoHeader, { IPageInfoHeader } from '../../../../shared/components/PageInfoHeader'
import PageTitle from '../../../../shared/components/PageTitle'
import { IPharmacyGroupInformation } from '../../../proto/models'
import { getGroupInformation, resetGroupInformation } from '../../../reducers/pharmacyReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import { section } from './Group.module.scss'

const url = 'pharmacy-office/organization'

interface IGroupOwnProps {
  groupId?: string
  path?: string
  children: React.ReactNode
}

interface IGroupStateProps {
  dispatch: AppDispatch
  info: IPharmacyGroupInformation
  loadingInfo: boolean
}

type GroupProps = IGroupOwnProps & IGroupStateProps

const Group = (props: GroupProps): ReactElement<GroupProps> => {
  const dataInfoHeader: IPageInfoHeader[] = [
    {
      id: nextId(),
      title: 'Группа',
      value: props.info?.groupName,
    },
    {
      id: nextId(),
      title: 'Наименование аптечной сети',
      value: props.info?.name,
    },
    {
      id: nextId(),
      title: 'Филиалов в группе',
      value: String(props.info?.branchCount),
    },
  ]

  const dataBreadcrumbs: IBreadcrumbs[] = [
    {
      title: 'Аптечные сети',
      link: withPrefix(url),
    },
    {
      title: 'Информация о группе',
      currSection: true,
    },
  ]

  useEffect(() => {
    props.dispatch(getGroupInformation(props.groupId))

    return () => {
      props.dispatch(resetGroupInformation())
    }
  }, [props.groupId])

  return (
    <section className={section}>
      <Breadcrumbs list={dataBreadcrumbs} />
      <PageTitle title="Информация о группе" />
      {props.loadingInfo ? (
        <LoaderSpinner />
      ) : (
        <>
          <PageInfoHeader data={dataInfoHeader} />
          {props.children}
        </>
      )}
    </section>
  )
}

const mapStateToProps = (store: IAppState): IGroupStateProps => {
  return {
    dispatch: store.dispatch,
    info: store.pharmacy.group.info,
    loadingInfo: store.pharmacy.group.loadingInfo,
  }
}

export default connect<IGroupStateProps, {}, IGroupOwnProps, IAppState>(mapStateToProps)(Group)
