import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Explanations from 'shared/components/Explanations'
import {
  explanationsItem,
  explanationsList,
  explanationsParagraph,
  explanationsText,
} from 'shared/components/Explanations/Explanations.module.scss'
import CubeQueryRender, { IChartComponentProps, renderChart } from 'shared/components/QueryRender/CubeQueryRender'
import CustomBarCursor from 'shared/components/Recharts/CustomBarCursor'
import CustomLegend from 'shared/components/Recharts/CustomLegend'
import CustomTooltip from 'shared/components/Recharts/CustomTooltip'

import { ChartPivotRow, Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import {
  failedKey,
  firstTryKey,
  IChartGridData,
  secondTryKey,
  thirdTryKey,
} from '../../../../reducers/pharmacyOrganizationStatisticsReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import { blockChart, blockChartGraph, blockChartTitle, chart } from './BlockCharts.module.scss'
import { barNames, barСolor, getChartWidth, thresholdValue } from './utils'

const barSize = 80
const barSizeBigData = 10
const countBarStyle = 6
const tooltipColor = '#201F25'

const round = (value: number, total: number) => Math.round(((value * 100) / total + Number.EPSILON) * 100) / 100

const successfulAttemptsKey = 'successful'
const failedAttemptsKey = 'failed'
const timedOutKey = 'timedOut'

const courseName = 'Course.name'

const successfulCount = 'SuccessfullCourseAttemptByPharmacyOrganization.count'
const successfulTryNumber = 'SuccessfullCourseAttemptByPharmacyOrganization.successfullTryNumber'
const successfulCourseId = 'SuccessfullCourseAttemptByPharmacyOrganization.courseId'

const failedCount = 'FailedCourseAttemptByPharmacyOrganization.count'
const failedCourseId = 'FailedCourseAttemptByPharmacyOrganization.courseId'

const timedOutCount = 'TimedOutCourseAttemptByOrganization.count'
const timedOutCourseId = 'TimedOutCourseAttemptByOrganization.courseId'

const labelFormatter = (label: React.ReactText) => {
  return label != 0 ? `${label}%` : ''
}

const applyLabelToBars = (
  course: { id: string; name: string },
  successfullPivots: ChartPivotRow[],
  failedPivots: ChartPivotRow[],
  timedOutPivots: ChartPivotRow[]
): IChartGridData => {
  const { id, name } = course
  const filteredSuccessfullPivots = successfullPivots.filter((p) => p.xValues[1] === id)
  const filteredFailedPivots = failedPivots.filter((p) => p.xValues[0] === id)
  const filteredTimedOutPivots = timedOutPivots.filter((p) => p.xValues[0] === id)
  const total =
    filteredSuccessfullPivots.reduce((prev, curr) => prev + curr[successfulCount], 0) +
    filteredFailedPivots.reduce((prev, curr) => prev + curr[failedCount], 0) +
    filteredTimedOutPivots.reduce((prev, curr) => prev + curr[timedOutCount], 0)
  const preparedData = {
    [firstTryKey]: round(
      filteredSuccessfullPivots.find((v) => v.x === `${firstTryKey},${id},${name}`)?.[successfulCount] ?? 0,
      total
    ),
    [secondTryKey]: round(
      filteredSuccessfullPivots.find((v) => v.x === `${secondTryKey},${id},${name}`)?.[successfulCount] ?? 0,
      total
    ),
    [thirdTryKey]: round(
      filteredSuccessfullPivots.find((v) => v.x === `${thirdTryKey},${id},${name}`)?.[successfulCount] ?? 0,
      total
    ),
    [failedKey]: round(
      (filteredFailedPivots.find((v) => v.x === `${id},${name}`)?.[failedCount] ?? 0) +
        (filteredTimedOutPivots.find((v) => v.x === `${id},${name}`)?.[timedOutCount] ?? 0),
      total
    ),
    ['name']: name,
  }
  return preparedData
}

const getData = (set: { [key: string]: ResultSet }) => {
  const successfulPivots = set[successfulAttemptsKey].chartPivot({
    x: [successfulTryNumber, successfulCourseId, courseName],
    y: [successfulCount],
  })
  const failedPivots = set[failedAttemptsKey].chartPivot({
    x: [failedCourseId, courseName],
    y: [failedCount],
  })
  const timedOutPivots = set[timedOutKey].chartPivot({
    x: [timedOutCourseId, courseName],
    y: [timedOutCount],
  })

  const pairs = successfulPivots
    .map((r) => ({ id: r.xValues[1], name: r.xValues[2] }))
    .concat(failedPivots.map((r) => ({ id: r.xValues[0], name: r.xValues[1] })))
    .concat(timedOutPivots.map((r) => ({ id: r.xValues[0], name: r.xValues[1] })))

  const uniquePairs = pairs.filter((item, i, a) => a.findIndex((v) => v.id == item.id) === i)

  return uniquePairs.map((pair) => applyLabelToBars(pair, successfulPivots, failedPivots, timedOutPivots))
}

const barRender = ({ resultSet }: IBarRenderProps) => {
  const data = getData(resultSet as { [key: string]: ResultSet })
  const [show, setShow] = useState<string>(null)
  const memoData = React.useMemo(() => data, [data])
  const barData = [firstTryKey, secondTryKey, thirdTryKey, failedKey]

  const legendClickHandler = (dataKey: string) => {
    if (show === dataKey) {
      setShow(null)
    } else {
      setShow(dataKey)
    }
  }

  const width = getChartWidth(data)

  return (
    memoData && (
      <ResponsiveContainer width={width} height="100%">
        <BarChart data={memoData} margin={{ top: 20 }}>
          <YAxis
            key="YAxis"
            axisLine={false}
            tickLine={false}
            tickFormatter={labelFormatter}
            domain={[0, 100]}
            ticks={[0, 25, 50, 75, 100]}
            width={40}
          />
          <XAxis hide={true} dataKey="name" />
          <CartesianGrid strokeDasharray="5 5" horizontal={true} vertical={false} stroke="#ACB2BD" />
          {barData.map((item, index) => {
            return (
              <Bar
                hide={show !== null && show !== item}
                key={`bar-${index}`}
                dataKey={item}
                stackId="a"
                name={barNames(item)}
                fill={barСolor(item)}
                minPointSize={3}
                barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
                background={show === item && { fill: '#eee' }}
              ></Bar>
            )
          })}
          <Tooltip cursor={<CustomBarCursor customWidth={2} color={tooltipColor} />} content={<CustomTooltip />} />
          <Legend
            wrapperStyle={{ bottom: '0px' }}
            height={35}
            content={<CustomLegend barData={barData} onClick={legendClickHandler} />}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  )
}

type IBarRenderProps = IChartComponentProps

const chartRender = (props: QueryRendererRenderProps) => (
  <div className={chart}>
    {renderChart(barRender)(props)}
  </div>
)

const VisibleExplanationsContent = () => {
  return <p className={explanationsParagraph}>График отражает данные только по&nbsp;курсам Вашей аптечной сети.</p>
}

const ExplanationsContent = () => {
  return (
    <>
      <VisibleExplanationsContent />
      <p className={explanationsParagraph}>График позволяет увидеть как провизоры проходят конкретный курс.</p>
      <ul className={explanationsList}>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Сданы с&nbsp;1&nbsp;попытки&nbsp;&#8210; %&nbsp;провизоров, сдавших тест курса с&nbsp;первой попытки
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Сданы с&nbsp;2&nbsp;попытки&nbsp;&#8210; %&nbsp;провизоров, сдавших тест курса со&nbsp;второй попытки
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Сданы с&nbsp;3&nbsp;попытки&nbsp;&#8210; %&nbsp;провизоров, сдавших тест курса с&nbsp;третьей попытки
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Не&nbsp;сданы&nbsp;&#8210; %&nbsp;провизоров, сдавших, которые не&nbsp;смогли сдать тест курса после трех
            попыток
          </span>
        </li>
      </ul>
    </>
  )
}

const TestComplitionChart = (props: TestComplitionChartChartProps) => {
  return (
    <div className={blockChart}>
      <h2 className={blockChartTitle}>Рейтинг курсов</h2>
      <div className={blockChartGraph}>
        <CubeQueryRender
          queries={{
            [successfulAttemptsKey]: {
              measures: [successfulCount],
              dimensions: [successfulTryNumber, successfulCourseId, courseName],
              filters: props.successfullAttmptsFilters,
            },
            [failedAttemptsKey]: {
              measures: [failedCount],
              dimensions: [failedCourseId, courseName],
              filters: props.failedAttemptsFilters,
            },
            [timedOutKey]: {
              measures: [timedOutCount],
              dimensions: [timedOutCourseId, courseName],
              filters: props.timedOutAttemptsFilters,
            },
          }}
          render={chartRender}
        />
      </div>

      <Explanations content={<ExplanationsContent />} visibleContent={<VisibleExplanationsContent />} />
    </div>
  )
}

interface ITestComplitionChartStateProps {
  dispatch: AppDispatch
  startDate: DateTime
  endDate: DateTime
  successfullAttmptsFilters: Filter[]
  failedAttemptsFilters: Filter[]
  timedOutAttemptsFilters: Filter[]
}

type TestComplitionChartChartProps = {} & ITestComplitionChartStateProps

const mapStateToProps = (store: IAppState): ITestComplitionChartStateProps => {
  return {
    dispatch: store.dispatch,
    startDate: store.pharmacyGroupStatistics.startDate,
    endDate: store.pharmacyGroupStatistics.endDate,
    successfullAttmptsFilters: store.pharmacyGroupStatistics.successfullAttmptsByCourseFilters,
    failedAttemptsFilters: store.pharmacyGroupStatistics.failedAttemptsByCourseFilters,
    timedOutAttemptsFilters: store.pharmacyGroupStatistics.timedOutAttemptsByCourseFilters,
  }
}

export default connect<ITestComplitionChartStateProps, {}, {}, IAppState>(mapStateToProps)(TestComplitionChart)
