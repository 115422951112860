import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import Long from 'long'
import { DateTime } from 'luxon'
import { FetchDataProps, TableFilters } from 'shared/components/EmployeeStatistics/Tables/EmployeeCourseStatisticsTable'
import { EDifficulty, EPublicationStatus, IFilterSearch } from 'shared/proto/models'

import { Filter } from '@cubejs-client/core'
import { createAction, createAsyncThunk, createReducer } from '@reduxjs/toolkit'

import { GuidHelper } from '../../external/rp.ui/helpers/GuidHelper'
import {
  EDetail,
  EDifficultyCourseStatistics,
  EmployeeCourseStatisticsList,
  EmployeeCourseStatisticsRequest,
  IEmployeeCourseStatistics,
  IEmployeeCourseStatisticsList,
  IEmployeeCourseStatisticsRequest,
} from '../proto/models'
import { AppDispatch, IAppState } from './rootReducer'

const startDate = DateTime.local().minus({ days: 30 })
const endDate = DateTime.local()

const apiMethodUrl = 'pharmacy-office/employee-course/get-courses'

export type EmployeeStatisticFiltersState = {
  startDate: DateTime
  endDate: DateTime
  difficulty: EDifficulty[]
  detail: EDetail
  filterOpen: boolean
  loading: boolean
  fetchedData: IEmployeeCourseStatistics[]
  totalRowCount: number
  businessCategories: IFilterSearch[]
  medicalCategories: IFilterSearch[]
  drugNames: IFilterSearch[]
  successfullAttemptsFilters: Filter[]
  failedAttemptsFilter: Filter[]
  timedOutAttemptsFilter: Filter[]
  totalFilters: Filter[]
  assignCoursesFilters: Filter[]
  tableFilters: TableFilters
  publicationInProgressFilters: Filter[]
  publicationStudiedFilters: Filter[]
  averageResultFilter: Filter[]
  courseProgressFilter: Filter[]
}

const initialState: EmployeeStatisticFiltersState = {
  startDate: startDate,
  endDate: endDate,
  difficulty: [],
  detail: EDetail.All,
  filterOpen: false,
  loading: true,
  fetchedData: [],
  totalRowCount: 0,
  businessCategories: [],
  medicalCategories: [],
  drugNames: [],
  successfullAttemptsFilters: [],
  failedAttemptsFilter: [],
  timedOutAttemptsFilter: [],
  totalFilters: [],
  assignCoursesFilters: [],
  tableFilters: null,
  publicationInProgressFilters: [],
  publicationStudiedFilters: [],
  averageResultFilter: [],
  courseProgressFilter: []
}

const getDateStart = (startDate: DateTime): string =>
  startDate.startOf('day').toISO({ includeOffset: false, suppressMilliseconds: true })

const getDateEnd = (endDate: DateTime): string =>
  endDate.endOf('day').toISO({ includeOffset: false, suppressMilliseconds: true })

export const getDateTimeRange = (startDate: DateTime, endDate: DateTime): string[] => [
  getDateStart(startDate),
  getDateEnd(endDate),
]

const getDifficultyFilter = (difficulty: EDifficulty[]): Record<symbol, Filter>[] => {
  return difficulty.map((value) => ({
    dimension: 'Publication.difficulty',
    operator: 'equals',
    values: [value.toString()],
  }))
}

const getAverageResultFilter = (props: {
  startDate: DateTime
  endDate: DateTime
  difficulty: EDifficulty[]
  employeeId: string
}): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'CourseTestSession.completionDate',
      operator: 'inDateRange',
      values: getDateTimeRange(props.startDate, props.endDate),
    },
    {
      dimension: 'CourseTestSession.userId',
      operator: 'equals',
      values: [props.employeeId],
    },
  ]

  return filters as Filter[]
}

const getSuccessfullAttemptsFilters = (props: {
  startDate: DateTime
  endDate: DateTime
  difficulty: EDifficulty[]
  employeeId: string
  detail: EDetail
}): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'SuccessfullCourseAttempt.successfullTryDate',
      operator: 'inDateRange',
      values: getDateTimeRange(props.startDate, props.endDate),
    },
    {
      dimension: 'SuccessfullCourseAttempt.successfullTryNumber',
      operator: 'lte',
      values: ['3'],
    },
    {
      dimension: 'SuccessfullCourseAttempt.userId',
      operator: 'equals',
      values: [props.employeeId],
    },
  ]

  if (props.difficulty.length === 1) {
    filters.push({
      dimension: 'Publication.difficulty',
      operator: 'equals',
      values: [props.difficulty.toString()],
    })
  } else if (props.difficulty.length > 1) {
    filters.push({
      or: getDifficultyFilter(props.difficulty),
    })
  }

  return filters as Filter[]
}

const getAssignCoursesFilters = (props: { employeeId: string }): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'PharmacistCourseWithOutFilters.userId',
      operator: 'equals',
      values: [props.employeeId],
    },
    {
      dimension: 'Publication.status',
      operator: 'equals',
      values: [`${EPublicationStatus.Active}`, `${EPublicationStatus.Inactive}`],
    },
  ]

  return filters as Filter[]
}

const getFailedAttemptsFilter = (props: {
  startDate: DateTime
  endDate: DateTime
  difficulty: EDifficulty[]
  employeeId: string
}): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'FailedCourseAttempt.failDate',
      operator: 'inDateRange',
      values: getDateTimeRange(props.startDate, props.endDate),
    },
    {
      dimension: 'FailedCourseAttempt.userId',
      operator: 'equals',
      values: [props.employeeId],
    },
  ]

  if (props.difficulty.length === 1) {
    filters.push({
      dimension: 'Publication.difficulty',
      operator: 'equals',
      values: [props.difficulty.toString()],
    })
  } else if (props.difficulty.length > 1) {
    filters.push({
      or: getDifficultyFilter(props.difficulty),
    })
  }

  return filters as Filter[]
}

const getTimedOutAttemptsFilter = (props: {
  startDate: DateTime
  endDate: DateTime
  difficulty: EDifficulty[]
  employeeId: string
}): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'TimedOutCourseAttempt.courseEndDate',
      operator: 'inDateRange',
      values: getDateTimeRange(props.startDate, props.endDate),
    },
    {
      dimension: 'TimedOutCourseAttempt.userId',
      operator: 'equals',
      values: [props.employeeId],
    },
  ]

  if (props.difficulty.length === 1) {
    filters.push({
      dimension: 'Publication.difficulty',
      operator: 'equals',
      values: [props.difficulty.toString()],
    })
  } else if (props.difficulty.length > 1) {
    filters.push({
      or: getDifficultyFilter(props.difficulty),
    })
  }

  return filters as Filter[]
}

const getPublicationStudiedFilters = (props: {
  startDate: DateTime
  endDate: DateTime
  difficulty: EDifficulty[]
  employeeId: string
}): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'StudentPublicationStatus.userId',
      operator: 'equals',
      values: [props.employeeId],
    },
    {
      dimension: 'CourseTestSession.userId',
      operator: 'notSet',
    },
    {
      dimension: 'StudentPublicationStatusHistory.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(props.startDate, props.endDate),
    },
    {
      dimension: 'PublicationHistory.status',
      operator: 'equals',
      values: [`${EPublicationStatus.Active}`],
    },
  ]

  if (props.difficulty.length === 1) {
    filters.push({
      dimension: 'Publication.difficulty',
      operator: 'equals',
      values: [props.difficulty.toString()],
    })
  } else if (props.difficulty.length > 1) {
    filters.push({
      or: getDifficultyFilter(props.difficulty),
    })
  }

  return filters as Filter[]
}

const getPublicationInProgressFilters = (props: {
  startDate: DateTime
  endDate: DateTime
  difficulty: EDifficulty[]
  employeeId: string
}): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'StudentPublicationStatus.userId',
      operator: 'equals',
      values: [props.employeeId],
    },
    {
      dimension: 'CourseTestSession.userId',
      operator: 'equals',
      values: [props.employeeId],
    },
    {
      dimension: 'StudentPublicationStatusHistory.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(props.startDate, props.endDate),
    },
    {
      dimension: 'PublicationHistory.status',
      operator: 'equals',
      values: [`${EPublicationStatus.Active}`],
    },
  ]

  if (props.difficulty.length === 1) {
    filters.push({
      dimension: 'Publication.difficulty',
      operator: 'equals',
      values: [props.difficulty.toString()],
    })
  } else if (props.difficulty.length > 1) {
    filters.push({
      or: getDifficultyFilter(props.difficulty),
    })
  }

  return filters as Filter[]
}

const getFilters = (props: {
  startDate: DateTime
  endDate: DateTime
  difficulty: EDifficulty[]
  employeeId: string
  detail: EDetail
}): Filter[] => {
  const filters: Record<symbol, Filter>[] = [
    {
      dimension: 'StudentPublicationStatus.userId',
      operator: 'equals',
      values: [props.employeeId],
    },
    {
      dimension: 'StudentPublicationStatusHistory.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(props.startDate, props.endDate),
    },
  ]

  if (props.difficulty.length === 1) {
    filters.push({
      dimension: 'Publication.difficulty',
      operator: 'equals',
      values: [props.difficulty.toString()],
    })
  } else if (props.difficulty.length > 1) {
    filters.push({
      or: getDifficultyFilter(props.difficulty),
    })
  }

  return filters as Filter[]
}

const getCourseProgressFilter = (props: {
  startDate: DateTime
  endDate: DateTime
  difficulty: EDifficulty[]
  employeeId: string
}): Filter[] => {
  const filters: Filter[] = [
    {
      dimension: 'CourseProgress.userId',
      operator: 'equals',
      values: [props.employeeId],
    },
    {
      dimension: 'CourseProgress.createDate',
      operator: 'beforeDate',
      values: [getDateEnd(props.endDate)],
    },
    {
      dimension: 'CourseProgress.endDate',
      operator: 'afterDate',
      values: [getDateStart(props.startDate)],
    },
  ]

  if (props.difficulty.length === 1) {
    filters.push({
      dimension: 'Publication.difficulty',
      operator: 'equals',
      values: [props.difficulty.toString()],
    })
  } else if (props.difficulty.length > 1) {
    filters.push({
      or: getDifficultyFilter(props.difficulty),
    })
  }

  return filters as Filter[]
}

const CHANGE_FILTERS = 'EMPLOYEE_STATISTICS_CHANGE_FILTERS'
export const changeFilters = () => (dispatch: AppDispatch, getState: () => IAppState): void => {
  const state = getState()

  const {
    startDate,
    endDate,
    difficulty,
    businessCategories,
    medicalCategories,
    drugNames,
    detail,
  } = state.employeeStatistics
  const filters: Filter[] = []

  if (medicalCategories?.length > 0) {
    filters.push({
      dimension: 'CourseNosologyAttachedMedicalCategory.medicalCategoryId',
      operator: 'equals',
      values: medicalCategories.map((v) => v.id),
    })
  }

  if (businessCategories?.length > 0) {
    filters.push({
      dimension: 'CourseBusinessCategory.businessCategoryId',
      operator: 'equals',
      values: businessCategories.map((v) => v.id),
    })
  }

  if (drugNames?.length > 0) {
    filters.push({
      dimension: 'CourseDrug.drugNameId',
      operator: 'equals',
      values: drugNames.map((v) => v.id),
    })
  }

  if(detail === EDetail.PharmacyNetwork) {
    const userId = state.profile.profileInfo.id

    filters.push({
      dimension: 'UserOrganizationRegionPresence.userId',
      operator: 'equals',
      values: [GuidHelper.toString(userId)],
    })
  }

  const filterProps = { startDate, endDate, difficulty, employeeId: state.pharmacy.group.employees.employee.id, detail }
  const payload = {
    detail: filterProps.detail,
    assignCoursesFilters: filters.concat(getAssignCoursesFilters({ employeeId: filterProps.employeeId })),
    successfullAttemptsFilters: filters.concat(getSuccessfullAttemptsFilters(filterProps)),
    failedAttemptsFilter: filters.concat(getFailedAttemptsFilter(filterProps)),
    timedOutAttemptsFilter: filters.concat(getTimedOutAttemptsFilter(filterProps)),
    totalFilters: filters.concat(getFilters(filterProps)),
    tableFilters: {
      startDate,
      endDate,
      detail: filterProps.detail,
      difficulty,
      businessCategories,
      medicalCategories,
      drugNames,
    },
    publicationInProgressFilters: filters.concat(getPublicationInProgressFilters(filterProps)),
    publicationStudiedFilters: filters.concat(getPublicationStudiedFilters(filterProps)),
    averageResultFilter: filters.concat(getAverageResultFilter(filterProps)),
    courseProgressFilter: filters.concat(getCourseProgressFilter(filterProps)),
  }

  dispatch({ type: CHANGE_FILTERS, payload: payload })
}

const SET_FILTER_OPEN = 'EMPLOYEE_STATISTICS_SET_FILTER_OPEN'
export const setFilterOpen = createAction(SET_FILTER_OPEN)

const CHANGE_START_DATE = 'EMPLOYEE_STATISTICS_CHANGE_START_DATE'
const changeStartDateValue = createAction<DateTime>(CHANGE_START_DATE)
export const changeStartDate = (value: DateTime) => (dispatch: AppDispatch): void => {
  dispatch(changeStartDateValue(value))
  dispatch(changeFilters())
}

const CHANGE_END_DATE = 'EMPLOYEE_STATISTICS_CHANGE_END_DATE'
const changeEndDateValue = createAction<DateTime>(CHANGE_END_DATE)
export const changeEndDate = (value: DateTime) => (dispatch: AppDispatch): void => {
  dispatch(changeEndDateValue(value))
  dispatch(changeFilters())
}

const CHANGE_DETAIL = 'CHANGE_DETAIL'
export const changeDetail = createAction<EDetail>(CHANGE_DETAIL)

const CHANGE_COURSE_DIFFICULTY = 'EMPLOYEE_STATISTICS_CHANGE_COURSE_DIFFICULTY'
export const changeDifficulty = createAction<EDifficulty[]>(CHANGE_COURSE_DIFFICULTY)

const EMPLOYEE_COURSE_STATISTICS_FETCH_TABLE = 'EMPLOYEE_COURSE_STATISTICS_FETCH_TABLE'
export const employeeCourseStatisticsFetchTable = createAsyncThunk<IEmployeeCourseStatisticsList, FetchDataProps>(
  EMPLOYEE_COURSE_STATISTICS_FETCH_TABLE,
  async (options) => {
    const { pageIndex, pageSize, employeeId, tableFilters, startDate, endDate, detail } = options

    const paging: IEmployeeCourseStatisticsRequest = {
      PageSize: pageSize,
      PageIndex: pageIndex,
      EmployeeId: GuidHelper.parse(employeeId),
      Difficulties: (tableFilters.difficulty as unknown) as EDifficultyCourseStatistics[],
      StartDate: Long.fromNumber(startDate.startOf('day').toJSDate().getTime() / 1000),
      EndDate: Long.fromNumber(endDate.endOf('day').toJSDate().getTime() / 1000),
      BusinessCategories: tableFilters.businessCategories,
      MedicalCategories: tableFilters.medicalCategories,
      DrugNames: tableFilters.drugNames,
      detail,
    }

    const response = await ProtoClient.post<IEmployeeCourseStatisticsList>(
      apiMethodUrl,
      paging,
      EmployeeCourseStatisticsRequest,
      EmployeeCourseStatisticsList
    )

    return response
  }
)

const SET_BUSINESS_CATEGORIES = 'EMPLOYEE_STATISTICS_SET_BUSINESS_CATEGORIES'
export const setBusinessCategoriesAction = createAction<IFilterSearch[]>(SET_BUSINESS_CATEGORIES)

const SET_MEDICAL_CATEGORIES = 'EMPLOYEE_STATISTICS_SET_MEDICAL_CATEGORIES'
export const setMedicalCategoriesAction = createAction<IFilterSearch[]>(SET_MEDICAL_CATEGORIES)

const SET_DRUG_NAMES = 'EMPLOYEE_STATISTICS_SET_DRUG_NAMES'
export const setDrugNamesAction = createAction<IFilterSearch[]>(SET_DRUG_NAMES)

export const clearFilters = () => (dispatch: AppDispatch): void => {
  dispatch(changeDifficulty([]))
  dispatch(setBusinessCategoriesAction([]))
  dispatch(setMedicalCategoriesAction([]))
  dispatch(setDrugNamesAction([]))
}

const employeeStatisticsReducer = createReducer(initialState, {
  [CHANGE_FILTERS]: (state, action) => {
    state.detail = action.payload.detail
    state.assignCoursesFilters = action.payload.assignCoursesFilters
    state.successfullAttemptsFilters = action.payload.successfullAttemptsFilters
    state.failedAttemptsFilter = action.payload.failedAttemptsFilter
    state.timedOutAttemptsFilter = action.payload.timedOutAttemptsFilter
    state.totalFilters = action.payload.totalFilters
    state.tableFilters = action.payload.tableFilters
    state.publicationInProgressFilters = action.payload.publicationInProgressFilters
    state.publicationStudiedFilters = action.payload.publicationStudiedFilters
    state.averageResultFilter = action.payload.averageResultFilter
    state.courseProgressFilter = action.payload.courseProgressFilter
  },
  [SET_BUSINESS_CATEGORIES]: (state, action) => {
    state.businessCategories = action.payload
  },
  [SET_MEDICAL_CATEGORIES]: (state, action) => {
    state.medicalCategories = action.payload
  },
  [SET_DRUG_NAMES]: (state, action) => {
    state.drugNames = action.payload
  },
  [CHANGE_START_DATE]: (state, action) => {
    state.startDate = action.payload
  },
  [CHANGE_END_DATE]: (state, action) => {
    state.endDate = action.payload
  },
  [CHANGE_COURSE_DIFFICULTY]: (state, action) => {
    state.difficulty = action.payload
  },
  [CHANGE_DETAIL]: (state, action) => {
    state.detail = action.payload
  },
  [SET_FILTER_OPEN]: (state, action) => {
    state.filterOpen = !state.filterOpen
  },
  [employeeCourseStatisticsFetchTable.fulfilled.toString()]: (
    state,
    action: { payload: IEmployeeCourseStatisticsList }
  ) => {
    state.loading = false
    state.totalRowCount = action.payload.rowCount
    state.fetchedData = action.payload.items
  },
  [employeeCourseStatisticsFetchTable.rejected.toString()]: (state, action) => {
    state.loading = false
  },
  [employeeCourseStatisticsFetchTable.pending.toString()]: (state, action) => {
    state.loading = true
  },
})

export default employeeStatisticsReducer
