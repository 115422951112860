import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Explanations from 'shared/components/Explanations'
import {
  explanationsItem,
  explanationsList,
  explanationsParagraph,
  explanationsText,
} from 'shared/components/Explanations/Explanations.module.scss'
import CubeQueryRender, { IChartComponentProps, renderChart } from 'shared/components/QueryRender/CubeQueryRender'
import CustomBarCursor from 'shared/components/Recharts/CustomBarCursor'
import CustomLegend from 'shared/components/Recharts/CustomLegend'
import CustomTooltip from 'shared/components/Recharts/CustomTooltip'
import { IUserListResponse } from 'shared/proto/models'

import { ChartPivotRow, Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import {
  failedKey,
  firstTryKey,
  IChartGridData,
  secondTryKey,
  thirdTryKey,
} from '../../../../reducers/pharmacyOrganizationStatisticsReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import { blockChart, blockChartGraph, blockChartTitle, chart } from './BlockCharts.module.scss'
import { barNames, barСolor, getChartWidth } from './utils'

const round = (value: number, total: number) => Math.round(((value * 100) / total + Number.EPSILON) * 100) / 100

const barSize = 80
const barSizeBigData = 10
const countBarStyle = 5
const tooltipColor = '#201F25'

const successfulAttemptsKey = 'successful'
const failedAttemptsKey = 'failed'
const timedOutKey = 'timedOut'

const successfulCount = 'SuccessfullCourseAttempt.count'
const successfulTryNumber = 'SuccessfullCourseAttempt.successfullTryNumber'
const successfulUserId = 'SuccessfullCourseAttempt.userId'

const failedCount = 'FailedCourseAttempt.count'
const failedUserId = 'FailedCourseAttempt.userId'

const timedOutCount = 'TimedOutCourseAttempt.count'
const timedOutUserId = 'TimedOutCourseAttempt.userId'

const labelFormatter = (label: React.ReactText) => {
  return label != 0 ? `${label}%` : ''
}

const applyLabelToBars = (
  user: IUserListResponse,
  successfullPivots: ChartPivotRow[],
  failedPivots: ChartPivotRow[],
  timedOutPivots: ChartPivotRow[]
): IChartGridData => {
  const userId = GuidHelper.toString(user.userId).toLowerCase()
  const filteredSuccessfullPivots = successfullPivots.filter((p) => p.xValues[1] === userId)
  const filteredFailedPivots = failedPivots.filter((p) => p.xValues[0] === userId)
  const filteredTimedOutPivots = timedOutPivots.filter((p) => p.xValues[0] === userId)
  const total =
    filteredSuccessfullPivots.reduce((prev, curr) => prev + curr[successfulCount], 0) +
    filteredFailedPivots.reduce((prev, curr) => prev + curr[failedCount], 0) +
    filteredTimedOutPivots.reduce((prev, curr) => prev + curr[timedOutCount], 0)
  const preparedData = {
    [firstTryKey]: round(
      filteredSuccessfullPivots.find((v) => v.x === `${firstTryKey},${userId}`)?.[successfulCount] ?? 0,
      total
    ),
    [secondTryKey]: round(
      filteredSuccessfullPivots.find((v) => v.x === `${secondTryKey},${userId}`)?.[successfulCount] ?? 0,
      total
    ),
    [thirdTryKey]: round(
      filteredSuccessfullPivots.find((v) => v.x === `${thirdTryKey},${userId}`)?.[successfulCount] ?? 0,
      total
    ),
    [failedKey]: round(
      (filteredFailedPivots.find((v) => v.x === `${userId}`)?.[failedCount] ?? 0) +
        (filteredTimedOutPivots.find((v) => v.x === `${userId}`)?.[timedOutCount] ?? 0),
      total
    ),
    ['name']: user.fullName,
  }
  return preparedData
}

const getData = (set: { [key: string]: ResultSet }, users: IUserListResponse[]) => {
  const successfulPivots = set[successfulAttemptsKey].chartPivot({
    x: [successfulTryNumber, successfulUserId],
    y: [successfulCount],
  })
  const failedPivots = set[failedAttemptsKey].chartPivot({
    x: [failedUserId],
    y: [failedCount],
  })
  const timedOutPivots = set[timedOutKey].chartPivot({
    x: [timedOutUserId],
    y: [timedOutCount],
  })
  const userIds = [
    ...new Set(
      successfulPivots
        .map((r) => r.xValues[1])
        .concat(failedPivots.map((r) => r.xValues[0]))
        .concat(timedOutPivots.map((r) => r.xValues[0]))
    ),
  ]

  const filteredUsers = users.filter((u) => userIds.includes(GuidHelper.toString(u.userId).toLowerCase()))

  const perparedData = filteredUsers.map((i) => applyLabelToBars(i, successfulPivots, failedPivots, timedOutPivots))

  return perparedData
}

const barRender = (props: IBarRenderProps) => {
  const { resultSet, users } = props
  const [show, setShow] = useState<string>(null)
  const data = getData(resultSet as { [key: string]: ResultSet }, users)
  const memoData = React.useMemo(() => data, [data])
  const barData = [firstTryKey, secondTryKey, thirdTryKey, failedKey]

  const legendClickHandler = (dataKey: string) => {
    if (show === dataKey) {
      setShow(null)
    } else {
      setShow(dataKey)
    }
  }

  const width = getChartWidth(data)

  return (
    memoData && (
      <ResponsiveContainer width={width} height="100%">
        <BarChart data={memoData} margin={{ top: 20 }}>
          <YAxis
            key="YAxis"
            axisLine={false}
            tickLine={false}
            tickFormatter={labelFormatter}
            domain={[0, 100]}
            ticks={[0, 25, 50, 75, 100]}
            width={40}
          />
          <XAxis hide={true} dataKey="name" />
          <CartesianGrid strokeDasharray="5 5" horizontal={true} vertical={false} stroke="#ACB2BD" />
          {barData.map((item, index) => {
            return (
              <Bar
                hide={show !== null && show !== item}
                key={`bar-${index}`}
                dataKey={item}
                stackId="a"
                name={barNames(item)}
                fill={barСolor(item)}
                minPointSize={3}
                barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
                background={show === item && { fill: '#eee' }}
              ></Bar>
            )
          })}
          <Tooltip cursor={<CustomBarCursor customWidth={2} color={tooltipColor} />} content={<CustomTooltip />} />
          <Legend
            wrapperStyle={{ bottom: '0px' }}
            height={35}
            content={<CustomLegend barData={barData} onClick={legendClickHandler} />}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  )
}

interface IBarRenderStateProps {
  users: IUserListResponse[]
}

type IBarRenderProps = IBarRenderStateProps & IChartComponentProps

const mapStateToBarChartProps = (store: IAppState): IBarRenderStateProps => {
  return {
    users: store.pharmacyGroupStatistics.users,
  }
}
const connectedBarRender = connect<IBarRenderStateProps, {}, IChartComponentProps, IAppState>(mapStateToBarChartProps)(
  barRender
)

const chartRender = (props: QueryRendererRenderProps) => (
  <div className={chart}>{renderChart(connectedBarRender)(props)}</div>
)

const VisibleExplanationsContent = () => {
  return (
    <p className={explanationsParagraph}>
      На&nbsp;графике отражены данные по&nbsp;всем курсам, которые были изучены вашими провизорами и&nbsp;провизорами,
      у&nbsp;которых есть заявка на&nbsp;добавление в&nbsp;штат вашей аптечной сети.
    </p>
  )
}

const ExplanationsContent = () => {
  return (
    <>
      <VisibleExplanationsContent />
      <p className={explanationsParagraph}>
        График показывает данные по&nbsp;тестированию конкретного провизора. Данные отражены по&nbsp;тем курсам, тест
        которых хотя&nbsp;бы раз запускал провизор.
      </p>
      <ul className={explanationsList}>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Сданы с&nbsp;1&nbsp;попытки&nbsp;&#8210; %&nbsp;курсов, тест которых провизор сдал с&nbsp;первой попытки
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Сданы с&nbsp;2&nbsp;попытки&nbsp;&#8210; %&nbsp;курсов, тест которых провизор сдал со&nbsp;второй попытки
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Сданы с&nbsp;3&nbsp;попытки&nbsp;&#8210; %&nbsp;курсов, тест которых провизор сдал с&nbsp;третьей попытки
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Не&nbsp;сданы&nbsp;&#8210; %&nbsp;курсов, тест которых провизор не&nbsp;смог сдать после трех попыток
          </span>
        </li>
      </ul>
    </>
  )
}

const TestComplitionChart = (props: TestComplitionChartChartProps) => {
  return (
    <div className={blockChart}>
      <div className={blockChartGraph}>
        <h2 className={blockChartTitle}>Статистика сдачи тестов по провизорам</h2>
        <CubeQueryRender
          queries={{
            [successfulAttemptsKey]: {
              measures: [successfulCount],
              dimensions: [successfulTryNumber, successfulUserId],
              filters: props.successfullAttmptsFilters,
            },
            [failedAttemptsKey]: {
              measures: [failedCount],
              dimensions: [failedUserId],
              filters: props.failedAttemptsFilters,
            },
            [timedOutKey]: {
              measures: [timedOutCount],
              dimensions: [timedOutUserId],
              filters: props.timedOutAttemptsFilters,
            },
          }}
          render={chartRender}
        />
      </div>

      <Explanations content={<ExplanationsContent />} visibleContent={<VisibleExplanationsContent />} />
    </div>
  )
}

type TestComplitionChartStateProps = {
  successfullAttmptsFilters: Filter[]
  failedAttemptsFilters: Filter[]
  timedOutAttemptsFilters: Filter[]
  dispatch: AppDispatch
}

type TestComplitionChartChartProps = TestComplitionChartStateProps

const mapStateToProps = (store: IAppState): TestComplitionChartStateProps => {
  return {
    successfullAttmptsFilters: store.pharmacyGroupStatistics.successfullAttmptsFilters,
    failedAttemptsFilters: store.pharmacyGroupStatistics.failedAttemptsFilters,
    timedOutAttemptsFilters: store.pharmacyGroupStatistics.timedOutAttemptsFilters,
    dispatch: store.dispatch,
  }
}

export default connect<TestComplitionChartStateProps, unknown, unknown, IAppState>(mapStateToProps)(TestComplitionChart)
