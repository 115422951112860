import { PharmacyOffice } from 'pharmacy-office'
import React, { ReactElement } from 'react'

const Main = (): ReactElement => (
  <>
    <PharmacyOffice />
  </>
)

export default Main
