import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { createAction, createReducer } from '@reduxjs/toolkit'
import { FilterSearchResult, IFilterSearch, IFilterSearchResult } from 'shared/proto/models'

const url = 'pharmacy-office/employee-course/'

export type EmployeeCourseStatisticsState = {
  pharmacies: IFilterSearch[]
  pharmacists: IFilterSearch[]
}

const initialState: EmployeeCourseStatisticsState = {
  pharmacies: null,
  pharmacists: null,
}

export const getPharmacies = async (query: string): Promise<IFilterSearch[]> => {
  const res = await ProtoClient.get<IFilterSearchResult>(url + 'pharmacies', FilterSearchResult, { query })
  return res.SearchResults
}
const EPLOYEE_STATISTICS_SET_PHARMACIES = 'EPLOYEE_STATISTICS_GET_PHARMACIES'
export const setPharmacies = createAction<IFilterSearch[]>(EPLOYEE_STATISTICS_SET_PHARMACIES)

export const getPharmacists = async (params: { pharmacyId: string; query: string }): Promise<IFilterSearch[]> => {
  if (!params.pharmacyId || !params.query || params.query.trim() === '') {
    return []
  }
  const res = await ProtoClient.get<IFilterSearchResult>(url + 'pharmacists', FilterSearchResult, params)
  return res.SearchResults
}

const EPLOYEE_STATISTICS_SET_PHARMACISTS = 'EPLOYEE_STATISTICS_GET_PHARMACISTS'
export const setPharmacists = createAction<IFilterSearch[]>(EPLOYEE_STATISTICS_SET_PHARMACISTS)

const EPLOYEE_STATISTICS_RESET_STATE = 'EPLOYEE_STATISTICS_RESET_STATE'
export const resetState = createAction(EPLOYEE_STATISTICS_RESET_STATE)

const employeeCourseStatisticsReducer = createReducer(initialState, {
  [EPLOYEE_STATISTICS_SET_PHARMACIES]: (state, action) => {
    state.pharmacies = action.payload
  },
  [EPLOYEE_STATISTICS_SET_PHARMACISTS]: (state, action) => {
    state.pharmacists = action.payload
  },
  [EPLOYEE_STATISTICS_RESET_STATE]: () => initialState,
})

export default employeeCourseStatisticsReducer
