import './Recharts.scss'

import React from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, LabelList, Legend, ResponsiveContainer, YAxis } from 'recharts'
import Explanations from 'shared/components/Explanations'
import {
  explanationsItem,
  explanationsList,
  explanationsParagraph,
  explanationsText,
} from 'shared/components/Explanations/Explanations.module.scss'
import CubeQueryRender, { IChartComponentProps, renderChart } from 'shared/components/QueryRender/CubeQueryRender'
import CustomLegend from 'shared/components/Recharts/CustomLegend'

import { Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import {
  failedKey,
  firstTryKey,
  secondTryKey,
  thirdTryKey,
} from '../../../../reducers/pharmacyOrganizationStatisticsReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import { blockChart, blockChartGraph, blockChartTitle, chart } from './BlockCharts.module.scss'
import { barNames, barСolor, getChartWidth, thresholdValue } from './utils'

const round = (value: number, total: number) => Math.round(((value * 100) / total + Number.EPSILON) * 100) / 100

const barSize = 80

const successfulAttemptsKey = 'successful'
const failedAttemptsKey = 'failed'
const timedOutKey = 'timedOut'

const getData = (set: { [key: string]: ResultSet }) => {
  const result = set[successfulAttemptsKey].chartPivot({ x: [successfulTryNumber], y: [successfulCount] })
  const failed = set[failedAttemptsKey].chartPivot({ x: ['0'], y: [failedCount] })
  const timedOut = set[timedOutKey].chartPivot({ x: ['0'], y: [timedOutCount] })
  let total =
    result.reduce((prev, curr) => prev + curr[successfulCount], 0) + failed[0][failedCount] + timedOut[0][timedOutCount]
  total = total < 1 ? 1 : total

  return [
    { [firstTryKey]: round(result.find((v) => v.x === firstTryKey)?.[successfulCount] ?? 0, total) },
    { [secondTryKey]: round(result.find((v) => v.x === secondTryKey)?.[successfulCount] ?? 0, total) },
    { [thirdTryKey]: round(result.find((v) => v.x === thirdTryKey)?.[successfulCount] ?? 0, total) },
    {
      [failedKey]: round(failed[0][failedCount] + timedOut[0][timedOutCount], total),
    },
  ]
}

const labelFormatter = (label: React.ReactText) => `${label}%`

const barRender = ({ resultSet }: IChartComponentProps) => {
  const data = getData(resultSet as { [key: string]: ResultSet })
  const barData = [firstTryKey, secondTryKey, thirdTryKey, failedKey]

  const width = getChartWidth(data)

  return (
    <ResponsiveContainer width={width}>
      <BarChart data={data} margin={{ top: 20 }}>
        <YAxis
          key="YAxis"
          axisLine={false}
          tickLine={false}
          tickFormatter={labelFormatter}
          domain={[0, 100]}
          ticks={[0, 25, 50, 75, 100]}
          width={40}
        />
        <CartesianGrid strokeDasharray="5 5" horizontal={true} vertical={false} stroke="#ACB2BD" />
        {barData.map((item, index) => {
          return (
            <Bar
              key={`bar-${index}`}
              dataKey={item}
              stackId="a"
              name={barNames(item)}
              fill={barСolor(item)}
              barSize={barSize}
              minPointSize={3}
            >
              <LabelList dataKey={item} position="top" formatter={labelFormatter} />
            </Bar>
          )
        })}
        <Legend height={35} wrapperStyle={{ bottom: '-5px' }} content={<CustomLegend barData={barData} />} />
      </BarChart>
    </ResponsiveContainer>
  )
}

const chartRender = (props: QueryRendererRenderProps) => <div className={chart}>{renderChart(barRender)(props)}</div>

const successfulCount = 'SuccessfullCourseAttempt.count'
const successfulTryNumber = 'SuccessfullCourseAttempt.successfullTryNumber'
const failedCount = 'FailedCourseAttempt.count'
const timedOutCount = 'TimedOutCourseAttempt.count'

const VisibleExplanationsContent = () => {
  return (
    <p className={explanationsParagraph}>
      На&nbsp;графике отражены данные по&nbsp;всем курсам, которые были изучены вашими провизорами и&nbsp;провизорами,
      у&nbsp;которых есть заявка на&nbsp;добавление в&nbsp;штат вашей аптечной сети.
    </p>
  )
}

const ExplanationsContent = () => {
  return (
    <>
      <VisibleExplanationsContent />
      <p className={explanationsParagraph}>
        График показывает прогресс сдачи курсов по&nbsp;всем провизорам. То&nbsp;есть:
      </p>
      <ul className={explanationsList}>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Сданы с&nbsp;1&nbsp;попытки&nbsp;&#8210; %&nbsp;курсов, тест которых провизор сдал с&nbsp;первой попытки
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Сданы с&nbsp;2&nbsp;попытки&nbsp;&#8210; %&nbsp;курсов, тест которых провизор сдал со&nbsp;второй попытки
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Сданы с&nbsp;3&nbsp;попытки&nbsp;&#8210; %&nbsp;курсов, тест которых провизор сдал с&nbsp;третьей попытки
          </span>
        </li>
        <li className={explanationsItem}>
          <span className={explanationsText}>
            Не&nbsp;сданы&nbsp;&#8210; %&nbsp;курсов, тест которых провизор не&nbsp;смог сдать после трех попыток
          </span>
        </li>
      </ul>
    </>
  )
}

const TestComplitionChart = (props: TestComplitionChartChartProps) => {
  return (
    <div className={blockChart}>
      <h2 className={blockChartTitle}>Статистика сдачи тестов</h2>
      <div className={blockChartGraph}>
        <CubeQueryRender
          queries={{
            [successfulAttemptsKey]: {
              measures: [successfulCount],
              dimensions: [successfulTryNumber],
              filters: props.successfullAttmptsFilters,
            },
            [failedAttemptsKey]: {
              measures: [failedCount],
              filters: props.failedAttemptsFilters,
            },
            [timedOutKey]: {
              measures: [timedOutCount],
              filters: props.timedOutAttemptsFilters,
            },
          }}
          render={chartRender}
        />
      </div>

      <Explanations content={<ExplanationsContent />} visibleContent={<VisibleExplanationsContent />} />
    </div>
  )
}

interface ITestComplitionChartStateProps {
  dispatch: AppDispatch
  successfullAttmptsFilters: Filter[]
  failedAttemptsFilters: Filter[]
  timedOutAttemptsFilters: Filter[]
}

type TestComplitionChartChartProps = ITestComplitionChartStateProps

const mapStateToProps = (store: IAppState): ITestComplitionChartStateProps => {
  return {
    dispatch: store.dispatch,
    successfullAttmptsFilters: store.pharmacyGroupStatistics.successfullAttmptsFilters,
    failedAttemptsFilters: store.pharmacyGroupStatistics.failedAttemptsFilters,
    timedOutAttemptsFilters: store.pharmacyGroupStatistics.timedOutAttemptsFilters,
  }
}

export default connect<ITestComplitionChartStateProps, unknown, unknown, IAppState>(mapStateToProps)(
  TestComplitionChart
)
