import React from 'react'
import { EDetail } from 'shared/proto/models'

import {
  filterBlock,
  filterBlockHeader,
  filterCheckbox,
  filterCheckboxList,
  filterLabel,
} from '../../CourseStatistics/Filter/Filter.module.scss'
import DetailFilterItem from './DetailFilterItem'

interface IDataFilter {
  id: number
  name: string
  value: EDetail
}

const dataFilter: IDataFilter[] = [
  {
    id: 10,
    name: 'Все курсы',
    value: EDetail.All,
  },
  {
    id: 20,
    name: 'Курсы аптечной сети',
    value: EDetail.PharmacyNetwork,
  },
]

export type DetailFilterStateProps = {
  detail: EDetail
}

export type DetailFilterDispatchProps = {
  changeDetail: (detail: EDetail) => void
}

type DetailFilterProps = DetailFilterStateProps & DetailFilterDispatchProps

const DetailFilter = (props: DetailFilterProps): React.ReactElement<DetailFilterProps> => {
  const listItems = dataFilter.map((item: IDataFilter) => {
    return (
      <DetailFilterItem
        key={item.id}
        name={item.name}
        id={item.id}
        value={item.value.toString()}
        checked={props.detail === item.value}
        onChange={(event) => {
          props.changeDetail(+event.target.value)
        }}
      />
    )
  })

  return (
    <div className={filterBlock}>
      <header className={filterBlockHeader}>
        <label className={filterLabel}>Детализация</label>
      </header>
      <div className={filterCheckbox}>
        <ul className={filterCheckboxList}>{listItems}</ul>
      </div>
    </div>
  )
}

export default DetailFilter
