import { withPrefix } from 'gatsby'
import { IUserPharmacyOrganization, PharmacyStatisticAvailableTabs } from 'pharmacy-office/proto/models'
import { getPharmacyOrganization, getPharmacyStatisticAvailableTabs } from 'pharmacy-office/reducers/pharmacyReducer'
import React from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'
import { IDataNavigationTab } from 'shared/components/NavigationTab/NavigationTab'
import { IPageInfoHeader } from 'shared/components/PageInfoHeader'
import Statistics, {
  IStatisticsOwnProps,
  IStatisticsStateProps,
  StatisticsProps,
} from 'shared/components/StatisticsPage/Statistics'

import { AppDispatch, IAppState } from '../../reducers/rootReducer'

const isAvailable = (availableTabs: PharmacyStatisticAvailableTabs[], tabName: string): boolean => {
  return availableTabs.filter((tab) => tab.tabName == tabName)?.[0]?.IsAvailable
}

const dataNavigation = (availableTabs: PharmacyStatisticAvailableTabs[]): IDataNavigationTab[] => {
  const navigationTabs: IDataNavigationTab[] = []

  const general = 'general'
  if (isAvailable(availableTabs, general)) {
    navigationTabs.push({
      id: 1,
      name: 'Общая',
      nameComponent: general,
      link: withPrefix('pharmacy-office/statistics'),
    })
  }

  const courses = 'courses'
  if (isAvailable(availableTabs, courses)) {
    navigationTabs.push({
      id: 2,
      name: 'Курсы',
      nameComponent: courses,
      link: withPrefix('pharmacy-office/statistics/courses'),
    })
  }

  const pharmacist = 'pharmacist'
  if (isAvailable(availableTabs, pharmacist)) {
    navigationTabs.push({
      id: 3,
      name: 'Провизоры',
      nameComponent: pharmacist,
      link: withPrefix('pharmacy-office/statistics/pharmacist'),
    })
  }

  const businessTrainers = 'business-trainers'
  if (isAvailable(availableTabs, businessTrainers)) {
    navigationTabs.push({
      id: 4,
      name: 'Бизнес-тренеры',
      nameComponent: businessTrainers,
      link: withPrefix('pharmacy-office/statistics/business-trainers'),
    })
  }

  return navigationTabs
}

const headerDataGetter = (organization: IUserPharmacyOrganization): IPageInfoHeader[] => {
  return [
    {
      id: nextId(),
      title: 'Наименование',
      value: organization?.name,
    },
    {
      id: nextId(),
      title: 'Номер договора',
      value: organization?.contractCode,
    },
  ]
}

const StatisticsPage = (props: StatisticsProps) => (
  <Statistics dataNavigation={dataNavigation} headerDataGetter={headerDataGetter} {...props}/>
)

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    fetchOrganization: () => {
      dispatch(getPharmacyOrganization())
    },
    fetchAvailableTabs: () => {
      dispatch(getPharmacyStatisticAvailableTabs())
    },
  }
}

const mapStateToProps = (store: IAppState): IStatisticsStateProps => {
  return {
    organization: store.pharmacy.organization,
    availableTabs: store.pharmacy.availableTabs,
    isShowInfoHeader: !store.profile.profileInfo.isRegionalManager
  }
}

export default connect<IStatisticsStateProps, {}, IStatisticsOwnProps, IAppState>(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsPage)
