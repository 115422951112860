import { withPrefix } from 'gatsby'
import React, { ReactElement, useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import LoaderSpinner from '../../../shared/components/LoaderSpinner'
import NavigationTab, { IDataNavigationTab } from '../../../shared/components/NavigationTab/NavigationTab'
import PageInfoHeader, { IPageInfoHeader } from '../../../shared/components/PageInfoHeader/PageInfoHeader'
import PageTitle from '../../../shared/components/PageTitle'
import { IUserPharmacyOrganization } from '../../proto/models'
import { getPharmacyOrganization } from '../../reducers/pharmacyReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'

interface IApplicationOwnProps {
  path?: string
  children?: React.ReactNode
}

interface IApplicationStateProps {
  dispatch: AppDispatch
  organization: IUserPharmacyOrganization
}

const dataNavigation: IDataNavigationTab[] = [
  {
    id: 1,
    name: 'Требуют подтверждения',
    nameComponent: 'ApplicationsRequireConfirmation',
    link: withPrefix('pharmacy-office/applications'),
  },
  {
    id: 2,
    name: 'Отклоненные',
    nameComponent: 'ApplicationsReject',
    link: withPrefix('pharmacy-office/applications/rejected'),
  },
]

type ApplicationProps = IApplicationOwnProps & IApplicationStateProps

const Applications = (props: ApplicationProps): ReactElement<ApplicationProps> => {
  const dataInfoHeader: IPageInfoHeader[] = [
    {
      id: nextId(),
      title: 'Наименование аптечной сети',
      value: props.organization?.name,
    },
    {
      id: nextId(),
      title: 'Номер договора',
      value: props.organization?.contractCode,
    },
  ]

  useEffect(() => {
    if (!props.organization) {
      props.dispatch(getPharmacyOrganization())
    }
  }, [])

  return (
    <>
      <PageTitle title="Заявки на добавление в штат" />
      {!props.organization ? (
        <LoaderSpinner />
      ) : (
        <>
          <PageInfoHeader data={dataInfoHeader} />
          <NavigationTab data={dataNavigation} />
          {props.children}
        </>
      )}
    </>
  )
}

const mapStateToProps = (store: IAppState) => {
  return {
    dispatch: store.dispatch,
    organization: store.pharmacy.organization,
  }
}

export default connect(mapStateToProps, null)(Applications)
