import React from 'react'
import { connect } from 'react-redux'
import LoaderSpinner from 'shared/components/LoaderSpinner'

import { changeFilters, clearFilters } from '../../../../reducers/employeeStatisticsReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import CourseProgressChart from './Charts/CourseProgressChart'
import TestingStatisticsChart from './Charts/TestingStatisticsChart'
import TotalsChart from './Charts/TotalsChart'
import { sectionContent } from './EmployeeStatistics.module.scss'
import BlockFilters from './Filter/BlockFilters'
import Filter from './Filter/Filter'
import EmployeeCourseStatisticsTable from './Tables/EmployeeCourseStatisticsTable'

export type CourseStatisticsOwnProps = {
  path: string
}

type CourseStatisticsDispatchProps = {
  init: () => void
}

type CourseStatisticsProps = CourseStatisticsOwnProps & CourseStatisticsDispatchProps

const EmployeeStatistics = (props: CourseStatisticsProps): React.ReactElement<CourseStatisticsOwnProps> => {
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    props.init()
    setLoading(false)
  }, [])
  return loading ? (
    <LoaderSpinner />
  ) : (
    <div className={sectionContent}>
      <BlockFilters />
      <Filter />
      <TotalsChart />
      <CourseProgressChart />
      <TestingStatisticsChart />
      <EmployeeCourseStatisticsTable />
    </div>
  )
}

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    init: () => {
      dispatch(changeFilters())
      return () => {
        dispatch(clearFilters())
      }
    },
  }
}
export default connect<unknown, CourseStatisticsDispatchProps, CourseStatisticsOwnProps, IAppState>(
  null,
  mapDispatchToProps
)(EmployeeStatistics)
