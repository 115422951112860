import React, { ReactElement } from 'react'
import { connect } from 'react-redux'

import { IBranchAddRequest, IPharmacyGroupBranches } from '../../../../proto/models'
import {
  addGroupBranch,
  deleteGroupBranches,
  getAddGroupBranches,
  getGroupBranches,
  IAddGroupBranchesRequest,
  IGroupBranchesRequest,
} from '../../../../reducers/pharmacyReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import GroupBranchesTable from './GroupBranchesTable'

interface IGroupBranchesOwnProps {
  path: string
  groupId?: string
}

interface IGroupBranchesStateProps {
  dispatch: AppDispatch
  addBranchPending: boolean
}

type GroupBranchesProps = IGroupBranchesOwnProps & IGroupBranchesStateProps

const GroupBranches = (props: GroupBranchesProps): ReactElement<GroupBranchesProps> => {
  const getGroupBranchesHandler = (options: IGroupBranchesRequest): Promise<IPharmacyGroupBranches> => {
    return props.dispatch(getGroupBranches(options))
  }

  const deleteGroupBranchesHandler = (id: string) => {
    props.dispatch(deleteGroupBranches(id))
  }

  const getAddGroupBranchesHandler = (options: IAddGroupBranchesRequest): Promise<IPharmacyGroupBranches> => {
    return props.dispatch(getAddGroupBranches(options))
  }

  const addGroupBranchHandler = (options: IBranchAddRequest) => {
    props.dispatch(addGroupBranch(options))
  }

  return (
    <GroupBranchesTable
      groupId={props.groupId}
      getGroupBranches={getGroupBranchesHandler}
      deleteGroupBranches={deleteGroupBranchesHandler}
      getAddGroupBranches={getAddGroupBranchesHandler}
      addGroupBranch={addGroupBranchHandler}
      addBranchPending={props.addBranchPending}
    />
  )
}

const mapStateToProps = (store: IAppState): IGroupBranchesStateProps => {
  return {
    dispatch: store.dispatch,
    addBranchPending: store.pharmacy.group.addBranchPending,
  }
}

export default connect<IGroupBranchesStateProps, {}, IGroupBranchesOwnProps, IAppState>(mapStateToProps)(GroupBranches)
