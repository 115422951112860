import { withPrefix } from 'gatsby'
import React, { ReactElement, useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import Breadcrumbs, { IBreadcrumbs } from '../../../../shared/components/Breadcrumbs/Breadcrumbs'
import LoaderSpinner from '../../../../shared/components/LoaderSpinner'
import PageInfoHeader, { IPageInfoHeader } from '../../../../shared/components/PageInfoHeader'
import PageTitle from '../../../../shared/components/PageTitle'
import { IBranchEmployees, IPharmacyGroupBranch } from '../../../proto/models'
import {
  deleteGroupEmployees,
  getGroupEmployees,
  getGroupEmployeesInformation,
  IGroupEmployeesRequest,
  resetGroupEmployeesInformation,
} from '../../../reducers/pharmacyReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import { section } from './BranchEmployeePage.module.scss'
import BranchEmployeeTable from './BranchEmployeeTable'

const url = 'pharmacy-office/organization'

interface IBranchEmployeesPageOwnProps {
  pharmacyId?: string
  groupId?: string
  path?: string
}

interface IBranchEmployeesPageStateProps {
  dispatch: AppDispatch
  info: IPharmacyGroupBranch
  loadingInfo: boolean
}

type BranchEmployeesPageProps = IBranchEmployeesPageOwnProps & IBranchEmployeesPageStateProps

const BranchEmployeesPage = (props: BranchEmployeesPageProps): ReactElement<BranchEmployeesPageProps> => {
  useEffect(() => {
    props.dispatch(getGroupEmployeesInformation(props.pharmacyId))

    return () => {
      props.dispatch(resetGroupEmployeesInformation())
    }
  }, [])

  const dataInfoHeader: IPageInfoHeader[] = [
    {
      id: nextId(),
      title: 'Наименование аптечной сети',
      value: props.info?.name,
    },
    {
      id: nextId(),
      title: 'Адрес филиала',
      value: props.info?.address,
    },
    {
      id: nextId(),
      title: 'Количество провизоров',
      value: String(props.info?.employeeCount),
    },
  ]

  const dataBreadcrumbs: IBreadcrumbs[] = [
    {
      title: 'Аптечные сети',
      link: withPrefix(url),
    },
    {
      title: 'Группа',
      link: withPrefix(`${url}/group/${props.groupId}/branches`),
    },
    {
      title: 'Информация о филиале',
      currSection: true,
    },
  ]

  const getBranchEmployeesHandler = (options: IGroupEmployeesRequest): Promise<IBranchEmployees> => {
    return props.dispatch(getGroupEmployees(options))
  }

  const deleteBranchEmployeesHandler = (employeeId: string) => {
    props.dispatch(deleteGroupEmployees(employeeId))
  }

  return (
    <section className={section}>
      <Breadcrumbs list={dataBreadcrumbs} />
      <PageTitle title="Аптека" />

      {props.loadingInfo ? (
        <LoaderSpinner />
      ) : (
        <>
          <PageInfoHeader data={dataInfoHeader} />
          <BranchEmployeeTable
            branchId={props.pharmacyId}
            groupId={props.groupId}
            getBranchEmployees={getBranchEmployeesHandler}
            deleteBranchEmployees={deleteBranchEmployeesHandler}
          />
        </>
      )}
    </section>
  )
}
const mapStateToProps = (store: IAppState): IBranchEmployeesPageStateProps => {
  return {
    dispatch: store.dispatch,
    info: store.pharmacy.group.employees.info,
    loadingInfo: store.pharmacy.group.employees.loadingInfo,
  }
}

export default connect<IBranchEmployeesPageStateProps, {}, IBranchEmployeesPageOwnProps, IAppState>(mapStateToProps)(
  BranchEmployeesPage
)
