/* eslint-disable react/display-name */
import classNames from 'classnames'
import { Link, withPrefix } from 'gatsby'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInterface,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  useGlobalFilter,
  UseGlobalFiltersInstanceProps,
  UseGlobalFiltersState,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
  UseTableOptions,
} from 'react-table'

import { DateHelper } from '../../../../external/rp.ui/helpers/DateHelper'
import { GuidHelper } from '../../../../external/rp.ui/helpers/GuidHelper'
import DeleteButton from '../../../../shared/components/Buttons/DeleteButton'
import PreviewIcon from '../../../../shared/components/Icons/PreviewIcon'
import AlertObjectRemove from '../../../../shared/components/Modal/AlertObjectRemove'
import SearchInput from '../../../../shared/components/SearchInput'
import Table from '../../../../shared/components/Table'
import {
  tableButtons,
  tableButtonsPreview,
  tableCell,
  tableText,
  tableTextContainer,
} from '../../../../shared/components/Table/Table.module.scss'
import TablePaging from '../../../../shared/components/TablePaging/TablePaging'
import { IBranchEmployee, IBranchEmployees } from '../../../proto/models'
import { IGroupEmployeesRequest } from '../../../reducers/pharmacyReducer'
import {
  section,
  sectionContent,
  sectionSearch,
  tableCellButtons,
  tableCellDate,
  tableCellEmail,
  tableCellFullname,
  tableCellGroup,
  tableCellPhone,
} from './BranchEmployee.module.scss'

interface IPharmaciesGroupTableProps {
  branchId: string
  groupId: string
  getBranchEmployees: (options: IGroupEmployeesRequest) => Promise<IBranchEmployees>
  deleteBranchEmployees: (employeeId: string) => void
}

const BranchEmployeeTable = (props: IPharmaciesGroupTableProps): ReactElement<IPharmaciesGroupTableProps> => {
  const [fetchedData, setFetchedData] = useState<IBranchEmployee[]>([])
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [currId, setCurrId] = useState(null)
  const [loading, setLoading] = useState(true)
  const [totalRowCount, setTotalRowCount] = useState(0)
  const pageSizeOptions = [25, 50, 75]
  const [localPageSize, setLocalPageSize] = useState(pageSizeOptions[0])
  const [search, setSearch] = useState('')

  const handleRemoveModalOpen = (id?: string) => {
    setOpenRemoveModal(true)
    setCurrId(id)
  }

  const agreeRemoveCallback = async () => {
    setLoading(true)
    await props.deleteBranchEmployees(currId)
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      search: search,
    })
  }

  interface FetchDataProps {
    pageIndex: number
    pageSize: number
    sortBy: Array<SortingRule<IBranchEmployees>>
    search: string
  }

  const onFetchData = async ({ pageIndex, pageSize, sortBy, search }: FetchDataProps) => {
    const paging: IGroupEmployeesRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortBy[0]?.id || 'name',
      sortDirection: sortBy[0]?.desc ?? true ? 'desc' : 'asc',
      branchId: props.branchId,
      searchQuery: search,
    }

    const response = await props.getBranchEmployees(paging)
    setFetchedData(response?.items ?? [])
    setTotalRowCount(response?.rowCount ?? 0)
    setLoading(false)
  }

  const getCellClass = (column: ColumnInterface<IBranchEmployees>) => {
    switch (column.id) {
      case 'fullName': {
        return classNames(tableCell, tableCellFullname)
      }
      case 'phone': {
        return classNames(tableCell, tableCellPhone)
      }
      case 'email': {
        return classNames(tableCell, tableCellEmail)
      }
      case 'pharmaciesGroupName': {
        return classNames(tableCell, tableCellGroup)
      }
      case 'createDate': {
        return classNames(tableCell, tableCellDate)
      }
      case 'rowButtons': {
        return classNames(tableCell, tableCellButtons)
      }
    }
  }

  const columns: Column<IBranchEmployee>[] = useMemo(
    () => [
      {
        Header: 'ФИО',
        id: 'fullName',
        accessor: 'fullName',
        sortType: 'basic',
        Cell: (cell: Cell<IBranchEmployee>) => {
          return (
            <p className={tableTextContainer} title={cell.value}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
      },
      {
        Header: 'Телефон',
        id: 'phone',
        accessor: 'phone',
        disableSortBy: true,
        Cell: (cell: Cell<IBranchEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
      },
      {
        Header: 'Почта',
        id: 'email',
        accessor: 'email',
        disableSortBy: true,
        Cell: (cell: Cell<IBranchEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
      },
      {
        Header: 'Группа',
        id: 'pharmaciesGroupName',
        accessor: 'pharmaciesGroupName',
        disableSortBy: true,
        Cell: (cell: Cell<IBranchEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
      },
      {
        Header: 'Дата добавления',
        id: 'createDate',
        accessor: 'createDate',
        Cell: (cell: Cell<IBranchEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{DateHelper.fromNumber(cell.value as number).format('DD.MM.YYYY')}</span>
            </p>
          )
        },
      },
      {
        id: 'rowButtons',
        Cell: (cell: Cell<IBranchEmployee>) => (
          <div className={tableButtons}>
            <Link
              className={tableButtonsPreview}
              to={withPrefix(
                `pharmacy-office/organization/group/${props.groupId}/branches/${
                  props.branchId
                }/employee/${GuidHelper.toString(cell.row.original.userId)}/courses`
              )}
            >
              <PreviewIcon />
            </Link>
            <DeleteButton
              iconColor="blue"
              onClick={() => handleRemoveModalOpen(GuidHelper.toString(cell.row.original.id))}
            />
          </div>
        ),
      },
    ],
    []
  )

  const data = useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const tablePageCount = useMemo(
    () => Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0])),
    [totalRowCount, localPageSize]
  )

  const initialState: Partial<TableState<IBranchEmployee>> &
    Partial<UsePaginationState<IBranchEmployee>> &
    UseSortByState<IBranchEmployee> = {
    pageSize: localPageSize,
    sortBy: [{ id: 'fullName', desc: true }],
  }

  const tableOptions: TableOptions<IBranchEmployee> &
    UseSortByOptions<IBranchEmployee> &
    UsePaginationOptions<IBranchEmployee> &
    UseTableOptions<IBranchEmployee> = {
    data: data,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    manualSortBy: true,
    manualPagination: true,
    disableSortRemove: true,
  }

  const table = useTable<IBranchEmployee>(
    tableOptions,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as TableInstance<IBranchEmployee> &
    UsePaginationInstanceProps<IBranchEmployee> &
    UseGlobalFiltersInstanceProps<IBranchEmployee>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize, sortBy } = table.state as UsePaginationState<IBranchEmployee> &
    UseSortByState<IBranchEmployee> &
    UseGlobalFiltersState<IBranchEmployee>

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  useEffect(() => {
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      search: search,
    })

    return () => {
      setFetchedData([])
      setOpenRemoveModal(false)
      setCurrId(null)
      setLoading(true)
      setLocalPageSize(pageSizeOptions[0])
    }
  }, [pageIndex, pageSize, sortBy, search])

  return (
    <>
      <AlertObjectRemove
        isOpen={openRemoveModal}
        title={'Вы уверены, что хотите удалить пользователя из списка сотрудников?'}
        agreeString={'Удалить'}
        disagreeString={'Отменить'}
        agreeCallback={agreeRemoveCallback}
        handleClose={() => setOpenRemoveModal(false)}
      />
      <div className={section}>
        <div className={sectionContent}>
          <div className={sectionSearch}>
            <SearchInput
              placeholder="Введите запрос для поиска"
              value={search}
              fullWidth={true}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(e.target.value)
              }}
            />
          </div>
          <Table<IBranchEmployee>
            tableInstance={table}
            loading={loading}
            getCellClass={getCellClass}
            rowWrap="nowrap"
          />
          <TablePaging
            gotoPage={gotoPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSizeOptions={pageSizeOptions}
            setLocalPageSize={setLocalPageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </>
  )
}

export default BranchEmployeeTable
