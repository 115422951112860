import classNames from 'classnames'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import Explanations from 'shared/components/Explanations'
import { explanationsParagraph } from 'shared/components/Explanations/Explanations.module.scss'
import CubeQueryRender, { IChartComponentProps, renderChart } from 'shared/components/QueryRender/CubeQueryRender'
import CustomLegend from 'shared/components/Recharts/CustomLegend'
import CustomTooltip from 'shared/components/Recharts/CustomTooltip'

import { ChartPivotRow, Filter, ResultSet } from '@cubejs-client/core'
import { QueryRendererRenderProps } from '@cubejs-client/react'

import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import { blockChart, blockChartGraph, blockChartTitle, chart } from './BlockCharts.module.scss'
import { thirtyFiveCountDataChartThreshold } from './utils'

const avgPresentationTime = 'PresentationTotalLearningTime.avgTotalLearningTime'
const avgTestTime = 'CourseTestSession.averageTestTime'
const courseId = 'Course.id'
const courseName = 'Course.name'

const presentationKey = 'presentation'
const testKey = 'test'

const barSize = 40
const barSizeBigData = 20
const countBarStyle = 6

interface ICubeTime {
  hours: number
  minutes: number
  seconds: number
  milliseconds: number
}

const timeToNumber = (time: ICubeTime) => {
  return (
    ((time && time.hours) || 0) * 60 +
    ((time && time.minutes) || 0) * 1 +
    ((time && time.seconds) || 0) * (1 / 60) +
    ((time && time.milliseconds) || 0) * (1 / 60) * 0.001
  )
}

interface IChartGridData {
  [presentationKey]: number
  [testKey]: number
  ['name']: string
}

const colors = {
  [presentationKey]: '#FEC213',
  [testKey]: '#42CF59',
}

const labels = {
  [presentationKey]: 'Изучение презентации',
  [testKey]: 'Сдача теста',
}

const getValue = (value: number) => {
  const minutes = Math.floor(value)
  const seconds = Math.floor((value - minutes) * 60)

  return `${minutes}:${seconds}`
}

const getValueFormatter = (payload: any) => {
  return getValue(payload.value)
}

const applyLabelToBars = (
  course: { id: string; name: string },
  presentationPivots: ChartPivotRow[],
  testPivots: ChartPivotRow[]
): IChartGridData => {
  const { id, name } = course
  const filteredPresentationPivots = presentationPivots.filter((p) => p.xValues[0] === id)
  const filteredTestPivots = testPivots.filter((p) => p.xValues[0] === id)

  const presentationTime = filteredPresentationPivots.find((v) => v.x === `${id},${name}`)?.[avgPresentationTime] ?? {}
  const testTime = filteredTestPivots.find((v) => v.x === `${id},${name}`)?.[avgTestTime] ?? {}

  const preparedData = {
    [presentationKey]: timeToNumber(presentationTime),
    [testKey]: timeToNumber(testTime),
    ['name']: name,
  }
  return preparedData
}

const getData = (set: { [key: string]: ResultSet }) => {
  const presentationPivots = set[presentationKey]
    .chartPivot({
      x: [courseId, courseName],
      y: [avgPresentationTime],
    })
    .filter((p) => p[avgPresentationTime])

  const testPivots = set[testKey]
    .chartPivot({
      x: [courseId, courseName],
      y: [avgTestTime],
    })
    .filter((p) => p[avgTestTime])

  const pairs = presentationPivots
    .map((r) => ({ id: r.xValues[0], name: r.xValues[1] }))
    .concat(testPivots.map((r) => ({ id: r.xValues[0], name: r.xValues[1] })))

  const uniquePairs = pairs.filter((item, i, a) => a.findIndex((v) => v.id == item.id) === i)

  return uniquePairs.map((pair) => applyLabelToBars(pair, presentationPivots, testPivots))
}

const barRender = ({ resultSet }: IBarRenderProps) => {
  const data = getData(resultSet as { [key: string]: ResultSet })
  const barData = [presentationKey, testKey]
  const [show, setShow] = useState<string>(null)
  const memoData = React.useMemo(() => data, [data])

  const legendClickHandler = (dataKey: string) => {
    if (show === dataKey) {
      setShow(null)
    } else {
      setShow(dataKey)
    }
  }

  const width = data.length > thirtyFiveCountDataChartThreshold ? '200%' : '99%'

  return (
    memoData && (
      <ResponsiveContainer width={width} height="100%">
        <BarChart data={memoData} margin={{ top: 20 }}>
          <XAxis dataKey="name" hide={true}/>
          <YAxis type="number" width={40} />
          <Tooltip content={<CustomTooltip getValue={getValueFormatter} />} />
          <CartesianGrid strokeDasharray="5 5" />
          <Bar
            dataKey={presentationKey}
            stackId="b"
            fill={colors[presentationKey]}
            name={labels[presentationKey]}
            barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
            hide={show !== null && show !== presentationKey}
            minPointSize={3}
          ></Bar>
          <Bar
            dataKey={testKey}
            stackId="a"
            fill={colors[testKey]}
            name={labels[testKey]}
            barSize={memoData.length <= countBarStyle ? barSize : barSizeBigData}
            hide={show !== null && show !== testKey}
            minPointSize={3}
          ></Bar>
          <Legend
            wrapperStyle={{ bottom: '-5px' }}
            height={35}
            content={<CustomLegend barData={barData} onClick={legendClickHandler} />}
          />
        </BarChart>
      </ResponsiveContainer>
    )
  )
}

type IBarRenderProps = IChartComponentProps

const chartRender = (props: QueryRendererRenderProps) => (
  <div className={classNames(chart, 'recharts-graph-time')} style={{ height: '385px' }}>
    {renderChart(barRender)(props)}
  </div>
)

const VisibleExplanationsContent = () => {
  return (
    <p className={explanationsParagraph}>
      График отражает данные по&nbsp;курсам Вашей аптечной сети. Позволяет увидеть среднее время, которое затратили
      провизоры на&nbsp;изучение презентации и&nbsp;на&nbsp;прохождение теста конкретного курса.
    </p>
  )
}

const AverageLearningTimeChart = (props: ChartProps) => {
  return (
    <div className={blockChart}>
      <h2 className={blockChartTitle}>Среднее время (мин)</h2>
      <div className={blockChartGraph}>
        <CubeQueryRender
          queries={{
            [presentationKey]: {
              measures: [avgPresentationTime],
              dimensions: [courseId, courseName],
              filters: props.filters,
            },
            [testKey]: {
              measures: [avgTestTime],
              dimensions: [courseId, courseName],
              filters: props.filters,
            },
          }}
          render={chartRender}
        />
      </div>

      <Explanations visibleContent={<VisibleExplanationsContent />} />
    </div>
  )
}

interface IChartStateProps {
  dispatch: AppDispatch
  startDate: DateTime
  endDate: DateTime
  filters: Filter[]
}

type ChartProps = IChartStateProps

const mapStateToProps = (store: IAppState): IChartStateProps => {
  return {
    dispatch: store.dispatch,
    startDate: store.pharmacyGroupStatistics.startDate,
    endDate: store.pharmacyGroupStatistics.endDate,
    filters: store.pharmacyGroupStatistics.averageLearingTimeFilters,
  }
}

export default connect<IChartStateProps, {}, {}, IAppState>(mapStateToProps)(AverageLearningTimeChart)
