import { withPrefix } from 'gatsby'
import { IBusinessTrainerInfo } from 'pharmacy-office/proto/models'
import { getBusinessTrainerInfo } from 'pharmacy-office/reducers/businessTrainerInfoReducer'
import React, { useEffect } from 'react'
import nextId from 'react-id-generator'
import Breadcrumbs, { IBreadcrumbs } from 'shared/components/Breadcrumbs'
import LoaderSpinner from 'shared/components/LoaderSpinner'
import PageInfoHeader from 'shared/components/PageInfoHeader'
import PageTitle from 'shared/components/PageTitle'
import { useAppDispatch, useAppSelector } from '../App/hook'
import BusinessTrainerInfoTable from './BusinessTrainerInfoTable'

interface IBusinessTrainerInfoOwnProps {
  trainerId?: string
}

const getDataPageInfoHeader = (props: IBusinessTrainerInfo) => {
  return [
    {
      id: nextId(),
      title: 'ФИО',
      value: props.name,
    },
    {
      id: nextId(),
      title: 'Телефон',
      value: props.phoneNumber,
    },
    {
      id: nextId(),
      title: 'Почта',
      value: props.email,
    },
  ]
}

const getDataBreadcrumbs = (): IBreadcrumbs[] => {
  return [
    {
      title: 'Бизнес-тренеры',
      link: withPrefix('pharmacy-office/statistics/business-trainers'),
    },
    {
      title: 'Бизнес-тренер',
      link: withPrefix('pharmacy-office/statistics/business-trainers/'),
      currSection: true,
    },
  ]
}

const BusinessTrainerInfo = (props: IBusinessTrainerInfoOwnProps) => {
  const businessTrainerInfo = useAppSelector((state) => state.businessTrainerInfo.businessTrainerInfo)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getBusinessTrainerInfo(props.trainerId))
  }, [])

  return !businessTrainerInfo ? (
    <LoaderSpinner />
  ) : (
    <>
      <Breadcrumbs list={getDataBreadcrumbs()} />
      <PageTitle title="Бизнес-тренер" paddingBottom={33} />
      <PageInfoHeader data={getDataPageInfoHeader(businessTrainerInfo)} />
      <BusinessTrainerInfoTable trainerId={props.trainerId} />
    </>
  )
}

export default BusinessTrainerInfo
