/* eslint-disable react/display-name */
import classNames from 'classnames'
import { DateHelper } from 'external/rp.ui/helpers/DateHelper'
import { saveAs } from 'file-saver'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import { Link, withPrefix } from 'gatsby'
import { DateTime } from 'luxon'
import { IEmployeesRequest } from 'pharmacy-office/reducers/pharmacistEmployeesReducer'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import {
  Cell,
  Column,
  ColumnInterface,
  SortingRule,
  TableInstance,
  TableOptions,
  TableState,
  useAsyncDebounce,
  usePagination,
  UsePaginationInstanceProps,
  UsePaginationOptions,
  UsePaginationState,
  useSortBy,
  UseSortByOptions,
  UseSortByState,
  useTable,
  UseTableOptions,
} from 'react-table'
import TextButton from 'shared/components/Buttons/TextButton/TextButton'
import PartialDateInput, { PartialDate } from 'shared/components/FlexibleDateInput/FlexibleDateInput'
import ExportIcon from 'shared/components/Icons/ExportIcon'
import LoaderSpinner from 'shared/components/LoaderSpinner'
import WarningTextBlock from 'shared/components/WarningTextBlock'

import PreviewIcon from '../../../../shared/components/Icons/PreviewIcon'
import SearchInput from '../../../../shared/components/SearchInput'
import Table from '../../../../shared/components/Table'
import {
  tableButtons,
  tableButtonsPreview,
  tableCell,
  tableText,
  tableTextContainer,
} from '../../../../shared/components/Table/Table.module.scss'
import TablePaging from '../../../../shared/components/TablePaging/TablePaging'
import {
  IPharmacyEmployee,
  IPharmacyEmployeeExportResult,
  IPharmacyEmployeeList,
  PharmacyEmployeeExportResult,
} from '../../../proto/models'
import {
  customTable,
  informationBlock,
  informationBlockIcon,
  informationBlockText,
  sectionContent,
  sectionExport,
  sectionFilter,
  sectionHeader,
  sectionSearch,
  tableCellAddDate,
  tableCellButtons,
  tableCellEmail,
  tableCellGroupName,
  tableCellName,
  tableCellNumber,
  tableCellPhoneNumber,
  tableSection,
} from './PharmacyEmployees.module.scss'

interface IPharmacyEmployeesTableProps {
  organizationId: Uint8Array
  loadingEmployees: boolean
  getEmployees: (options: IEmployeesRequest) => Promise<IPharmacyEmployeeList>
  setEmployeeLoading: (value: boolean) => void
}

const PharmacyEmployeesTable = (props: IPharmacyEmployeesTableProps): ReactElement<IPharmacyEmployeesTableProps> => {
  const [fetchedData, setFetchedData] = useState<IPharmacyEmployee[]>([])
  const [totalRowCount, setTotalRowCount] = useState(0)
  const [search, setSearch] = useState('')
  const pageSizeOptions = [25, 50, 75]
  const [localPageSize, setLocalPageSize] = useState(pageSizeOptions[0])
  const [loading, setLoading] = useState(false)

  interface IFetchDataProps {
    pageIndex: number
    pageSize: number
    sortBy: Array<SortingRule<IPharmacyEmployeeList>>
    search: string
    year: number
    month: number
    day: number
  }

  const onFetchData = async ({ pageIndex, pageSize, sortBy, search, year, month, day }: IFetchDataProps) => {
    const paging: IEmployeesRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortBy[0]?.id || 'name',
      sortDirection: sortBy[0]?.desc ?? true ? 'asc' : 'desc',
      organizationId: GuidHelper.toString(props.organizationId),
      searchQuery: search,
      year,
      month,
      day,
    }

    props.setEmployeeLoading(true)

    const response = await props.getEmployees(paging)

    setFetchedData(response?.items ?? [])
    setTotalRowCount(response?.rowCount ?? 0)
  }

  const onExportData = async ({ pageIndex, pageSize, sortBy, search, year, month, day }: IFetchDataProps) => {
    const paging: IEmployeesRequest = {
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortColumn: sortBy[0]?.id || 'name',
      sortDirection: sortBy[0]?.desc ?? true ? 'asc' : 'desc',
      organizationId: GuidHelper.toString(props.organizationId),
      searchQuery: search,
      year,
      month,
      day,
    }

    setLoading(true)

    const response = await ProtoClient.get<IPharmacyEmployeeExportResult>(
      'statistics/export',
      PharmacyEmployeeExportResult,
      paging
    )

    const byteArrays = [];
    byteArrays.push(response.data);

    const blob = new Blob(byteArrays, { type: 'application/vnd.ms-excel' });
    saveAs(blob, response.fileName);

    setLoading(false)
  }

  const getCellClass = (column: ColumnInterface<IPharmacyEmployeeList>) => {
    switch (column.id) {
      case 'name': {
        return classNames(tableCell, tableCellName)
      }
      case 'phoneNumber': {
        return classNames(tableCell, tableCellPhoneNumber)
      }
      case 'email': {
        return classNames(tableCell, tableCellEmail)
      }
      case 'groupName': {
        return classNames(tableCell, tableCellGroupName)
      }
      case 'addDate': {
        return classNames(tableCell, tableCellAddDate)
      }
      case 'averageResult': {
        return classNames(tableCell, tableCellNumber)
      }
      case 'scores': {
        return classNames(tableCell, tableCellNumber)
      }
      case 'rating': {
        return classNames(tableCell, tableCellNumber)
      }
      case 'rowButtons': {
        return classNames(tableCell, tableCellButtons)
      }
    }
  }

  const columns: Column<IPharmacyEmployee>[] = useMemo(
    () => [
      {
        Header: 'ФИО',
        id: 'name',
        accessor: 'name',
        Cell: (cell: Cell<IPharmacyEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.name}</span>
            </p>
          )
        },
      },
      {
        Header: 'Телефон',
        id: 'phoneNumber',
        disableSortBy: true,
        Cell: (cell: Cell<IPharmacyEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.phoneNumber}</span>
            </p>
          )
        },
      },
      {
        Header: 'Почта',
        id: 'email',
        disableSortBy: true,
        Cell: (cell: Cell<IPharmacyEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.email}</span>
            </p>
          )
        },
      },
      {
        Header: 'Группа',
        id: 'groupName',
        disableSortBy: true,
        Cell: (cell: Cell<IPharmacyEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.groupName}</span>
            </p>
          )
        },
      },
      {
        Header: 'Дата добавления',
        id: 'addDate',
        disableSortBy: true,
        Cell: (cell: Cell<IPharmacyEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>
                {(cell.row.original.addDate as number) && cell.row.original.addDate !== 0
                  ? DateHelper.toStringWithTime(cell.row.original.addDate as number, 'DD.MM.YYYY')
                  : null}
              </span>
            </p>
          )
        },
      },
      {
        Header: 'Средний результат',
        id: 'averageResult',
        accessor: 'averageResult',
        Cell: (cell: Cell<IPharmacyEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.averageResult}</span>
            </p>
          )
        },
      },
      {
        Header: 'Баллы',
        id: 'scores',
        accessor: 'scores',
        Cell: (cell: Cell<IPharmacyEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.scores}</span>
            </p>
          )
        },
      },
      {
        Header: 'Рейтинг',
        id: 'rating',
        accessor: 'rating',
        Cell: (cell: Cell<IPharmacyEmployee>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.row.original.rating}</span>
            </p>
          )
        },
      },
      {
        id: 'rowButtons',
        disableSortBy: true,
        Cell: (cell: Cell<IPharmacyEmployee>) => (
          <div className={tableButtons}>
            {cell.row.original.addDate !== 0 && (
              <Link
                className={tableButtonsPreview}
                to={withPrefix(
                  `/pharmacy-office/statistics/pharmacist/${GuidHelper.toString(cell.row.original.id)}/courses`
                )}
              >
                <PreviewIcon />
              </Link>
            )}
          </div>
        ),
      },
    ],
    []
  )

  const data = useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const tablePageCount = useMemo(() => {
    return Math.ceil(totalRowCount / (localPageSize || pageSizeOptions[0]))
  }, [totalRowCount, localPageSize])

  const initialState: Partial<TableState<IPharmacyEmployee>> &
    Partial<UsePaginationState<IPharmacyEmployee>> &
    UseSortByState<IPharmacyEmployee> = {
    pageSize: localPageSize,
    sortBy: [{ id: 'name', desc: true }],
  }

  const tableOptions: TableOptions<IPharmacyEmployee> &
    UseSortByOptions<IPharmacyEmployee> &
    UsePaginationOptions<IPharmacyEmployee> &
    UseTableOptions<IPharmacyEmployee> = {
    data: data,
    columns: columns,
    pageCount: tablePageCount,
    initialState: initialState,
    disableSortRemove: true,
    manualSortBy: true,
    manualPagination: true,
  }

  const table = useTable<IPharmacyEmployee>(
    tableOptions,
    useSortBy,
    usePagination
  ) as TableInstance<IPharmacyEmployee> & UsePaginationInstanceProps<IPharmacyEmployee>
  const { gotoPage, setPageSize, pageCount } = table
  const { pageIndex, pageSize, sortBy } = table.state as UsePaginationState<IPharmacyEmployee> &
    UseSortByState<IPharmacyEmployee>

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  const startDateInitial = new Date()
  startDateInitial.setDate(startDateInitial.getDate() - 30)

  const endDateInitial = new Date()
  endDateInitial.setDate(endDateInitial.getDate())

  const [date, setDate] = React.useState<PartialDate>({ year: DateTime.now().year, month: 0, day: 0 })

  useEffect(() => {
    onFetchDataDebounced({
      pageIndex: pageIndex,
      pageSize: pageSize,
      sortBy: sortBy,
      search,
      year: date.year,
      month: date.month,
      day: date.day,
    })
  }, [pageIndex, sortBy, pageSize, search, date])

  return (
    <>
      <LoaderSpinner />
      <div className={sectionContent}>
        <div className={sectionHeader}>
          <div className={sectionSearch}>
            <SearchInput
              fullWidth={true}
              placeholder="Введите запрос для поиска"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearch(e.target.value)
              }}
            />
          </div>
          <div className={sectionFilter}>
            <PartialDateInput defaultDate={date} onFilterButtonClick={setDate} tooltipButton="Применить фильтр" />
          </div>
          <div className={sectionExport}>
            <TextButton
              text="Экспорт"
              onClick={() =>
                onExportData({
                  pageIndex: pageIndex,
                  pageSize: pageSize,
                  sortBy: sortBy,
                  search,
                  year: date.year,
                  month: date.month,
                  day: date.day,
                })
              }
              loading={loading}
              iconLeft={<ExportIcon />}
            />
          </div>
        </div>
        <WarningTextBlock
          customClassName={informationBlock}
          customIconClassName={informationBlockIcon}
          customTextClassName={informationBlockText}
          text="При расчете рейтинга учитываются: количество пройденных курсов, количество попыток сдачи теста,
        количество оставленных оценок и&nbsp;отзывов"
        />
        <>
          <div className={tableSection}>
            <Table<IPharmacyEmployee>
              tableInstance={table}
              loading={props.loadingEmployees}
              getCellClass={getCellClass}
              rowWrap="nowrap"
              additionalTableStyles={customTable}
            />
          </div>
          <TablePaging
            gotoPage={gotoPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSizeOptions={pageSizeOptions}
            setLocalPageSize={setLocalPageSize}
            setPageSize={setPageSize}
          />
        </>
      </div>
    </>
  )
}

export default PharmacyEmployeesTable
