import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import React from 'react'
import { connect } from 'react-redux'
import { BlockAnswer, BlockSort, CoursePresentation } from 'shared/components/CourseStatistics'
import { ICourseInfoForm } from 'shared/proto/models'
import {
  getDateTimeRange,
  ICourseStatisticFiltersState,
} from 'shared/reducers/courseStatistics/courseStatisticFilterReducer'

import { Filter as CubeFilter } from '@cubejs-client/core'

import { IFilterSearch } from '../../proto/models'
import { resetState } from '../../reducers/employeeCourseStatisticsReducer'
import { AppDispatch, IAppState } from '../../reducers/rootReducer'
import Filter from './BlockFilter/Filter'
import BlockCourseReport from './BlockReport/BlockCourseReport'
import { sectionContent } from './CourseStatistics.module.scss'
import DynamicsLearning from './Dynamics/DynamicsLearning'

interface ICourseStatisticsOwnProps {
  path?: string
}

interface ICourseStatisticsFilterStateProps {
  course?: ICourseInfoForm
}

interface ICourseStatisticsDispatchProps {
  resetState: () => void
}

type CourseStatisticsProps = ICourseStatisticsOwnProps &
  ICourseStatisticsFilterStateProps &
  ICourseStatisticsDispatchProps

type FilterType = ICourseStatisticFiltersState

export const addFilterByPharmacy = (filters: Record<symbol, CubeFilter[]>[], pharmacies: IFilterSearch[]) => {
  if (pharmacies && pharmacies.length > 0) {
    filters.push({
      or: [
        {
          dimension: 'UserPharmacies.pharmacyId',
          operator: 'equals',
          values: pharmacies.map((p) => p.id),
        },
        {
          and: [
            {
              dimension: 'EmployerChangeApplication.pharmacyId',
              operator: 'equals',
              values: pharmacies.map((p) => p.id),
            },
            {
              dimension: 'EmployerChangeApplication.status',
              operator: 'equals',
              values: ['10'],
            },
          ],
        },
      ],
    })
  }

  return filters
}

const dynamicsLearningChart = (courseId: string, filter: FilterType): CubeFilter[] => {
  return [
    {
      dimension: 'StudentPublicationStatus.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'StudentPublicationStatus.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'PharmacistCourse.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'StudentPublicationStatusHistory.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
  ]
}

const dynamicsLearningTable = (courseId: string, filter: FilterType): CubeFilter[] => {
  return [
    {
      dimension: 'StudentPublicationStatus.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'PharmacistCourse.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'CourseTestSession.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'CourseTestSession.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
  ]
}

const presentationFilters = (courseId: string, filter: FilterType): CubeFilter[] => {
  return [
    {
      dimension: 'StudentPublicationStatus.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'PharmacistCourse.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'PresentationViewHistory.startDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
  ]
}

const answerFilters = (courseId: string, filter: ICourseStatisticFiltersState): CubeFilter[] => {
  return [
    {
      dimension: 'PharmacistCourse.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'TestQuestionStatistic.courseId',
      operator: 'equals',
      values: [courseId],
    },
    {
      dimension: 'CourseTestSession.createDate',
      operator: 'inDateRange',
      values: getDateTimeRange(filter),
    },
  ]
}

const CourseStatistics = (props: CourseStatisticsProps) => {
  const { course } = props

  const courseId = React.useMemo(() => GuidHelper.toString(course.id), [course.id])

  React.useEffect(() => props.resetState, [])

  return (
    <div className={sectionContent}>
      <BlockSort />
      <Filter />
      <BlockCourseReport courseId={courseId} />
      <DynamicsLearning courseId={courseId} chartFilters={dynamicsLearningChart} tableFilters={dynamicsLearningTable} />
      <CoursePresentation courseId={courseId} getFilters={presentationFilters} />
      <BlockAnswer courseId={courseId} getFilters={answerFilters} />
    </div>
  )
}

const mapStateToProps = (state: IAppState): ICourseStatisticsFilterStateProps => ({
  course: state.courseInfo.course,
})

const mapDispatchToProps = (dispatch: AppDispatch): ICourseStatisticsDispatchProps => {
  return {
    resetState: () => {
      dispatch(resetState())
    },
  }
}

export default connect<
  ICourseStatisticsFilterStateProps,
  ICourseStatisticsDispatchProps,
  ICourseStatisticsOwnProps,
  IAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(CourseStatistics)
