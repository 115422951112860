/* eslint-disable react/display-name */
import classNames from 'classnames'
import { GuidHelper } from 'external/rp.ui/helpers/GuidHelper'
import { ProtoClient } from 'external/rp.ui/utils/protoClient'
import React, { ReactElement, useState } from 'react'
import {
  Cell,
  Column,
  TableInstance,
  TableOptions,
  useAsyncDebounce,
  useSortBy,
  UseSortByOptions,
  useTable,
} from 'react-table'
import Notification from 'shared/components/Notification'
import Table from 'shared/components/Table'
import { tableCell, tableText, tableTextContainer } from 'shared/components/Table/Table.module.scss'

import {
  DeleteRoleRequest,
  DeleteRoleResult,
  EmployeeRoles,
  IDeleteRoleRequest,
  IDeleteRoleResult,
  IEmployeeRole,
  IEmployeeRoles,
} from '../../../../proto/models'
import { section, sectionContent, tableCellDeleteRole, tableCellName } from './EmployeeRolesTable.module.scss'

const apiMethodUrl = 'pharmacy-office/employee/get-roles'
const deleteMethodUrl = 'pharmacy-office/employee/delete-role'

interface IEmployeeTableState {
  employeeId?: string
  path: string
}
const EmployeeRolesTable = (props: IEmployeeTableState): ReactElement<IEmployeeTableState> => {
  const [fetchedData, setFetchedData] = useState<IEmployeeRole[]>([])
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false)
  const [notificationMessage, setNotificationMessage] = useState<string>('')
  const [loading, setLoading] = useState(false)

  const onFetchData = async () => {
    setLoading(true)
    const response = await ProtoClient.get<IEmployeeRoles>(apiMethodUrl + '/' + props.employeeId, EmployeeRoles)
    setFetchedData(response?.items ?? [])
    setLoading(false)
  }

  const deleteRecord = async (roleName: string) => {
    setLoading(true)
    const options: IDeleteRoleRequest = {
      id: GuidHelper.parse(props.employeeId),
      roleName: roleName,
    }

    const result = await ProtoClient.post<IDeleteRoleResult>(
      deleteMethodUrl,
      options,
      DeleteRoleRequest,
      DeleteRoleResult
    )

    if (!result.success) {
      setNotificationOpen(true)
      setNotificationMessage(result.message)
    }

    onFetchData()
  }

  const getCellClass = (column: Column<IEmployeeRole>) => {
    switch (column.id) {
      case 'name': {
        return classNames(tableCell, tableCellName)
      }
      case 'deleteRole': {
        return classNames(tableCell, tableCellDeleteRole)
      }
    }
  }

  const columns: Column<IEmployeeRole>[] = React.useMemo(
    () => [
      {
        Header: 'Наименование роли',
        id: 'name',
        accessor: 'name',
        disableSortBy: true,
        Cell: (cell: Cell<IEmployeeRole>) => {
          return (
            <p className={tableTextContainer}>
              <span className={tableText}>{cell.value}</span>
            </p>
          )
        },
      },
      ///Скрыто в первой версии
      // {
      //   id: 'deleteRole',
      //   disableSortBy: true,
      //   Cell: (cell: Cell<IEmployeeRole>) => (
      //     <div className={tableButtons}>
      //       <DeleteButton iconColor="blue" onClick={() => deleteRecord(cell.row.original.name)} />
      //     </div>
      //   ),
      // },
    ],
    []
  )

  const data = React.useMemo(() => {
    return fetchedData
  }, [fetchedData])

  const tableOptions: TableOptions<IEmployeeRole> & UseSortByOptions<IEmployeeRole> = {
    data: data,
    columns: columns,
  }

  const table = useTable<IEmployeeRole>(tableOptions, useSortBy) as TableInstance<IEmployeeRole>

  const onFetchDataDebounced = useAsyncDebounce(onFetchData, 1000)

  React.useEffect(() => {
    onFetchDataDebounced()
  }, [])

  const closeNotificationHandler = () => {
    setNotificationOpen(false)
    setNotificationMessage('')
  }

  return (
    <>
      <Notification
        onClose={closeNotificationHandler}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={notificationOpen}
        text={notificationMessage}
        response="positive"
      />
      <div className={section}>
        <div className={sectionContent}>
          {
            ///Скрыто в первой версии
            /* <div className={sectionContentHeader}>
            <div className={sectionButtons}>
              <AddRoleModal
                employeeId={props.employeeId}
                afterSubmitCallback={() => onFetchDataDebounced()}
                setLoading={() => setLoading(true)}
              />
            </div>
          </div> */
          }
          <Table<IEmployeeRole> tableInstance={table} loading={loading} getCellClass={getCellClass} />
        </div>
      </div>
    </>
  )
}

export default EmployeeRolesTable
