import React, { ChangeEvent, ReactElement, useState } from 'react'
import CloseButton from 'shared/components/Buttons/CloseButton'
import TextButton from 'shared/components/Buttons/TextButton'
import CustomTextarea from 'shared/components/CustomTextarea'
import CloseIcon from 'shared/components/Icons/CloseIcon'

import { Dialog } from '@material-ui/core'

import {
  modal,
  modalBlockButtons,
  modalBlockComment,
  modalButton,
  modalClose,
  modalHeader,
  modalTitle,
} from './ApplicationsRequireConfirmation.module.scss'

export interface IModalProps {
  handleClose: (values: any) => void
  open: boolean
  onRejectApplications: (message: string) => void
}

const EditForm = (props: IModalProps): ReactElement<IModalProps> => {
  const { open, handleClose, onRejectApplications } = props
  const [value, setValue] = useState('')

  const rejectButtonHandler = () => {
    onRejectApplications(value)
  }

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={(e, reason) => {
        handleClose(reason)
      }}
    >
      <div className={modal}>
        <header className={modalHeader}>
          <h2 className={modalTitle}>Отклонение заявки</h2>
          <div className={modalClose}>
            <CloseButton onClick={handleClose} color="grey" isHover={true} />
          </div>
        </header>
        <div className={modalBlockComment}>
          <CustomTextarea
            value={value}
            onChange={(event: ChangeEvent<HTMLTextAreaElement>) => setValue(event.target.value)}
            height="104px"
            placeholder="Введите комментарий"
          />
        </div>
        <div className={modalBlockButtons}>
          <div className={modalButton}>
            <TextButton
              onClick={rejectButtonHandler}
              iconLeft={<CloseIcon color="white" />}
              text="Отклонить"
              disabled={!value.trim()}
            />
          </div>
          <div className={modalButton}>
            <TextButton variant="outlined" text="Отменить" onClick={handleClose} />
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default EditForm
