import React, { ReactElement } from 'react'

import { Dialog } from '@material-ui/core'

import CloseButton from '../../../../../shared/components/Buttons/CloseButton'
import { IBranchAddRequest, IPharmacyGroupBranches } from '../../../../proto/models'
import { IAddGroupBranchesRequest } from '../../../../reducers/pharmacyReducer'
import AddBranchTable from './AddBranchTable'
import { modal, modalClose, modalHeader, modalTitle } from './GroupBranches.module.scss'

interface IAddBranchContext {
  groupId: string
  open: boolean
  addBranchPending: boolean
  handleClose: () => void
  getAddGroupBranches: (options: IAddGroupBranchesRequest) => Promise<IPharmacyGroupBranches>
  addGroupBranch: (options: IBranchAddRequest) => void
}

const AddBranch = (props: IAddBranchContext): ReactElement<IAddBranchContext> => {
  const closeModalHandler = () => {
    props.handleClose()
  }

  const actionCallback = (options: IBranchAddRequest) => {
    props.addGroupBranch(options)
    props.handleClose()
  }

  return (
    <Dialog maxWidth={false} open={props.open} onClose={closeModalHandler}>
      <div className={modal}>
        <header className={modalHeader}>
          <h2 className={modalTitle}>Выбор филиала</h2>
          <div className={modalClose}>
            <CloseButton onClick={props.handleClose} color="grey" isHover={true} />
          </div>
        </header>
        <AddBranchTable
          groupId={props.groupId}
          actionCallback={actionCallback}
          getAddGroupBranches={props.getAddGroupBranches}
          addBranchPending={props.addBranchPending}
          closeModalHandler={closeModalHandler}
        />
      </div>
    </Dialog>
  )
}

export default AddBranch
