// extracted by mini-css-extract-plugin
export var forminfoSubtitle = "AdvancedTargetingForm-module--forminfo-subtitle--3FrtU";
export var section = "AdvancedTargetingForm-module--section--3dwNd";
export var sectionButtons = "AdvancedTargetingForm-module--section-buttons--1z17I";
export var sectionButton = "AdvancedTargetingForm-module--section-button--2Tcqs";
export var forminfo = "AdvancedTargetingForm-module--forminfo--15SW9";
export var forminfoClose = "AdvancedTargetingForm-module--forminfo-close--2XDJt";
export var forminfoCloseButton = "AdvancedTargetingForm-module--forminfo-close-button--aAhwP";
export var forminfoList = "AdvancedTargetingForm-module--forminfo-list--16YjR";
export var forminfoItem = "AdvancedTargetingForm-module--forminfo-item--2KfM2";
export var forminfoItemWrapper = "AdvancedTargetingForm-module--forminfo-item-wrapper--1C-tF";
export var forminfoBlockSelect = "AdvancedTargetingForm-module--forminfo-block-select--1SOGg";
export var forminfoBlockSearch = "AdvancedTargetingForm-module--forminfo-block-search--11K5w";
export var forminfoTitle = "AdvancedTargetingForm-module--forminfo-title--EyFET";
export var forminfoSettings = "AdvancedTargetingForm-module--forminfo-settings--XNCoA";
export var forminfoResult = "AdvancedTargetingForm-module--forminfo-result--3G7GT";