import { withPrefix } from 'gatsby'
import React, { ReactElement, useEffect } from 'react'
import nextId from 'react-id-generator'
import { connect } from 'react-redux'

import Breadcrumbs, { IBreadcrumbs } from '../../../../shared/components/Breadcrumbs'
import LoaderSpinner from '../../../../shared/components/LoaderSpinner'
import NavigationTab from '../../../../shared/components/NavigationTab/NavigationTab'
import PageInfoHeader from '../../../../shared/components/PageInfoHeader'
import PageTitle from '../../../../shared/components/PageTitle'
import { IEmployee } from '../../../proto/models'
import { getEmployee, resetEmployee } from '../../../reducers/pharmacyReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'

const url = 'pharmacy-office'

const getDataNavigation = (props: IEmployeeOwnProps) => {
  let coursesLink = `${url}/organization/group/${props.groupId}/branches/${props.pharmacyId}/employee/${props.employeeId}/courses`
  let statisticsLink = `${url}/organization/group/${props.groupId}/branches/${props.pharmacyId}/employee/${props.employeeId}/statistics`

  if (props.employees) {
    coursesLink = `${url}/statistics/pharmacist/${props.employeeId}/courses`
    statisticsLink = `${url}/statistics/pharmacist/${props.employeeId}/statistics`
  }

  return [
    {
      id: nextId(),
      name: 'Курсы',
      link: withPrefix(coursesLink),
    },
    {
      id: nextId(),
      name: 'Статистика',
      link: withPrefix(statisticsLink),
    },
  ]
}

const getDataPageInfoHeader = (employee: IEmployee) => {
  return [
    {
      id: nextId(),
      title: 'ФИО',
      value: employee.fullName,
    },
    {
      id: nextId(),
      title: 'Наименование группы',
      value: employee.groupName,
    },
    {
      id: nextId(),
      title: 'Телефон',
      value: employee.phone,
    },
    {
      id: nextId(),
      title: 'Адрес филиала',
      value: employee.address,
    },
    {
      id: nextId(),
      title: 'Наименование аптечной сети',
      value: employee.networkName,
    },

    {
      id: nextId(),
      title: 'Почта',
      value: employee.email,
    },
  ]
}

const getDataBreadcrumbs = (props: IEmployeeOwnProps): IBreadcrumbs[] => {
  return props.employees
    ? [
        {
          title: 'Статистика',
          link: withPrefix(`${url}/statistics/pharmacist`),
        },
        {
          title: 'Информация о сотруднике',
          currSection: true,
        },
      ]
    : [
        {
          title: 'Аптечные сети',
          link: withPrefix(`${url}/organization`),
        },
        {
          title: 'Группа',
          link: withPrefix(`${url}/organization/group/${props.groupId}/branches/`),
        },
        {
          title: 'Филиал',
          link: withPrefix(`${url}/organization/group/${props.groupId}/branches/${props.pharmacyId}`),
        },
        {
          title: 'Информация о сотруднике',
          currSection: true,
        },
      ]
}

interface IEmployeeOwnProps {
  path: string
  groupId?: string
  pharmacyId?: string
  employeeId?: string
  children: React.ReactNode
  employees?: boolean
}

interface IEmployeeStateProps {
  dispatch: AppDispatch
  info: IEmployee
  loadingInfo: boolean
}

type EmployeeProps = IEmployeeOwnProps & IEmployeeStateProps

const EmployeePage = (props: EmployeeProps): ReactElement<EmployeeProps> => {
  useEffect(() => {
    props.dispatch(getEmployee(props.employeeId))

    return () => {
      props.dispatch(resetEmployee())
    }
  }, [props.employeeId])

  return props.loadingInfo ? (
    <LoaderSpinner />
  ) : (
    <>
      <Breadcrumbs list={getDataBreadcrumbs(props)} />
      <PageTitle title="Информация о сотруднике" />
      <PageInfoHeader data={getDataPageInfoHeader(props.info)} />
      <NavigationTab data={getDataNavigation(props)} />
      {props.children}
    </>
  )
}

const mapStateToProps = (store: IAppState): IEmployeeStateProps => {
  return {
    dispatch: store.dispatch,
    info: store.pharmacy.group.employees.employee.info,
    loadingInfo: store.pharmacy.group.employees.employee.loadingInfo,
  }
}

export default connect<IEmployeeStateProps, {}, IEmployeeOwnProps, IAppState>(mapStateToProps)(EmployeePage)
