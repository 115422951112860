import React, { ReactElement } from 'react'
import { connect } from 'react-redux'

import { IPharmacyEmployee, IPharmacyEmployeeList } from '../../../proto/models'
import { getEmployees, IEmployeesRequest, setLoadEmployees } from '../../../reducers/pharmacistEmployeesReducer'
import { AppDispatch, IAppState } from '../../../reducers/rootReducer'
import { section } from './PharmacyEmployees.module.scss'
import PharmacyEmployeesTable from './PharmacyEmployeesTable'

interface IPharmacyEmployeesOwnProps {
  path: string
}

interface IPharmacyEmployeesStateProps {
  dispatch: AppDispatch
  loadingEmployees: boolean
  listEmployees: IPharmacyEmployee[]
  organizationId: Uint8Array
}

type PharmacyEmployeesProps = IPharmacyEmployeesOwnProps & IPharmacyEmployeesStateProps

const PharmacyEmployees = (props: PharmacyEmployeesProps): ReactElement<PharmacyEmployeesProps> => {
  const setEmployeeLoadingHandler = (value: boolean) => {
    props.dispatch(setLoadEmployees(value))
  }

  const getEmployeesHandler = (options: IEmployeesRequest): Promise<IPharmacyEmployeeList> => {
    return props.dispatch(getEmployees(options))
  }

  return (
    <section className={section}>
      <PharmacyEmployeesTable
        getEmployees={getEmployeesHandler}
        loadingEmployees={props.loadingEmployees}
        organizationId={props.organizationId}
        setEmployeeLoading={setEmployeeLoadingHandler}
      />
    </section>
  )
}

const mapStateToProps = (store: IAppState): IPharmacyEmployeesStateProps => {
  return {
    dispatch: store.dispatch,
    loadingEmployees: store.statisticPharmacist.employees.loadingEmployees,
    listEmployees: store.statisticPharmacist.employees.listEmployees,
    organizationId: store.pharmacy.organization.id,
  }
}

export default connect<IPharmacyEmployeesStateProps, unknown, IPharmacyEmployeesOwnProps, IAppState>(mapStateToProps)(
  PharmacyEmployees
)
