import classNames from 'classnames'
import throttleFetch from 'external/rp.ui/utils/throttleFetch'
import { DateTime } from 'luxon'
import React from 'react'
import { connect } from 'react-redux'
import FilterButton from 'shared/components/Buttons/FilterButton'
import DateInput from 'shared/components/DateInput/DateInput'

import {
  changeEndDate,
  changeStartDate,
  setGlobalFilterOpen,
} from '../../../../reducers/pharmacyOrganizationStatisticsReducer'
import { AppDispatch, IAppState } from '../../../../reducers/rootReducer'
import { blockFilters, blockFiltersItem, blockFiltersItemButton, blockFiltersList } from './BlockFilters.module.scss'

const throttleChangeStartDate = throttleFetch((dispatch: AppDispatch, date: Date) =>
  dispatch(changeStartDate(DateTime.fromJSDate(date)))
)

const throttleChangeEndDate = throttleFetch((dispatch: AppDispatch, date: Date) =>
  dispatch(changeEndDate(DateTime.fromJSDate(date)))
)

const BlockFilters = (props: BlockFiltersProps) => {
  return (
    <div className={blockFilters}>
      <ul className={blockFiltersList}>
        <li className={blockFiltersItem}>
          <DateInput
            value={props.startDate.toJSDate()}
            onChange={(date) => {
              if (date !== null) {
                throttleChangeStartDate(props.dispatch, date)
              }
            }}
            maxDate={props.endDate.toJSDate()}
          />
        </li>
        <li className={blockFiltersItem}>
          <DateInput
            value={props.endDate.toJSDate()}
            onChange={(date) => {
              if (date !== null) {
                throttleChangeEndDate(props.dispatch, date)
              }
            }}
            minDate={props.startDate.toJSDate()}
          />
        </li>
        <li className={classNames(blockFiltersItem, blockFiltersItemButton)}>
          <FilterButton onClick={() => props.dispatch(setGlobalFilterOpen())} />
        </li>
      </ul>
    </div>
  )
}

interface IBlockFiltersStateProps {
  dispatch: AppDispatch
  startDate: DateTime
  endDate: DateTime
}

type BlockFiltersProps = IBlockFiltersStateProps

const mapStateToProps = (store: IAppState): IBlockFiltersStateProps => {
  return {
    dispatch: store.dispatch,
    startDate: store.pharmacyGroupStatistics.startDate,
    endDate: store.pharmacyGroupStatistics.endDate,
  }
}

export default connect<IBlockFiltersStateProps, unknown, unknown, IAppState>(mapStateToProps)(BlockFilters)
