import { Dispatch } from 'redux'

import { createAction, createReducer } from '@reduxjs/toolkit'

export interface ICoursesState {
  isAdvancedSearchOpen: boolean
}

const initialState: ICoursesState = {
  isAdvancedSearchOpen: false,
}

const SET_ADVANCED_SEARCH_OPEN = 'SET_ADVANCED_SEARCH_OPEN'
export const setAdvancedSearchOpen = createAction<boolean>(SET_ADVANCED_SEARCH_OPEN)

const coursesReducer = createReducer(initialState, {
  [SET_ADVANCED_SEARCH_OPEN]: (state, action) => {
    state.isAdvancedSearchOpen = action.payload
  },
})

export default coursesReducer
