import React, { ChangeEventHandler, ReactElement } from 'react'

import {
  filterCheckboxInput,
  filterCheckboxItem,
  filterCheckboxText,
} from '../../CourseStatistics/Filter/Filter.module.scss'
import CustomRadio from '../../CustomRadio'

interface IDetailFilterItemProps {
  id: number
  name: string
  onChange: ChangeEventHandler<HTMLInputElement>
  value: string
  checked: boolean
}

const DetailFilterItem = (props: IDetailFilterItemProps): ReactElement<IDetailFilterItemProps> => (
  <li className={filterCheckboxItem}>
    <span className={filterCheckboxInput}>
      <CustomRadio
        id={props.id.toString()}
        value={props.value}
        onChange={props.onChange}
        checked={props.checked}
        disabled={false}
      />
    </span>
    <span className={filterCheckboxText}>{props.name}</span>
  </li>
)

export default DetailFilterItem
